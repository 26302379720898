<template>
  <div class="wrapper">
    <p>Du har ikke tilgang til siden du prøvde å navigere deg til.</p>
    <div class="link">
      <router-link to="/">Tilbake til forsiden</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import authService from "@/utils/auth/authService";
import { defineComponent } from "vue";

export default defineComponent({
  props: {},

  setup() {
    authService.isAuthenticated().then((isAuthenticated) => {
      if (!isAuthenticated) {
        authService.navigateToUrl("/auth/login");
      }
    });

    return {};
  },
});
</script>

<style lang="less" scoped>
.wrapper {
  width: 800px;
  margin: 0 auto;
  margin-top: 5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.link {
  margin-top: 1rem;
}
</style>
