<template>
  <div>
    <h1>Bestillingsarkiv</h1>
    <BestillingFilter
      class="bestilling-filter"
      @søk="
        page = 1;
        hentBestillinger($event);
      "
    />
    <div v-if="!lastet" class="spinner-wrapper"><Spinner /></div>
    <BestillingArkiv v-else class="bestilling-liste" :bestillinger="bestillinger" />
    <SideVelger v-if="lastet" v-model="page" v-model:antallPerSide="pageSize" :totaltAntall="totaltAntall" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { BestillingArkiv, BestillingFilter } from "@/components";
import { IBestilling, IBestillingFilter } from "@/types";
import { hentBestillinger as getBestillinger } from "@/clients/bestillingClient";
import authService from "@/utils/auth/authService";

export default defineComponent({
  props: {},

  setup() {
    const lastet = ref(false);
    const bestillinger = ref<IBestilling[]>([]);
    const page = ref(1);
    const pageSize = ref(10);
    const totaltAntall = ref(0);
    const sisteSøk = ref<IBestillingFilter>();

    const isKundesenter = ref(false);
    const isAdmin = ref(false);
    authService.hasRole("Admin").then((result) => (isAdmin.value = result));
    authService.hasRole("Kundesenter").then((result) => (isKundesenter.value = result));

    const hentBestillinger = (filter: IBestillingFilter = {}) => {
      console.log("Søker med filter", filter.ordreNummer);
      sisteSøk.value = filter;
      lastet.value = false;
      getBestillinger(filter, pageSize.value, page.value - 1).then((result) => {
        bestillinger.value = result.bestillinger;
        totaltAntall.value = result.totaltAntall;
        lastet.value = true;
      });
    };

    hentBestillinger({ sorterSynkende: true });

    watch([page, pageSize], hentBestillinger);

    return {
      bestillinger,
      lastet,
      isAdmin,
      isKundesenter,
      hentBestillinger,
      page,
      pageSize,
      sisteSøk,
      totaltAntall,
    };
  },

  components: { BestillingArkiv, BestillingFilter },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.spinner-wrapper {
  display: flex;
  justify-content: center;
}

.bestilling-filter {
  margin-bottom: 1rem;
}

.bestilling-liste {
  width: 100%;
}
</style>
