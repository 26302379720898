<template>
  <CenteredSpinner v-if="laster" />

  <div v-else class="import-verktøy">
    <div class="input-gruppe">
      <label for="hovedfil"> Hovedfil </label>
      <select v-model="valgtHovedfil" id="hovedfil">
        <option value="">-- Ikke valgt --</option>
        <option v-for="fil in hovedfiler" :key="fil" :value="fil">
          {{ fil }}
        </option>
      </select>
    </div>

    <div class="input-gruppe">
      <label for="suppleringsfil"> Suppleringsfil </label>
      <select v-model="valgtSuppleringsfil" id="suppleringsfil">
        <option value="">-- Ikke valgt --</option>
        <option v-for="fil in suppleringsfiler" :key="fil" :value="fil">
          {{ fil }}
        </option>
      </select>
    </div>

    <Button type="primary" @click="importer">Importer</Button>
  </div>
</template>

<script lang="ts">
import { IImportFil } from "@/types";
import { computed, defineComponent, ref } from "vue";
import { hentFilListe, importerData } from "@/clients/importClient";
import { toast } from "@/utils";
import { bekreftFare } from "@/utils/dialogUtils";

export default defineComponent({
  props: {},

  setup() {
    const filer = ref<IImportFil[]>();
    const laster = ref(true);

    hentFilListe().then((result) => {
      filer.value = result;
      laster.value = false;
    });

    const hovedfiler = computed(() => filer.value?.filter((f) => !f.erSuppleringsfil).map((f) => f.filnavn));
    const suppleringsfiler = computed(() => filer.value?.filter((f) => f.erSuppleringsfil).map((f) => f.filnavn));

    const valgtHovedfil = ref("");
    const valgtSuppleringsfil = ref("");

    const importer = async () => {
      const doImporter = async () => {
        laster.value = true;
        await importerData(
          valgtHovedfil.value ? valgtHovedfil.value : null,
          valgtSuppleringsfil.value ? valgtSuppleringsfil.value : null
        );
        laster.value = false;
        toast("Fullførte import av data");
      };

      bekreftFare(
        "Du er i ferd med å importere data fra backup. Dette vil overskrive data som ligger i løsningen, " +
          "slik at blant annet bestillinger blir slettet." +
          "\n\nVil du gjennomføre dette?",
        { title: "Fare for tap av data", onConfirm: doImporter }
      );
    };

    return { laster, hovedfiler, suppleringsfiler, valgtHovedfil, valgtSuppleringsfil, importer };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.import-verktøy {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-gruppe {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
