import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["value"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["value"]
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("label", null, "Merke", -1)),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valgtHovedgruppe) = $event)),
      disabled: _ctx.hovedgrupper.length === 0,
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hentUndergrupper()))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hovedgrupper, (hovedgruppe) => {
        return (_openBlock(), _createElementBlock("option", {
          value: hovedgruppe,
          key: hovedgruppe.hovedgruppeId
        }, _toDisplayString(hovedgruppe.beskrivelse), 9, _hoisted_3))
      }), 128))
    ], 40, _hoisted_2), [
      [_vModelSelect, _ctx.valgtHovedgruppe]
    ]),
    _cache[7] || (_cache[7] = _createElementVNode("label", null, "Modell", -1)),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.valgtUndergruppe) = $event)),
      disabled: _ctx.undergrupper.length === 0,
      onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.hentÅrsmodeller()))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.undergrupper, (undergruppe) => {
        return (_openBlock(), _createElementBlock("option", {
          value: undergruppe,
          key: undergruppe.undergruppeId
        }, _toDisplayString(undergruppe.beskrivelse), 9, _hoisted_5))
      }), 128))
    ], 40, _hoisted_4), [
      [_vModelSelect, _ctx.valgtUndergruppe]
    ]),
    _cache[8] || (_cache[8] = _createElementVNode("label", null, "Årsmodell", -1)),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.valgtÅrsmodell) = $event)),
      disabled: _ctx.årsmodeller.length === 0,
      onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleÅrsmodellChange()))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.årsmodeller, (årsmodell) => {
        return (_openBlock(), _createElementBlock("option", {
          value: årsmodell,
          key: årsmodell.fra
        }, _toDisplayString(årsmodell.fra), 9, _hoisted_7))
      }), 128))
    ], 40, _hoisted_6), [
      [_vModelSelect, _ctx.valgtÅrsmodell]
    ])
  ]))
}