import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "kunde-info" }
const _hoisted_2 = { class: "rad" }
const _hoisted_3 = { class: "felt-gruppe" }
const _hoisted_4 = { class: "felt-gruppe" }
const _hoisted_5 = { class: "felt-gruppe" }
const _hoisted_6 = { class: "rad" }
const _hoisted_7 = { class: "felt-gruppe" }
const _hoisted_8 = { class: "felt-gruppe" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "felt-gruppe" }
const _hoisted_11 = { class: "rad" }
const _hoisted_12 = { class: "felt-gruppe" }
const _hoisted_13 = { class: "felt-gruppe" }
const _hoisted_14 = { class: "felt-gruppe" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("label", null, "Kundenummer", -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.kunde.kundeNummer), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[1] || (_cache[1] = _createElementVNode("label", null, "Navn", -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.kunde.navn), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[2] || (_cache[2] = _createElementVNode("label", null, "Status", -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.kunde.status), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _cache[3] || (_cache[3] = _createElementVNode("label", null, "Adresse", -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.kunde.adresselinje1), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.kunde.postNr) + " " + _toDisplayString(_ctx.kunde.postSted), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[4] || (_cache[4] = _createElementVNode("label", null, "Telefon", -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.kunde.telefonNummer1), 1),
        (_ctx.kunde.telefonNummer1)
          ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.kunde.telefonNummer2), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[5] || (_cache[5] = _createElementVNode("label", null, "Deres referanse", -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.kunde.deresReferanse), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _cache[6] || (_cache[6] = _createElementVNode("label", null, "Lager", -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.kunde.kundesLager), 1)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _cache[7] || (_cache[7] = _createElementVNode("label", null, "Rute", -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.kunde.rute?.beskrivelse ?? ""), 1)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _cache[8] || (_cache[8] = _createElementVNode("label", null, "Avganger", -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.kunde.rute?.avganger.map((a) => _ctx.formaterKlokkeslett(a.avgangTidspunkt)).join(", ")), 1)
      ])
    ])
  ]))
}