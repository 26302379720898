import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "opprett-tokens" }
const _hoisted_2 = {
  key: 0,
  class: "forrige-utsendelse"
}
const _hoisted_3 = {
  key: 1,
  class: "options"
}
const _hoisted_4 = { class: "option" }
const _hoisted_5 = { class: "option" }
const _hoisted_6 = { class: "option" }
const _hoisted_7 = {
  key: 3,
  class: "spinner-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.forrigeUtsendelse)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[3] || (_cache[3] = _createElementVNode("i", { class: "las la-info-circle" }, null, -1)),
          _cache[4] || (_cache[4] = _createTextVNode(" Forrige utsending skjedde ")),
          _createElementVNode("strong", null, _toDisplayString(_ctx.formaterDatoTid(_ctx.forrigeUtsendelse.tidspunkt)), 1),
          _cache[5] || (_cache[5] = _createTextVNode(" av ")),
          _createElementVNode("strong", null, _toDisplayString(_ctx.forrigeUtsendelse.bruker), 1),
          _cache[6] || (_cache[6] = _createTextVNode(". "))
        ]))
      : _createCommentVNode("", true),
    (!_ctx.senderInn)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              id: "epost",
              type: "checkbox",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sendEpost) = $event))
            }, null, 512), [
              [_vModelCheckbox, _ctx.sendEpost]
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("label", { for: "epost" }, "Send e-post til alle kunder", -1))
          ]),
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              id: "sms",
              type: "checkbox",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sendSms) = $event))
            }, null, 512), [
              [_vModelCheckbox, _ctx.sendSms]
            ]),
            _cache[8] || (_cache[8] = _createElementVNode("label", { for: "sms" }, "Send SMS til alle kunder", -1))
          ]),
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createElementVNode("input", {
              id: "csv",
              type: "checkbox",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.lastNedCsv) = $event))
            }, null, 512), [
              [_vModelCheckbox, _ctx.lastNedCsv]
            ]),
            _cache[9] || (_cache[9] = _createElementVNode("label", { for: "csv" }, "Last ned CSV", -1))
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.senderInn)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 2,
          onClick: _ctx.sendInn,
          type: "primary"
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createTextVNode("Opprett tokens")
          ])),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true),
    (_ctx.senderInn)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_Spinner)
        ]))
      : _createCommentVNode("", true)
  ]))
}