<template>
  <CenteredSpinner v-if="sender"/>
  <form v-else class="sms-tester" @submit.prevent="sendSmsKlikk">
    <div class="input-gruppe">
      <label for="mottaker"> Mottaker </label>
      <input id="mottaker" v-model="mottaker" type="text"/>
    </div>
    <div class="input-gruppe">
      <label for="melding"> Melding </label>
      <textarea id="melding" v-model="melding" type="text"/>
    </div>

    <Button type="primary" @click="sendSmsKlikk">Send</Button>
  </form>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import { sendSms } from "@/clients/smsClient";
import { toast } from "@/utils";

const mottaker = ref("");
const emne = ref("");
const melding = ref("");

const sender = ref(false);

const sendSmsKlikk = async () => {
  try {
    sender.value = true;
    await sendSms(mottaker.value, melding.value);
    toast(`SMS ble sendt til "${mottaker.value}"`);
  } catch (e: any) {
    toast(`SMS sending feilet: ${e.message}`);
  } finally {
    sender.value = false;
    mottaker.value = "";
    emne.value = "";
    melding.value = "";
  }
};
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.sms-tester {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.input-gruppe {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>