<template>
  <form class="wrapper" @submit.prevent="lagre()">
    <h1>Ny bruker</h1>
    <ul v-if="errors.length" class="errors">
      <li v-for="error in errors" :key="error">{{ error }}</li>
    </ul>
    <div class="input-gruppe">
      <label for="brukernavn">Brukernavn</label>
      <input type="text" id="brukernavn" v-model="brukerNavn" />
    </div>

    <div class="input-gruppe">
      <label for="lager">Lager</label>
      <input type="text" id="lager" v-model="lager" />
    </div>

    <div class="input-gruppe">
      <label for="kundeNr">Kundenummer</label>
      <input type="text" id="kundeNr" v-model="kundeNr" />
    </div>

    <div class="input-gruppe">
      <label for="passord1">Passord</label>
      <input type="password" id="passord1" v-model="passord1" />
    </div>

    <span v-if="passord2 && passord1 !== passord2">Passordene er ikke like</span>

    <div class="input-gruppe">
      <label for="passord2">Gjenta passord</label>
      <input type="password" id="passord2" v-model="passord2" />
    </div>

    <Button @click="lagre()" type="primary" :disabled="lagrer || !passord1 || !brukerNavn || passord1 !== passord2">
      Lagre
    </Button>
  </form>
</template>

<script lang="ts">
import { opprettBruker } from "@/clients/brukerClient";
import router from "@/router";
import { toast, validerPassord } from "@/utils";
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {},

  setup() {
    const errors = ref<string[]>([]);
    const brukerNavn = ref("");
    const lager = ref("");
    const kundeNr = ref("");
    const passord1 = ref("");
    const passord2 = ref("");
    const lagrer = ref(false);

    const lagre = async () => {
      lagrer.value = true;
      errors.value = [];

      const [passordGyldig, valideringsfeil] = validerPassord(passord1.value);
      if (!passordGyldig) {
        lagrer.value = false;
        errors.value = valideringsfeil;
        return;
      }

      try {
        await opprettBruker(brukerNavn.value, passord1.value, lager.value, kundeNr.value);
      } finally {
        lagrer.value = false;
      }
      toast(`Ny bruker med brukernavn "${brukerNavn.value}" opprettet.`, 5);
      router.push("/brukere");
    };

    return { errors, brukerNavn, passord1, passord2, lagre, kundeNr, lager, lagrer };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.input-gruppe {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.errors {
  list-style: none;
  color: @red;
  padding: 0;
  margin: 0;
}
</style>
