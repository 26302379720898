import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  ref: "printContainer",
  class: "print-container"
}
const _hoisted_2 = { class: "field-row" }
const _hoisted_3 = { class: "field-group" }
const _hoisted_4 = {
  key: 0,
  class: "field-group"
}
const _hoisted_5 = { class: "field-row" }
const _hoisted_6 = { class: "field-group" }
const _hoisted_7 = { class: "field-group" }
const _hoisted_8 = { class: "field-group" }
const _hoisted_9 = { class: "field-row" }
const _hoisted_10 = { class: "field-group" }
const _hoisted_11 = { class: "field-group" }
const _hoisted_12 = { class: "field-group" }
const _hoisted_13 = { class: "linje-tabell" }
const _hoisted_14 = { class: "artikkelnr" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "lagerplass" }
const _hoisted_18 = { class: "antall" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[14] || (_cache[14] = _createElementVNode("h1", null, "Plukkliste", -1)),
    _createVNode(_component_Button, { onClick: _ctx.print }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Skriv ut")
      ])),
      _: 1
    }, 8, ["onClick"]),
    _createElementVNode("div", _hoisted_1, [
      _cache[13] || (_cache[13] = _createElementVNode("h1", null, "Plukkliste", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[1] || (_cache[1] = _createElementVNode("label", null, "Bestilling-ID", -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.bestilling.id), 1)
        ]),
        (_ctx.bestilling.ordreNummer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[2] || (_cache[2] = _createElementVNode("label", null, "Ordrenummer", -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.bestilling.ordreNummer), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _cache[3] || (_cache[3] = _createElementVNode("label", null, "Kundenummer", -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.bestilling.kundeNr), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[4] || (_cache[4] = _createElementVNode("label", null, "Kundenavn", -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.bestilling.kunde.navn), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[5] || (_cache[5] = _createElementVNode("label", null, "Adresse", -1)),
          _createElementVNode("span", null, _toDisplayString(_ctx.bestilling.kunde.adresselinje1), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.bestilling.kunde.postNr) + " " + _toDisplayString(_ctx.bestilling.kunde.postSted), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _cache[6] || (_cache[6] = _createElementVNode("label", null, "Lager", -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.bestilling.lager), 1)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[7] || (_cache[7] = _createElementVNode("label", null, "Rute", -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.transportRute?.beskrivelse ?? "Ukjent"), 1)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _cache[8] || (_cache[8] = _createElementVNode("label", null, "Avgangstider", -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.avganger), 1)
        ])
      ]),
      _createElementVNode("table", _hoisted_13, [
        _cache[12] || (_cache[12] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", { class: "artikkelnr" }, [
              _createTextVNode("Artikkelnummer"),
              _createElementVNode("br"),
              _createTextVNode("Artikkelnavn")
            ]),
            _createElementVNode("th", { class: "lagerplass" }, [
              _createTextVNode("Lagerplass"),
              _createElementVNode("br"),
              _createTextVNode("Lagerstatus")
            ]),
            _createElementVNode("th", { class: "antall" }, "Antall")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.linjerMedVarer, (linje, i) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: i,
              class: "artikkel-linje"
            }, [
              _createElementVNode("td", _hoisted_14, [
                _createTextVNode(_toDisplayString(linje.artikkelNr) + " ", 1),
                _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" " + _toDisplayString(linje.artikkel.navn) + " ", 1),
                (linje.artikkel.egenskaper.length)
                  ? (_openBlock(), _createElementBlock("em", _hoisted_15, " - " + _toDisplayString(linje.artikkel.egenskaper.map((x) => x.beskrivelse).join(", ")), 1))
                  : _createCommentVNode("", true),
                (linje.merknad)
                  ? (_openBlock(), _createElementBlock("em", _hoisted_16, [
                      _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                      _createTextVNode(" Merknad: " + _toDisplayString(linje.merknad), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("td", _hoisted_17, [
                _createTextVNode(_toDisplayString(linje.hovedlokasjon) + " ", 1),
                _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.lagLagerStatusBeskrivelse(linje.lagerStatus)), 1)
              ]),
              _createElementVNode("td", _hoisted_18, _toDisplayString(linje.antall), 1)
            ]))
          }), 128))
        ])
      ])
    ], 512)
  ]))
}