<template>
  <div class="spinner-wrapper" v-if="lagrer">
    <Spinner />
  </div>
  <div v-else-if="!token" class="wrapper">
    <div class="input-gruppe">
      <label for="kunde">Kunde</label>
      <KundeNummerTypeAhead id="kunde" v-model="kundeNr" />
    </div>

    <div v-if="kundeNr">Valgt kunde: {{ kundeNr }}{{ kunde ? ` - ${kunde.navn}` : "" }}</div>

    <Button @click="doOpprettToken" type="primary">{{
      kundeNr ? "Opprett token tilknyttet valgt kunde" : "Opprett token"
    }}</Button>
  </div>
  <div v-else class="wrapper">
    <div class="input-gruppe">
      <label for="token">Token</label>
      <input id="token" type="text" disabled :value="token" />
    </div>
    <div class="input-gruppe">
      <label for="registreringslenke">Registreringslenke</label>
      <a :href="registreringslenke">{{ registreringslenke }}</a>
    </div>
  </div>
</template>

<script lang="ts">
import { opprettToken } from "@/clients/brukerClient";
import { computed, defineComponent, ref, watch } from "vue";
import KundeNummerTypeAhead from "@/components/kunde/KundeNummerTypeAhead.vue";
import { hentKunde } from "@/clients/kundeClient";
import { IKunde } from "@/types";

export default defineComponent({
  props: {},

  setup() {
    const kundeNr = ref("");
    const kunde = ref<IKunde | null>();
    const token = ref("");
    const lagrer = ref(false);

    const registreringslenke = computed(() => `${window.location.origin}/registrer/${token.value}`);

    watch(kundeNr, () => {
      kunde.value = null;
      hentKunde(kundeNr.value).then((result) => {
        kunde.value = result;
      });
    });

    const doOpprettToken = async () => {
      lagrer.value = true;
      token.value = await opprettToken(kundeNr.value);
      lagrer.value = false;
    };

    return { kundeNr, kunde, token, doOpprettToken, lagrer, registreringslenke };
  },

  components: {
    KundeNummerTypeAhead,
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.spinner-wrapper {
  display: flex;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.input-gruppe {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
