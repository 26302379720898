<template>
  <div>
    <h1>Plukkliste</h1>
    <Button @click="print">Skriv ut</Button>

    <div ref="printContainer" class="print-container">
      <h1>Plukkliste</h1>

      <div class="field-row">
        <div class="field-group">
          <label>Bestilling-ID</label>
          {{ bestilling.id }}
        </div>

        <div v-if="bestilling.ordreNummer" class="field-group">
          <label>Ordrenummer</label>
          {{ bestilling.ordreNummer }}
        </div>
      </div>

      <div class="field-row">
        <div class="field-group">
          <label>Kundenummer</label>
          {{ bestilling.kundeNr }}
        </div>
        <div class="field-group">
          <label>Kundenavn</label>
          {{ bestilling.kunde.navn }}
        </div>
        <div class="field-group">
          <label>Adresse</label>
          <span>{{ bestilling.kunde.adresselinje1 }}</span>
          <span>{{ bestilling.kunde.postNr }} {{ bestilling.kunde.postSted }}</span>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group">
          <label>Lager</label>
          {{ bestilling.lager }}
        </div>
        <div class="field-group">
          <label>Rute</label>
          {{ transportRute?.beskrivelse ?? "Ukjent" }}
        </div>
        <div class="field-group">
          <label>Avgangstider</label>
          {{ avganger }}
        </div>
      </div>

      <table class="linje-tabell">
        <thead>
          <tr>
            <th class="artikkelnr">Artikkelnummer<br />Artikkelnavn</th>
            <th class="lagerplass">Lagerplass<br />Lagerstatus</th>
            <th class="antall">Antall</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(linje, i) in linjerMedVarer" :key="i" class="artikkel-linje">
            <td class="artikkelnr">
              {{ linje.artikkelNr }}
              <br />
              {{ linje.artikkel.navn }}
              <em v-if="linje.artikkel.egenskaper.length">
                - {{ linje.artikkel.egenskaper.map((x) => x.beskrivelse).join(", ") }}
              </em>
              <em v-if="linje.merknad">
                <br />
                Merknad:
                {{ linje.merknad }}
              </em>
            </td>
            <td class="lagerplass">
              {{ linje.hovedlokasjon }}
              <br />
              {{ lagLagerStatusBeskrivelse(linje.lagerStatus) }}
            </td>
            <td class="antall">{{ linje.antall }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref } from "vue";
import { Printd } from "printd";
import { IBestilling, ITransportRute, LagerStatus } from "@/types";
import { formaterKlokkeslett } from "@/utils";

export default defineComponent({
  props: {
    bestilling: {
      type: Object as PropType<IBestilling>,
      required: true,
    },
    transportRute: {
      type: Object as PropType<ITransportRute | undefined>,
      required: true,
    },
  },

  setup(props) {
    const printContainer = ref<null | HTMLElement>(null);
    const printd = new Printd();

    const linjerMedVarer = computed(() => props.bestilling.linjer.filter((x) => x.antall !== 0));

    const print = () => {
      if (!printContainer.value) {
        return;
      }

      printd.print(printContainer.value, [cssText]);
    };

    onMounted(() => {
      print();
    });

    const avganger = computed(
      () =>
        props.transportRute?.avganger.map((avgang) => formaterKlokkeslett(avgang.avgangTidspunkt)).join(", ") ??
        "Fant ingen avganger"
    );

    const lagLagerStatusBeskrivelse = (status: LagerStatus): string => {
      if (status === LagerStatus.tomtPåLager) return "Manko";
      if (status === LagerStatus.utilgjengeligPåHovedlokasjon) return "Ikke nok på hovedlokasjon";
      if (status === LagerStatus.overallokert) return "Overallokert";
      if (status === LagerStatus.ok) return "På lager";

      return "Ukjent";
    };

    return { printContainer, avganger, print, lagLagerStatusBeskrivelse, linjerMedVarer };
  },
});

const cssText = `
.print-container {
  font-size: 12px;
}

.print-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* * * * * * * * * * * * * * * * * * * * * * */
/*             Lim inn under her             */
/* * * * * * * * * * * * * * * * * * * * * * */

.print-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.field-row {
  display: flex;
  flex-direction: row;
  gap: 60px;
}

.field-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
}

table {
  border-collapse: collapse;
}

thead {
  border-bottom: 1px solid black;
}

td,
th {
  padding: 8px 4px;
}

tr:not(:last-child) > td {
  border-bottom: 1px solid lightgray;
}

.artikkelnr {
  text-align: left;
}

.lagerplass {
  text-align: left;
}

.antall {
  text-align: right;
}
`;
</script>

<style lang="less" scoped>
.print-container {
  margin-top: 1rem;
  border: 1px solid black;
  padding: 1rem;
  min-height: 30rem;
}

/* * * * * * * * * * * * * * * * * * * * * * */
/*  Styles under her må kopieres til cssText */
/* * * * * * * * * * * * * * * * * * * * * * */
.print-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.field-row {
  display: flex;
  flex-direction: row;
  gap: 60px;
}

.field-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
}

table {
  border-collapse: collapse;
}

thead {
  border-bottom: 1px solid black;
}

td,
th {
  padding: 8px 4px;
}

tr:not(:last-child) > td {
  border-bottom: 1px solid lightgray;
}

.artikkelnr {
  text-align: left;
}

.lagerplass {
  text-align: left;
}

.antall {
  text-align: right;
}
</style>
