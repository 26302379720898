import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.aktiv)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "modal-wrapper",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('lukk')))
      }, [
        _createElementVNode("div", {
          class: "modal",
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createElementVNode("button", {
            class: "lukk-knapp",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('lukk')))
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("i", { class: "las la-times-circle" }, null, -1)
          ])),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ]))
    : _createCommentVNode("", true)
}