<template>
  <Suspense>
    <template #default>
      <FraktEtikett :bestillingId="id" />
    </template>
    <template #fallback>
      <div class="spinner-container">
        <Spinner />
      </div>
    </template>
  </Suspense>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { FraktEtikett } from "@/components";

export default defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  setup() {
    return {};
  },

  components: {
    FraktEtikett,
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.spinner-container {
  display: flex;
  justify-content: center;
}
</style>
