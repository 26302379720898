<template>
  <div class="bestilling-filter">
    <div class="input-gruppe">
      <label for="bestillingId">Bestilling-ID</label>
      <input
        id="bestillingId"
        type="text"
        v-model="filter.bestillingId"
        placeholder="Bestilling-ID"
        @keydown.enter="søk()"
        @blur="søk()"
      />
    </div>
    <div class="input-gruppe">
      <label for="ordreNummer">Ordrenummer</label>
      <input
        id="ordreNummer"
        type="text"
        v-model="filter.ordreNummer"
        placeholder="Ordrenummer"
        @keydown.enter="søk()"
        @blur="søk()"
      />
    </div>
    <div class="input-gruppe" v-if="isAdmin || isKundesenter">
      <label for="lager">Lager</label>
      <input id="lager" type="text" v-model="filter.lager" placeholder="Lager" @keydown.enter="søk()" @blur="søk()" />
    </div>
    <div class="input-gruppe">
      <label for="status">Status</label>
      <select v-model="bestillingStatus" id="status" @change="søk()">
        <option :value="undefined"></option>
        <option :value="BestillingStatus.åpen">{{ bestillingStatusTilTekst(BestillingStatus.åpen) }}</option>
        <option :value="BestillingStatus.underPlukking">
          {{ bestillingStatusTilTekst(BestillingStatus.underPlukking) }}
        </option>
        <option :value="BestillingStatus.plukket">{{ bestillingStatusTilTekst(BestillingStatus.plukket) }}</option>
      </select>
    </div>
    <div class="input-gruppe">
      <label for="kunde">Kunde</label>
      <input
        id="kunde"
        type="text"
        v-model="filter.kunde"
        placeholder="Kundenummer"
        @keydown.enter="søk()"
        @blur="søk()"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { BestillingStatus, BestillingStatusTekst, IBestillingFilter } from "@/types";
import authService from "@/utils/auth/authService";
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {},

  setup(_, { emit }) {
    const filter = ref<IBestillingFilter>({
      sorterSynkende: true,
    });

    const isKundesenter = ref(false);
    const isAdmin = ref(false);
    const bestillingStatus = ref<BestillingStatus | undefined>();
    authService.hasRole("Admin").then((result) => (isAdmin.value = result));
    authService.hasRole("Kundesenter").then((result) => (isKundesenter.value = result));

    const søk = () => {
      emit("søk", { ...filter.value, status: bestillingStatus.value ? [bestillingStatus.value] : [] });
    };

    const bestillingStatusTilTekst = (status: BestillingStatus): string | undefined => {
      return BestillingStatusTekst.get(status);
    };

    return { filter, søk, BestillingStatus, isAdmin, isKundesenter, bestillingStatusTilTekst, bestillingStatus };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.bestilling-filter {
  display: flex;
  gap: 1rem;
}

.input-gruppe {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}

input,
select {
  width: 10rem;
}
</style>
