import { CrudError } from "./crud";
import { toastError } from "./toastUtils";

export const handleVueError = (error: unknown): void => {
  if (error instanceof CrudError) {
    const crudError = error as CrudError;

    if (crudError.visTilBruker) {
      toastError(crudError.message);
    } else {
      toastError("En feil oppstod ved kommunikasjon med server.", 5);
    }
  } else {
    console.error("Unhandled Vue error", error);
  }
};

export const handleGlobalError = (event: ErrorEvent): void => {
  event.preventDefault();

  const error = event.error;
  console.error(error);
};

export const handleGlobalRejection = (event: PromiseRejectionEvent): void => {
  event.preventDefault();

  const error = event.reason;
  console.error("Unhandled rejection", error);
};
