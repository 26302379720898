import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "ruteliste" }
const _hoisted_2 = { class: "artikkel-info" }
const _hoisted_3 = { class: "artikkel-nummer" }
const _hoisted_4 = { class: "artikkel-navn" }
const _hoisted_5 = {
  key: 0,
  class: "egenskaper"
}
const _hoisted_6 = { class: "knapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ruter, (rute) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "rad",
          key: rute.artikkelNr
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(rute.artikkelNummer), 1),
            _createElementVNode("span", _hoisted_4, [
              _createTextVNode(_toDisplayString(rute.navn) + " ", 1),
              (rute.egenskaper.length)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, " – " + _toDisplayString(rute.egenskaper.map((x) => x.beskrivelse).join(", ")), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Button, {
              onClick: ($event: any) => (_ctx.leggTilArtikkel(rute))
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Legg til")
              ])),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]))
      }), 128))
    ])
  ]))
}