import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "errors"
}
const _hoisted_2 = { class: "input-gruppe" }
const _hoisted_3 = { class: "input-gruppe" }
const _hoisted_4 = { class: "input-gruppe" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 2,
  class: "spinner-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (!_ctx.lagrer && _ctx.tokenSjekket && _ctx.tokenGyldig)
    ? (_openBlock(), _createElementBlock("form", {
        key: 0,
        class: "registrer-bruker",
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.registrer && _ctx.registrer(...args)), ["prevent"]))
      }, [
        (_ctx.errors.length)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
                return (_openBlock(), _createElementBlock("li", { key: error }, _toDisplayString(error), 1))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _cache[4] || (_cache[4] = _createElementVNode("label", { for: "brukernavn" }, "Brukernavn", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.brukernavn) = $event)),
            id: "brukernavn"
          }, null, 512), [
            [_vModelText, _ctx.brukernavn]
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[5] || (_cache[5] = _createElementVNode("label", { for: "passord" }, "Passord", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.passord) = $event)),
            id: "passord"
          }, null, 512), [
            [_vModelText, _ctx.passord]
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[6] || (_cache[6] = _createElementVNode("label", { for: "gjentaPassord" }, "Gjenta passord", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.gjentaPassord) = $event)),
            id: "gjentaPassord"
          }, null, 512), [
            [_vModelText, _ctx.gjentaPassord]
          ])
        ]),
        _createVNode(_component_Button, {
          type: "primary",
          onClick: _ctx.registrer
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("Registrer")
          ])),
          _: 1
        }, 8, ["onClick"])
      ], 32))
    : (!_ctx.tokenGyldig)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Lenken du har fulgt for å opprette bruker er ikke gyldig. Ta kontakt med kundesenteret dersom du mener dette er en feil. "))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_Spinner)
        ]))
}