<template>
  <div>
    <div class="modellvelger-wrapper">
      <ModellVelger v-model:årsmodell="valgtÅrsmodell" />

      <label>Rute</label>
      <RuteTypeVelger class="rute-type-velger" v-model="ruteType" />
    </div>

    <CenteredSpinner class="søk-spinner" v-if="søker" />
    <RuteVelger v-if="ruter.length > 0" :ruter="ruter" @velg-rute="velgRute" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { IArtikkel, IÅrsmodell, RuteType } from "@/types";
import { hentRuterForÅrsmodell as getRuterForÅrsmodell } from "@/clients/bilInfoClient";
import RuteVelger from "./RuteVelger.vue";
import ModellVelger from "./ModellVelger.vue";
import RuteTypeVelger from "./RuteTypeVelger.vue";

export default defineComponent({
  props: {},

  setup(props, context) {
    const valgtÅrsmodell = ref<IÅrsmodell | undefined>();
    const ruteType = ref<RuteType>(RuteType.frontrute);
    const ruter = ref<IArtikkel[]>([]);
    const søker = ref(false);

    const hentRuterForÅrsmodell = async (årsmodell: IÅrsmodell | undefined, ruteType: RuteType | null = null) => {
      if (!årsmodell) {
        ruter.value = [];
        return;
      }

      søker.value = true;
      ruter.value = await getRuterForÅrsmodell(
        årsmodell.hovedgruppeId,
        årsmodell.undergruppeId,
        årsmodell.fra,
        ruteType
      );
      søker.value = false;
    };

    watch([valgtÅrsmodell, ruteType], (nyÅrsmodell) => hentRuterForÅrsmodell(nyÅrsmodell[0], ruteType.value));

    const velgRute = (rute: IArtikkel) => {
      context.emit("legg-til-artikkel", rute);
    };

    return { valgtÅrsmodell, hentRuterForÅrsmodell, ruter, velgRute, ruteType, søker };
  },

  components: { ModellVelger, RuteVelger, RuteTypeVelger },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.modellvelger-wrapper {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.rute-type-velger {
  width: 100%;
}

.søk-spinner {
  margin-top: 1rem;
}
</style>
