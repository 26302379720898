<template>
  <div>
    <LagerVelger v-if="isAdmin || isKundesenter" v-model="valgtLager" />
    <h1>
      Åpne bestillinger <span v-if="kunde">for kunde {{ kunde }}</span>
    </h1>

    <div class="kunde-bulk-knapper" v-if="lastet && kunde">
      <span class="vis-alle-kunder-wrapper">
        <ButtonRouterLink to="/apne-bestillinger"> Vis alle kunder</ButtonRouterLink>
      </span>

      <Button
        class="btn-avbryt-plukking-kunde"
        v-if="
          [...bestillinger, ...restordre].some((bestilling) => bestilling.status === BestillingStatus.underPlukking)
        "
        @click="håndterAvbrytPlukkingForKunde()"
      >
        <i class="las la-times-circle" />
        Avbryt plukking for valgt kunde
      </Button>
      <Button
        class="btn-start-plukking-kunde"
        v-if="[...bestillinger, ...restordre].some((bestilling) => bestilling.status === BestillingStatus.åpen)"
        @click="håndterStartPlukkingForKunde()"
      >
        <i class="las la-dolly" />
        Start plukking for valgt kunde
      </Button>
    </div>

    <LagerStatusFilter class="lager-status-filter" v-model="lagerStatus" @hent="hentBestillinger()" />

    <div v-if="!lastet && valgtLager" class="spinner-wrapper"><Spinner /></div>
    <ApneBestillinger
      v-if="lastet"
      class="bestilling-liste"
      :bestillinger="bestillinger"
      :restordre="restordre"
      @fjernBestilling="
        bestillinger = bestillinger.filter((x) => x !== $event);
        restordre = restordre.filter((x) => x !== $event);
      "
      @startPlukking="håndterStartPlukking($event)"
      @avbrytPlukking="håndterAvbrytPlukking($event)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { ÅpneBestillinger as ApneBestillinger, LagerVelger, LagerStatusFilter } from "@/components";
import { BestillingStatus, IBestilling, LagerStatus } from "@/types";
import { avbrytPlukking, hentBestillingerEtterAvgang, startPlukking } from "@/clients/bestillingClient";
import authService from "@/utils/auth/authService";
import { useRoute } from "vue-router";

const hentLagerStatus = (): LagerStatus[] | null => {
  const stringValue = localStorage.getItem("lager-status-filter");
  return stringValue ? JSON.parse(stringValue) : null;
};

const lagreLagerStatus = (status: LagerStatus[]) => {
  localStorage.setItem("lager-status-filter", JSON.stringify(status));
};

const ALLE_LAGER_STATUS = [
  LagerStatus.ok,
  LagerStatus.overallokert,
  LagerStatus.utilgjengeligPåHovedlokasjon,
  LagerStatus.tomtPåLager,
];

export default defineComponent({
  props: {},

  setup() {
    const lastet = ref(false);
    const lagerStatus = ref<LagerStatus[]>(hentLagerStatus() ?? [...ALLE_LAGER_STATUS]);
    const bestillinger = ref<IBestilling[]>([]);
    const restordre = ref<IBestilling[]>([]);
    const valgtLager = ref<string | undefined>();
    const route = useRoute();
    const kunde = ref<string>(route.query.kunde as string);

    const isKundesenter = ref(false);
    const isAdmin = ref(false);
    authService.hasRole("Admin").then((result) => {
      isAdmin.value = result;
      if (!result) {
        hentBestillinger();
      }
    });
    authService.hasRole("Kundesenter").then((result) => (isKundesenter.value = result));

    const hentBestillinger = async () => {
      lastet.value = false;
      lagreLagerStatus(lagerStatus.value);
      [bestillinger.value, restordre.value] = await Promise.all([
        hentBestillingerEtterAvgang(valgtLager.value, lagerStatus.value, false, kunde.value),
        hentBestillingerEtterAvgang(valgtLager.value, lagerStatus.value, true, kunde.value),
      ]);
      lastet.value = true;
    };

    watch([valgtLager], hentBestillinger);

    const håndterStartPlukking = async (bestilling: IBestilling) => {
      await startPlukking(bestilling.id);
      bestillinger.value = bestillinger.value.map((x) =>
        x.id === bestilling.id ? { ...bestilling, status: BestillingStatus.underPlukking } : x
      );
      restordre.value = restordre.value.map((x) =>
        x.id === bestilling.id ? { ...bestilling, status: BestillingStatus.underPlukking } : x
      );
    };

    const håndterAvbrytPlukking = async (bestilling: IBestilling) => {
      await avbrytPlukking(bestilling.id);
      bestillinger.value = bestillinger.value.map((x) =>
        x.id === bestilling.id ? { ...bestilling, status: BestillingStatus.åpen } : x
      );
      restordre.value = restordre.value.map((x) =>
        x.id === bestilling.id ? { ...bestilling, status: BestillingStatus.åpen } : x
      );
    };

    const håndterStartPlukkingForKunde = async () => {
      await Promise.all([
        ...bestillinger.value
          .filter((bestilling) => bestilling.status === BestillingStatus.åpen)
          .map(håndterStartPlukking),
        ...restordre.value
          .filter((bestilling) => bestilling.status === BestillingStatus.åpen)
          .map(håndterStartPlukking),
      ]);
    };

    const håndterAvbrytPlukkingForKunde = async () => {
      await Promise.all([
        ...bestillinger.value
          .filter((bestilling) => bestilling.status === BestillingStatus.underPlukking)
          .map(håndterAvbrytPlukking),
        ...restordre.value
          .filter((bestilling) => bestilling.status === BestillingStatus.underPlukking)
          .map(håndterAvbrytPlukking),
      ]);
    };

    return {
      bestillinger,
      restordre,
      lastet,
      isAdmin,
      isKundesenter,
      valgtLager,
      lagerStatus,
      hentBestillinger,
      kunde,
      håndterStartPlukking,
      håndterAvbrytPlukking,
      BestillingStatus,
      håndterStartPlukkingForKunde,
      håndterAvbrytPlukkingForKunde,
    };
  },

  watch: {
    "$route.query.kunde"() {
      this.kunde = this.$route.query.kunde as string;
      this.hentBestillinger();
    },
  },

  components: { ApneBestillinger, LagerVelger, LagerStatusFilter },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.spinner-wrapper {
  display: flex;
  justify-content: center;
}

.lager-status-filter {
  margin-bottom: 2.5rem;
}

.bestilling-liste {
  width: 100%;
}

.kunde-bulk-knapper {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;

  .vis-alle-kunder-wrapper {
    margin-right: auto;
  }

  .btn-avbryt-plukking-kunde {
    background: @orange;
  }
}
</style>
