<template>
  <div>
    <Modal :aktiv="vistBestilling !== null" @lukk="vistBestilling = null">
      <BekreftPlukking
        :bestilling="vistBestilling"
        @bestilling-bekreftet="
          $emit('fjern-bestilling', $event);
          vistBestilling = null;
        "
        @avbryt="vistBestilling = null"
      />
    </Modal>

    <input
      class="bestilling-søk"
      type="text"
      v-model="søkestreng"
      placeholder="Søk etter bestilling-ID eller ordrenummer"
    />

    <div v-if="restordre.length > 0">
      <h2>Fra restordre</h2>
      <p>
        Dette er ordre som ikke var ferdigbehandlet da systemet gikk ned. Ettersom denne listen kun oppdateres
        periodisk, er det mulig at noen av disse ordrene allikevel er plukket.
      </p>
      <ApneBestillingerTabell
        :bestillinger="filtrerteRestordre"
        @vis-detaljer="vistBestilling = $event"
        @start-plukking="$emit('start-plukking', $event)"
        @avbryt-plukking="$emit('avbryt-plukking', $event)"
        :restordre="true"
      />
    </div>
    <h2 v-if="restordre.length > 0">Fra reservesystemet</h2>
    <ApneBestillingerTabell
      :bestillinger="filtrerteBestillinger"
      @vis-detaljer="vistBestilling = $event"
      @start-plukking="$emit('start-plukking', $event)"
      @avbryt-plukking="$emit('avbryt-plukking', $event)"
    />
  </div>
</template>

<script lang="ts">
import { IBestilling, IBestillingMedAvgang } from "@/types";
import { computed, defineComponent, PropType, ref } from "vue";
import BekreftPlukking from "./BekreftPlukking.vue";
import ApneBestillingerTabell from "./ÅpneBestillingerTabell.vue";

export default defineComponent({
  props: {
    bestillinger: {
      type: Array as PropType<IBestillingMedAvgang[]>,
      required: true,
    },
    restordre: {
      type: Array as PropType<IBestillingMedAvgang[]>,
      required: true,
    },
  },

  setup(props) {
    const vistBestilling = ref<IBestilling | null>(null);
    const søkestreng = ref("");

    const filtrer = (bestilling: IBestilling): boolean =>
      (søkestreng.value.length === 0 ||
        bestilling.id.toString().startsWith(søkestreng.value) ||
        bestilling.ordreNummer?.toString().startsWith(søkestreng.value)) ??
      false;

    const filtrerteBestillinger = computed(() => props.bestillinger.filter(filtrer));
    const filtrerteRestordre = computed(() => props.restordre.filter(filtrer));

    return {
      vistBestilling,
      filtrerteBestillinger,
      filtrerteRestordre,
      søkestreng,
    };
  },

  components: { BekreftPlukking, ApneBestillingerTabell },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.bestilling-søk {
  width: 100%;
}
</style>
