<template>
  <div>
    <h1>Opprett tokens for alle kunder</h1>
    <div>
      <p>
        Dette verktøyet lar deg opprette tokens for alle kunder som bruker Bilglassportalen. Disse lar kundene selv
        registrere brukere i reserveløsningen. Hver token er gyldig i 14 dager.
      </p>
      <p>
        Under kan du velge om tokens skal sendes til alle kunder på e-post, SMS, eller om du bare vil laste ned en CSV
        med alle tokens.
      </p>
    </div>
    <OpprettTokensForAlleKunder />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OpprettTokensForAlleKunder from "@/components/admin/OpprettTokensForAlleKunder.vue";

export default defineComponent({
  props: {},

  setup() {
    return {};
  },

  components: {
    OpprettTokensForAlleKunder,
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
</style>
