<template>
  <div class="wrapper">
    <ul>
      <li v-for="rolle in brukerRoller" :key="rolle.id">
        {{ rolle.navn }}
        <Button @click="fjernRolle(rolle.navn)" type="danger">Fjern</Button>
      </li>
    </ul>

    <div class="legg-til-wrapper">
      <select v-model="valgtRolle" class="rollevelger">
        <option :value="undefined" disabled>-- Velg rolle --</option>
        <option v-for="rolle in valgbareRoller" :value="rolle" :key="rolle.id">
          {{ rolle.navn }}
        </option>
      </select>
      <Button @click="leggTilRolle">Legg til</Button>
    </div>
  </div>
</template>

<script lang="ts">
import { fjernRolleForBruker, hentRoller, hentRollerForBruker, leggTilRolleForBruker } from "@/clients/brukerClient";
import { IRolle } from "@/types";
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    brukerId: {
      type: String,
      required: true,
    },
  },

  computed: {
    valgbareRoller(): IRolle[] {
      return this.roller.filter((rolle) => !this.brukerRoller.some((r) => r.id === rolle.id));
    },
  },

  setup(props) {
    const roller = ref<IRolle[]>([]);
    const brukerRoller = ref<IRolle[]>([]);

    hentRoller().then((resultat) => (roller.value = resultat));
    hentRollerForBruker(props.brukerId).then((resultat) => (brukerRoller.value = resultat));

    const valgtRolle = ref<IRolle | undefined>();

    const leggTilRolle = async () => {
      if (!valgtRolle.value) return;

      await leggTilRolleForBruker(props.brukerId, valgtRolle.value.navn);
      brukerRoller.value = [...brukerRoller.value, valgtRolle.value];
      valgtRolle.value = undefined;
    };

    const fjernRolle = async (rolleNavn: string) => {
      await fjernRolleForBruker(props.brukerId, rolleNavn);
      brukerRoller.value = brukerRoller.value.filter((rolle) => rolle.navn !== rolleNavn);
    };

    return { roller, brukerRoller, valgtRolle, leggTilRolle, fjernRolle };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

ul {
  padding: 0;
}

li {
  list-style: none;
  padding: 0.8rem 1rem;

  display: flex;

  button {
    margin-left: auto;
  }
}

li:not(:last-child) {
  border-bottom: 1px solid @black80;
}

.legg-til-wrapper {
  margin-top: 2.5rem;
  width: 100%;
  display: flex;
  gap: 1rem;

  .rollevelger {
    flex-grow: 1;
    min-width: 15rem;
  }

  button {
    padding: 0.5rem 2rem;
  }
}
</style>
