import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bekreft-plukking" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "antall-kolonne" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["onUpdate:modelValue"]
const _hoisted_6 = { class: "button-row" }
const _hoisted_7 = {
  key: 1,
  class: "spinner-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LagerStatusIkon = _resolveComponent("LagerStatusIkon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Bestillingsdetaljer", -1)),
    (_ctx.fullBestilling)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("table", null, [
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fullBestilling.linjer, (linje) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: linje.id
                }, [
                  _createElementVNode("td", null, [
                    _createVNode(_component_LagerStatusIkon, {
                      lagerStatus: linje.lagerStatus
                    }, null, 8, ["lagerStatus"])
                  ]),
                  _createElementVNode("td", null, [
                    _createVNode(_component_router_link, {
                      to: `/artikler/${linje.artikkel.artikkelNummer}`,
                      target: "_blank",
                      rel: "noopener noreferrer"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(linje.artikkel.artikkelNummer) + " – " + _toDisplayString(linje.artikkel.navn), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _createElementVNode("td", _hoisted_3, [
                    (linje.antall != _ctx.antall[linje.id])
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Opprinnelig antall: " + _toDisplayString(linje.antall), 1))
                      : _createCommentVNode("", true),
                    _withDirectives(_createElementVNode("input", {
                      type: "number",
                      "onUpdate:modelValue": ($event: any) => ((_ctx.antall[linje.id]) = $event),
                      min: "0"
                    }, null, 8, _hoisted_5), [
                      [_vModelText, _ctx.antall[linje.id]]
                    ])
                  ])
                ]))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Button, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.avbryt()))
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Avbryt")
              ])),
              _: 1
            }),
            (_ctx.bestilling.status === _ctx.BestillingStatus.underPlukking)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.doBekreftPlukking())),
                  type: "primary"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Bekreft")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_Spinner)
        ]))
  ]))
}