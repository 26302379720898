<template>
  <div class="page-options">
    <label>Antall per side:</label>
    <select
      v-model="pageSize"
      @change="
        page = 1;
        $emit('update:modelValue', page);
      "
    >
      <option v-for="size in [10, 25, 50, 100]" :key="size" :value="size">
        {{ size }}
      </option>
    </select>
    <div class="page-selector">
      <button
        @click="
          page = Math.max(page - 1, 1);
          $emit('update:modelValue', page);
        "
      >
        <i class="las la-angle-double-left"></i>
      </button>
      Side
      <input
        type="number"
        v-model="page"
        min="1"
        :max="antallSider"
        @blur="håndterSideEndring()"
        @keydown.enter="håndterSideEndring()"
        @mouseup="$emit('update:modelValue', page)"
      />
      av {{ antallSider }}
      <button
        @click="
          page = Math.min(page + 1, antallSider);
          $emit('update:modelValue', page);
        "
      >
        <i class="las la-angle-double-right"></i>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: Number,
      default: 1,
    },
    antallPerSide: {
      type: Number,
      default: 25,
    },
    totaltAntall: {
      type: Number,
    },
  },

  setup(props, context) {
    const page = ref(props.modelValue);
    const pageSize = ref(props.antallPerSide);

    watch([pageSize], () => {
      context.emit("update:antallPerSide", pageSize.value);
    });

    const antallSider = computed(() => Math.ceil((props.totaltAntall ?? 0) / pageSize.value));

    const håndterSideEndring = () => {
      if (page.value < 1) page.value = 1;
      if (page.value > antallSider.value) page.value = antallSider.value;
      context.emit("update:modelValue", page.value);
    };

    return { page, pageSize, håndterSideEndring, antallSider };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.page-options {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.page-selector {
  margin-left: auto;

  input {
    width: 2.1rem;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 0.9rem;
  }
}
</style>
