import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "input-rad" }
const _hoisted_2 = {
  key: 0,
  class: "bilinfo"
}
const _hoisted_3 = {
  key: 1,
  class: "ingen-treff-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RuteTypeVelger = _resolveComponent("RuteTypeVelger")!
  const _component_Button = _resolveComponent("Button")!
  const _component_CenteredSpinner = _resolveComponent("CenteredSpinner")!
  const _component_RuteVelger = _resolveComponent("RuteVelger")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.regNr) = $event)),
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.harSøkt = false)),
        onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.søkOppRuter()), ["enter"])),
        placeholder: "Registreringsnummer"
      }, null, 544), [
        [_vModelText, _ctx.regNr]
      ]),
      _createVNode(_component_RuteTypeVelger, {
        class: "rute-type-velger",
        modelValue: _ctx.ruteType,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.ruteType) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_Button, {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.søkOppRuter()))
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("Søk")
        ])),
        _: 1
      })
    ]),
    (_ctx.bilInfo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.bilInfo.merke) + " " + _toDisplayString(_ctx.bilInfo.modell) + " " + _toDisplayString(_ctx.bilInfo.årsmodellFra), 1))
      : _createCommentVNode("", true),
    (!_ctx.søker && _ctx.harSøkt && _ctx.ruter.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Ingen treff"))
      : _createCommentVNode("", true),
    (_ctx.søker)
      ? (_openBlock(), _createBlock(_component_CenteredSpinner, {
          key: 2,
          class: "søk-spinner"
        }))
      : _createCommentVNode("", true),
    (_ctx.ruter.length > 0)
      ? (_openBlock(), _createBlock(_component_RuteVelger, {
          key: 3,
          ruter: _ctx.ruter,
          onVelgRute: _ctx.velgRute
        }, null, 8, ["ruter", "onVelgRute"]))
      : _createCommentVNode("", true)
  ]))
}