<template>
  <div>
    <div class="input-rad">
      <input
        type="text"
        v-model="regNr"
        @input="harSøkt = false"
        @keyup.enter="søkOppRuter()"
        placeholder="Registreringsnummer"
      />
      <RuteTypeVelger class="rute-type-velger" v-model="ruteType" />
      <Button @click="søkOppRuter()">Søk</Button>
    </div>
    <div class="bilinfo" v-if="bilInfo">{{ bilInfo.merke }} {{ bilInfo.modell }} {{ bilInfo.årsmodellFra }}</div>
    <div class="ingen-treff-info" v-if="!søker && harSøkt && ruter.length === 0">Ingen treff</div>
    <CenteredSpinner class="søk-spinner" v-if="søker" />
    <RuteVelger v-if="ruter.length > 0" :ruter="ruter" @velg-rute="velgRute" />
  </div>
</template>

<script lang="ts">
import { IArtikkel, IBilInfo, RuteType } from "@/types";
import { defineComponent, ref } from "vue";
import RuteVelger from "./RuteVelger.vue";
import RuteTypeVelger from "./RuteTypeVelger.vue";
import { hentBilInfo, hentRuterForRegNr } from "@/clients/bilInfoClient";

export default defineComponent({
  props: {},

  setup(props, context) {
    const regNr = ref("");
    const ruter = ref<IArtikkel[]>([]);
    const ruteType = ref<RuteType>(RuteType.frontrute);
    const bilInfo = ref<IBilInfo | undefined>();
    const harSøkt = ref(false);
    const søker = ref(false);

    const søkOppRuter = async () => {
      ruter.value = [];
      if (regNr.value.length === 0) {
        return;
      }
      søker.value = true;
      try {
        const rutePromise = hentRuterForRegNr(regNr.value, ruteType.value);
        const bilInfoPromise = hentBilInfo(regNr.value);
        [ruter.value, bilInfo.value] = await Promise.all([rutePromise, bilInfoPromise]);
      } finally {
        harSøkt.value = true;
        søker.value = false;
      }
    };

    const velgRute = (rute: IArtikkel) => {
      context.emit("legg-til-artikkel", rute);
    };

    return { regNr, ruter, søkOppRuter, velgRute, harSøkt, bilInfo, ruteType, søker };
  },

  components: { RuteVelger, RuteTypeVelger },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.input-rad {
  display: flex;
  gap: 0.8rem;
}

.bilinfo {
  margin-top: 1rem;
  padding: 0 0.8rem;
  font-size: 0.8rem;
  font-weight: bold;
}

.ingen-treff-info {
  margin-top: 0.8rem;
  color: @red;
}

.søk-spinner {
  margin-top: 1rem;
}
</style>
