<template>
  <div v-if="!lastet" class="spinner-container">
    <Spinner />
  </div>
  <div v-else class="wrapper">
    <h1>Brukerdetaljer</h1>
    <div class="knapper-container">
      <ButtonRouterLink :to="`/brukere/${brukerId}/rediger`">Rediger</ButtonRouterLink>
      <Button type="danger" @click="slettBruker()">Slett</Button>
    </div>
    <Bruker :bruker="bruker" />
    <h2>Tilbakestill passord</h2>
    <TilbakestillPassord :brukerId="bruker.id" />
    <div class="roller-wrapper">
      <h2>Roller</h2>
      <BrukerRoller :brukerId="bruker.id" />
    </div>
  </div>
</template>

<script lang="ts">
import { hentBruker, slettBruker as doSlettBruker } from "@/clients/brukerClient";
import { DEFAULT_BRUKER, IBruker } from "@/types";
import { defineComponent, ref } from "vue";
import Spinner from "@/components/_basis/Spinner.vue";
import { Bruker, BrukerRoller, TilbakestillPassord } from "@/components";
import { bekreftFare } from "@/utils/dialogUtils";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {
    brukerId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const lastet = ref(false);
    const bruker = ref<IBruker>({ ...DEFAULT_BRUKER });
    const router = useRouter();

    hentBruker(props.brukerId).then((resultat) => {
      bruker.value = resultat;
      lastet.value = true;
    });

    const slettBruker = async () => {
      bekreftFare(`Er du sikker på at du vil slette brukeren "${bruker.value.brukerNavn}"?`, {
        onConfirm: async () => {
          await doSlettBruker(props.brukerId);
          router.push("/brukere");
        },
      });
    };

    return { lastet, bruker, slettBruker };
  },

  components: {
    Spinner,
    Bruker,
    BrukerRoller,
    TilbakestillPassord,
  },
});
</script>

<style lang="less" scoped>
.spinner-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.roller-wrapper {
  margin-top: 3rem;
}

.knapper-container {
  display: flex;
  gap: 0.8rem;
  float: right;
}
</style>
