import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BekreftPlukking = _resolveComponent("BekreftPlukking")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_ApneBestillingerTabell = _resolveComponent("ApneBestillingerTabell")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Modal, {
      aktiv: _ctx.vistBestilling !== null,
      onLukk: _cache[2] || (_cache[2] = ($event: any) => (_ctx.vistBestilling = null))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BekreftPlukking, {
          bestilling: _ctx.vistBestilling,
          onBestillingBekreftet: _cache[0] || (_cache[0] = ($event: any) => {
          _ctx.$emit('fjern-bestilling', $event);
          _ctx.vistBestilling = null;
        }),
          onAvbryt: _cache[1] || (_cache[1] = ($event: any) => (_ctx.vistBestilling = null))
        }, null, 8, ["bestilling"])
      ]),
      _: 1
    }, 8, ["aktiv"]),
    _withDirectives(_createElementVNode("input", {
      class: "bestilling-søk",
      type: "text",
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.søkestreng) = $event)),
      placeholder: "Søk etter bestilling-ID eller ordrenummer"
    }, null, 512), [
      [_vModelText, _ctx.søkestreng]
    ]),
    (_ctx.restordre.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[10] || (_cache[10] = _createElementVNode("h2", null, "Fra restordre", -1)),
          _cache[11] || (_cache[11] = _createElementVNode("p", null, " Dette er ordre som ikke var ferdigbehandlet da systemet gikk ned. Ettersom denne listen kun oppdateres periodisk, er det mulig at noen av disse ordrene allikevel er plukket. ", -1)),
          _createVNode(_component_ApneBestillingerTabell, {
            bestillinger: _ctx.filtrerteRestordre,
            onVisDetaljer: _cache[4] || (_cache[4] = ($event: any) => (_ctx.vistBestilling = $event)),
            onStartPlukking: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('start-plukking', $event))),
            onAvbrytPlukking: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('avbryt-plukking', $event))),
            restordre: true
          }, null, 8, ["bestillinger"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.restordre.length > 0)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "Fra reservesystemet"))
      : _createCommentVNode("", true),
    _createVNode(_component_ApneBestillingerTabell, {
      bestillinger: _ctx.filtrerteBestillinger,
      onVisDetaljer: _cache[7] || (_cache[7] = ($event: any) => (_ctx.vistBestilling = $event)),
      onStartPlukking: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('start-plukking', $event))),
      onAvbrytPlukking: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('avbryt-plukking', $event)))
    }, null, 8, ["bestillinger"])
  ]))
}