<template>
  <div>
    <Button type="primary" @click="eksporter">Last ned mine oppdrag</Button>
  </div>
</template>

<script setup lang="ts">
import authService from "@/utils/auth/authService";
import { lastNedKundeEksport } from "@/utils/lastNedEksport";

const eksporter = async () => {
  const user = await authService.getUser();
  await lastNedKundeEksport(user?.kundeNr);
};
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
</style>
