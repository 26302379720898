import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "fyll-på-artikler" }
const _hoisted_2 = { class: "input-gruppe" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "input-gruppe" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CenteredSpinner = _resolveComponent("CenteredSpinner")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("h2", null, "Fyll på artikler", -1)),
    (_ctx.lagrer)
      ? (_openBlock(), _createBlock(_component_CenteredSpinner, { key: 0 }))
      : (_openBlock(), _createElementBlock("form", {
          key: 1,
          class: "skjema",
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.lagre && _ctx.lagre(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_2, [
            _cache[3] || (_cache[3] = _createElementVNode("label", { for: "lokasjon" }, " Lokasjon ", -1)),
            _withDirectives(_createElementVNode("select", {
              id: "lokasjon",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.lokasjon) = $event))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lokasjoner, (lokasjon) => {
                return (_openBlock(), _createElementBlock("option", {
                  value: lokasjon.lokasjon,
                  key: lokasjon.lokasjon
                }, _toDisplayString(lokasjon.lokasjon) + ": " + _toDisplayString(lokasjon.saldo), 9, _hoisted_3))
              }), 128))
            ], 512), [
              [_vModelSelect, _ctx.lokasjon]
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _cache[4] || (_cache[4] = _createElementVNode("label", { for: "antall" }, "Antall", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "number",
              id: "antall",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.antall) = $event)),
              placeholder: "Antall"
            }, null, 512), [
              [_vModelText, _ctx.antall]
            ])
          ]),
          _createVNode(_component_Button, {
            type: "primary",
            onClick: _ctx.lagre
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Lagre")
            ])),
            _: 1
          }, 8, ["onClick"])
        ], 32))
  ]))
}