<template>
  <form class="tilbakestill-passord" @submit.prevent="utførTilbakestillPassord()">
    <ul v-if="errors.length" class="errors">
      <li v-for="error in errors" :key="error">
        {{ error }}
      </li>
    </ul>
    <div class="input-gruppe">
      <label for="passord">Passord</label>
      <input type="password" id="passord" v-model="passord" placeholder="Passord" @input="lagret = false" />
    </div>
    <div class="input-gruppe">
      <label for="gjentaPassord">Gjenta passord</label>
      <input type="password" id="gjentaPassord" v-model="gjentaPassord" placeholder="Gjenta passord" />
    </div>
    <Button type="primary" @click="utførTilbakestillPassord()">Lagre</Button>
  </form>
</template>

<script lang="ts">
import { tilbakestillPassord } from "@/clients/brukerClient";
import { toast, validerPassord } from "@/utils";
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    brukerId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const passord = ref("");
    const gjentaPassord = ref("");
    const errors = ref<string[]>([]);
    const lagret = ref(false);

    const utførTilbakestillPassord = async () => {
      if (passord.value !== gjentaPassord.value) {
        errors.value = ["Passordene er ikke like"];
        return;
      }

      errors.value = [];
      const [passordGyldig, valideringsfeil] = validerPassord(passord.value);
      if (!passordGyldig) {
        errors.value = valideringsfeil;
        return;
      }

      await tilbakestillPassord(props.brukerId, passord.value);
      passord.value = "";
      gjentaPassord.value = "";
      lagret.value = true;
      toast("Passordet ble endret");
    };

    return { passord, gjentaPassord, utførTilbakestillPassord, errors, lagret };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.errors {
  list-style: none;
  margin: 0;
  padding: 0;
  color: @red;
}

.tilbakestill-passord {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.input-gruppe {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
