import { DialogType, IDialog, IDialogOptions } from "@/types";
import { reactive } from "vue";

export const dialoger = reactive([] as IDialog[]);
let idCounter = 0;

const leggTilDialog = (type: DialogType, text: string, options: IDialogOptions = {}): void => {
  dialoger.push({
    text,
    ...options,
    type,
    id: idCounter++,
  });
};

export const bekreftFare = (tekst: string, options: IDialogOptions = {}): void => {
  console.log("Bekreft fare");
  leggTilDialog("danger", tekst, options);
  console.log(dialoger);
};

export const fjernDialog = (dialog: IDialog): void => {
  const dialogIndex = dialoger.findIndex((x) => x === dialog);
  dialoger.splice(dialogIndex, 1);
};
