<template>
  <div class="bestilling-detaljer">
    <h1>Bestilling</h1>
    <div>
      <div class="skjema-felt">
        <label>Bestilling-ID</label>
        <p>{{ bestilling.id }}</p>
      </div>
      <div class="skjema-felt">
        <label>Dato</label>
        <p>{{ formaterDatoTid(bestilling.opprettet) }}</p>
      </div>

      <h2>Bestilte artikler</h2>
      <table class="artikkel-tabell" v-if="bestilling.linjer.length > 0">
        <thead>
          <th>Artikkelnummer</th>
          <th>Navn</th>
          <th>Bruttopris</th>
          <th>Antall</th>
          <th>Sum</th>
        </thead>
        <tbody>
          <tr v-for="linje in bestilling.linjer" :key="linje.id">
            <td>{{ linje.artikkelNr }}</td>
            <td>
              {{ linje.artikkel.navn }}
              <em v-if="linje.merknad">
                <br />
                Merknad:
                {{ linje.merknad }}
              </em>
            </td>
            <td>{{ linje.artikkel.bruttoPris }} kr</td>
            <td>{{ linje.antall }}</td>
            <td>{{ linje.antall * linje.artikkel.bruttoPris }} kr</td>
          </tr>
        </tbody>
      </table>
      <p v-else>Ingen bestilte artikler</p>
    </div>
  </div>
</template>

<script lang="ts">
import { IBestilling } from "@/types";
import { formaterDatoTid } from "@/utils";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    bestilling: {
      type: Object as PropType<IBestilling>,
      required: true,
    },
  },

  setup() {
    return { formaterDatoTid };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
.artikkel-tabell {
  width: 100%;
}

h2 {
  margin-top: 3rem;
}
</style>
