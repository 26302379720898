import { IBruker } from "./auth";
import { IKunde, KundeStatus } from "./domene";

export const DEFAULT_KUNDE: IKunde = {
  kundeNummer: "",
  status: KundeStatus.foreløpig,
  navn: "",
  adresselinje1: "",
  postNr: "",
  postSted: "",
  telefonNummer1: "",
  telefonNummer2: "",
  deresReferanse: "",
  kundesLager: "",
  leveringsmåte: "",
};

export const DEFAULT_BRUKER: IBruker = {
  id: "",
  brukerNavn: "",
  epost: "",
  telefonNummer: "",
  lager: "",
  kundeNr: "",
};
