import { IRutesøk, IRutesøkFilter, IRutesøkMedSideInfo, RuteType } from "@/types";
import { get, post } from "@/utils";

export const hentRutesøk = async (filter: IRutesøkFilter): Promise<IRutesøkMedSideInfo> => {
  const params = new URLSearchParams();

  if (filter.kunde) params.append("kunde", filter.kunde);

  params.append("page", filter.page?.toString() ?? "0");
  params.append("pageSize", filter.pageSize?.toString() ?? "10");

  let result = await get(`/api/rutesøk?${params.toString()}`);

  result = {
    ...result,
    rutesøk: result.rutesøk.map((r: IRutesøk) => ({
      ...r,
      svar: r.svar ? { ...r.svar, opprettet: new Date(r.svar.opprettet) } : r.svar,
    })),
  };

  return result;
};

export const opprettRutesøk = async (
  kundeNr: string | undefined,
  regNr: string,
  chassisNr: string,
  ruteType: RuteType,
  merke: string,
  modell: string,
  årsmodell: string
): Promise<void> => {
  const request = {
    kundeNr,
    regNr,
    chassisNr,
    ruteType,
    merke,
    modell,
    årsmodell,
  };

  await post(`/api/rutesøk`, request);
};
