<template>
  <div class="rediger-bruker">
    <h1>Rediger bruker</h1>

    <CenteredSpinner v-if="laster || lagrer" />

    <form class="form" @submit.prevent="lagre()" v-else>
      <ul v-if="errors.length" class="errors">
        <li v-for="error in errors" :key="error">{{ error }}</li>
      </ul>
      <div class="input-gruppe">
        <label for="brukernavn">Brukernavn</label>
        <input type="text" id="brukernavn" v-model="bruker.brukerNavn" disabled />
      </div>

      <div class="input-gruppe">
        <label for="lager">Lager</label>
        <input type="text" id="lager" v-model="bruker.lager" />
      </div>

      <div class="input-gruppe">
        <label for="kundeNr">Kundenummer</label>
        <input type="text" id="kundeNr" v-model="bruker.kundeNr" />
      </div>

      <Button @click="lagre()" type="primary" :disabled="lagrer"> Lagre </Button>
    </form>
  </div>
</template>

<script lang="ts">
import { hentBruker, redigerBruker } from "@/clients/brukerClient";
import { IBruker } from "@/types";
import { toast } from "@/utils";
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {
    brukerId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const errors = ref<string[]>([]);
    const laster = ref(true);
    const lagrer = ref(false);
    const bruker = ref<IBruker>();

    hentBruker(props.brukerId).then((resultat) => {
      bruker.value = resultat;
      laster.value = false;
    });

    const router = useRouter();

    const lagre = async () => {
      if (!bruker.value) return;

      lagrer.value = true;
      errors.value = [];

      try {
        await redigerBruker(props.brukerId, bruker.value.lager, bruker.value.kundeNr);
      } finally {
        lagrer.value = false;
      }
      toast(`Oppdaterte informasjon for bruker "${bruker.value?.brukerNavn}"`);
      router.push(`/brukere/${props.brukerId}`);
    };

    return { errors, lagre, lagrer, laster, bruker };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.input-gruppe {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.errors {
  list-style: none;
  color: @red;
  padding: 0;
  margin: 0;
}
</style>
