<template>
  <div class="driftsetting">
    <div class="steg">
      <a href="#steg1" class="neste-steg"><i class="las la-angle-double-down" /></a>
      <h1>Driftsetting av reserveløsningen</h1>
      <p>Nedenfor er den stegvise prosessen for å gjøre løsningen driftsklar i en nødssituasjon beskrevet.</p>
    </div>
    <div id="steg1" class="steg">
      <a href="#steg2" class="neste-steg"><i class="las la-angle-double-down" /></a>
      <h2>Steg 1: Stans automatisk import av data</h2>
      <p>
        I en normalsituasjon importerer reserveløsningen oppdaterte data jevnlig. Ved overgang til reservedrift må denne
        importen stanses slik at dataene blir statiske, og dermed kan brukes til bestilling av varer, lagerstyring osv.
      </p>

      <div class="verktøy">
        Automatisk import er <strong>{{ automatiskImportAktivert ? "aktivert" : "deaktivert" }}</strong
        >. <Button @click="settAutomatiskImport(false)" v-if="automatiskImportAktivert">Deaktiver</Button>
      </div>
    </div>
    <div id="steg2" class="steg">
      <a href="#steg3" class="neste-steg"><i class="las la-angle-double-down" /></a>
      <h2>Steg 2: Last inn nyeste gyldige data til reserveløsningen</h2>
      <p>
        Som beskrevet i steg 1 importerer reserveløsningen oppdaterte data jevnlig. I et krisetilfelle kan det være at
        nyeste data ikke er gyldige, og at en eldre backup av data må benyttes. Hvis de nyeste dataene er gyldige kan
        man hoppe over dette steget.
      </p>
      <p>
        Vær oppmerksom på at importprosessen tar lang tid – normalt sett opp mot 10 minutter. Hvis du blir værende på
        siden, vil du få et varsel når import er ferdig.
      </p>
      <div class="verktøy">
        <Import />
      </div>
    </div>
    <div id="steg3" class="steg">
      <a href="#steg4" class="neste-steg"><i class="las la-angle-double-down" /></a>
      <h2>Steg 3: Opprett brukere for alle aktive kunder</h2>
      <p>
        Vi må opprette brukere for alle aktive kunder av Bilglassgruppen. Verktøyet under lar deg automatisk opprette og
        sende ut lenker til alle kunder, som igjen lar kundene selv registrere brukere. Lenkene er gyldige i 14 dager,
        og ved registrering vil brukerene automatisk knyttes til riktig kunde, slik at de kan legge inn bestillinger så
        fort brukeren er opprettet og de har logget inn.
      </p>
      <div class="verktøy">
        <OpprettTokensForAlleKunder />
      </div>
      <p>
        Ved behov kan nye lenker for enkeltkunder opprettes ved å bruke verktøyet under "Admin" -> "Brukere" -> "Opprett
        registreringstoken".
      </p>
    </div>
    <div id="steg4" class="steg">
      <a href="#ferdig" class="neste-steg"><i class="las la-angle-double-down" /></a>
      <h2>Steg 4: Opprett brukere for lager- og kundesenter-medarbeidere</h2>
      <p>
        Brukere for ansatte i Bilglassgruppen som jobber på lager eller kundesenter må opprettes manuelt – enten ved å
        lage tokens som beskrevet over uten å velge en kundetilknytning, eller ved å bruke verktøyet under "Admin" ->
        "Brukere" -> "Opprett ny bruker". Etter at brukeren er opprettet må riktig roller settes fra brukerlisten.
        Brukeren vil så ha riktige privilegier etter å ha logget ut og inn igjen.
      </p>
      <div class="verktøy">
        <ButtonRouterLink to="/brukere">Gå til brukeradministrasjon</ButtonRouterLink>
      </div>
    </div>
    <div id="ferdig" class="steg">
      <h2>Løsningen er driftsklar</h2>
      <p>Etter at alle stegene ovenfor er gjennomført er løsningen klar til drift.</p>
    </div>
  </div>
</template>

<script lang="ts">
import { hentAutomatiskImportVerdi, settAutomatiskImport as postAutomatiskImport } from "@/clients/configClient";
import { defineComponent, ref } from "vue";
import OpprettTokensForAlleKunder from "./OpprettTokensForAlleKunder.vue";
import Import from "./Import.vue";

export default defineComponent({
  props: {},

  setup() {
    const automatiskImportAktivert = ref(false);

    hentAutomatiskImportVerdi().then((result) => (automatiskImportAktivert.value = result));

    const settAutomatiskImport = async (verdi: boolean) => {
      await postAutomatiskImport(verdi);
      automatiskImportAktivert.value = verdi;
    };

    return { automatiskImportAktivert, settAutomatiskImport };
  },

  components: { OpprettTokensForAlleKunder, Import },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.driftsetting {
  max-width: 100ch;
}

.steg {
  position: relative;
  &:not(:first-child) {
    padding-top: 1rem;
    margin-top: 7rem;

    .neste-steg {
      top: 2rem;
    }
  }

  .neste-steg {
    color: @black30;
    font-size: 1rem;
    position: absolute;
    left: -1.5rem;
  }
}

.steg:last-child {
  min-height: calc(100vh - 4rem);
}

.verktøy {
  margin-left: 4rem;
  padding: 1rem;
  border-left: 5px solid @blue-lighter;
}
</style>
