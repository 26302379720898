import { RouteRecordRaw } from "vue-router";

import LoginCallback from "./LoginCallback.vue";
import Login from "./Login.vue";
import LogOut from "./LogOut.vue";
import LogoutCallback from "./LogoutCallback.vue";
import NotAllowed from "./NotAllowed.vue";

const prefix = "/auth";

export const authRoutes: RouteRecordRaw[] = [
  {
    path: prefix + "/login-callback",
    component: LoginCallback,
    meta: { guest: true },
  },
  {
    path: prefix + "/login",
    component: Login,
    meta: { guest: true },
  },
  {
    path: prefix + "/not-allowed",
    component: NotAllowed,
    meta: { guest: true },
  },
  {
    path: prefix + "/logout",
    component: LogOut,
  },
  {
    path: prefix + "/logout-callback",
    component: LogoutCallback,
    meta: { guest: true },
  },
];

export default authRoutes;
