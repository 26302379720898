<template>
  <div>
    {{ errorMessage }}
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import authService, { AuthenticationResultStatus } from "@/utils/auth/authService";

export default defineComponent({
  props: {},

  setup() {
    const url = window.location.href;
    const errorMessage = ref<string | undefined>("");

    authService.completeSignIn(url).then(async (result) => {
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          throw new Error("Invalid status");
        case AuthenticationResultStatus.Success:
          await authService.navigateToUrl(authService.getReturnUrl(result.state));
          break;
        case AuthenticationResultStatus.Fail:
          errorMessage.value = result.message;
          break;
        default:
          throw new Error(`Invalid authentication result status '${result.status}'`);
      }
    });
    return {
      errorMessage,
    };
  },
});
</script>

<style lang="less" scoped></style>
