import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modellvelger-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModellVelger = _resolveComponent("ModellVelger")!
  const _component_RuteTypeVelger = _resolveComponent("RuteTypeVelger")!
  const _component_CenteredSpinner = _resolveComponent("CenteredSpinner")!
  const _component_RuteVelger = _resolveComponent("RuteVelger")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ModellVelger, {
        årsmodell: _ctx.valgtÅrsmodell,
        "onUpdate:årsmodell": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valgtÅrsmodell) = $event))
      }, null, 8, ["årsmodell"]),
      _cache[2] || (_cache[2] = _createElementVNode("label", null, "Rute", -1)),
      _createVNode(_component_RuteTypeVelger, {
        class: "rute-type-velger",
        modelValue: _ctx.ruteType,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ruteType) = $event))
      }, null, 8, ["modelValue"])
    ]),
    (_ctx.søker)
      ? (_openBlock(), _createBlock(_component_CenteredSpinner, {
          key: 0,
          class: "søk-spinner"
        }))
      : _createCommentVNode("", true),
    (_ctx.ruter.length > 0)
      ? (_openBlock(), _createBlock(_component_RuteVelger, {
          key: 1,
          ruter: _ctx.ruter,
          onVelgRute: _ctx.velgRute
        }, null, 8, ["ruter", "onVelgRute"]))
      : _createCommentVNode("", true)
  ]))
}