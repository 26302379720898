import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]
const _hoisted_5 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("select", {
    class: "rutevelger",
    value: _ctx.modelValue,
    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
  }, [
    _createElementVNode("option", { value: _ctx.frontrute }, "Frontrute", 8, _hoisted_2),
    _createElementVNode("option", { value: _ctx.siderute }, "Siderute", 8, _hoisted_3),
    _createElementVNode("option", { value: _ctx.bakrute }, "Bakrute", 8, _hoisted_4),
    _createElementVNode("option", { value: _ctx.annet }, "Annet", 8, _hoisted_5)
  ], 40, _hoisted_1))
}