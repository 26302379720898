import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "printContainer",
  class: "print-container"
}
const _hoisted_2 = { class: "avsender" }
const _hoisted_3 = { class: "rute" }
const _hoisted_4 = { class: "mottaker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[6] || (_cache[6] = _createElementVNode("h1", null, "Fraktetikett", -1)),
    _createVNode(_component_Button, { onClick: _ctx.print }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Skriv ut")
      ])),
      _: 1
    }, 8, ["onClick"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", null, [
        _createElementVNode("tbody", null, [
          _createElementVNode("tr", _hoisted_2, [
            _cache[1] || (_cache[1] = _createElementVNode("label", null, "Avsender", -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.lager.navn), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.lager.adresselinje1), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.lager.postNr) + " " + _toDisplayString(_ctx.lager.postSted), 1)
          ]),
          _createElementVNode("tr", _hoisted_3, [
            _cache[2] || (_cache[2] = _createElementVNode("label", null, "Rute", -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.transportRute?.beskrivelse ?? "Ukjent"), 1),
            _cache[3] || (_cache[3] = _createElementVNode("label", null, "Avganger", -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.avganger), 1)
          ]),
          _createElementVNode("tr", _hoisted_4, [
            _cache[4] || (_cache[4] = _createElementVNode("label", null, "Mottaker", -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.kunde.navn), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.kunde.adresselinje1), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.kunde.postNr) + " " + _toDisplayString(_ctx.kunde.postSted), 1)
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("tr", { class: "kolli" }, [
            _createElementVNode("span", { class: "dato" }, [
              _createElementVNode("label", null, "Dato"),
              _createElementVNode("input", {
                type: "text",
                disabled: ""
              })
            ]),
            _createElementVNode("span", { class: "kolli-info" }, [
              _createElementVNode("label", null, "Kolli"),
              _createElementVNode("input", {
                type: "text",
                disabled: ""
              })
            ]),
            _createElementVNode("span", { class: "vekt" }, [
              _createElementVNode("label", null, "Vekt"),
              _createElementVNode("input", {
                type: "text",
                disabled: ""
              })
            ])
          ], -1))
        ])
      ])
    ], 512)
  ]))
}