<template>
  <div class="kundevelger">
    <div>
      <span v-if="!modelValue">Du har ikke valgt hvilken kunde du bestiller for.</span>
      <span v-else
        >Du gjør bestillinger på vegne av {{ modelValue }}{{ kundeInfo ? ` - ${kundeInfo.navn}` : "" }}.</span
      >

      <Button class="button" v-if="modelValue && !redigerer" @click="redigerer = true">Endre</Button>
    </div>

    <div v-if="redigerer">
      <KundeNummerTypeAhead v-model="valgtKunde" class="inputfelt" />
      <Button class="button" :disabled="!modelValue" @click="redigerer = false">Avbryt</Button>
    </div>
  </div>
</template>

<script lang="ts">
import { hentKunde } from "@/clients/kundeClient";
import { STORAGE_PREFIX } from "@/constants";
import { IKunde } from "@/types";
import { defineComponent, onMounted, ref, watch } from "vue";
import KundeNummerTypeAhead from "./KundeNummerTypeAhead.vue";

const LOCAL_STORAGE_KEY = `${STORAGE_PREFIX}.valgtKunde`;

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },

  setup(props, context) {
    const redigerer = ref<boolean>(!props.modelValue);
    const valgtKunde = ref("");
    const kundeInfo = ref<IKunde>();

    if (!props.modelValue) {
      const valueFromStorage = localStorage.getItem(LOCAL_STORAGE_KEY);

      if (valueFromStorage) {
        redigerer.value = false;
        valgtKunde.value = valueFromStorage;
        context.emit("update:modelValue", valueFromStorage);
      }
    }

    const onVelgKunde = () => {
      redigerer.value = false;
      localStorage.setItem(LOCAL_STORAGE_KEY, valgtKunde.value);

      context.emit("update:modelValue", valgtKunde.value);
    };

    watch([valgtKunde], () => onVelgKunde());

    const oppdaterKundeInfo = async () => {
      if (valgtKunde.value === "") {
        return;
      }
      kundeInfo.value = await hentKunde(valgtKunde.value);
    };

    watch([valgtKunde], oppdaterKundeInfo);
    onMounted(() => oppdaterKundeInfo());

    return { redigerer, valgtKunde, kundeInfo };
  },

  components: {
    KundeNummerTypeAhead,
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.kundevelger {
  background: @yellow-lighter;
  border-radius: 0.5rem;
  padding: 1.2rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.inputfelt {
  width: 20rem;
}

.button {
  margin-left: 1.2rem;
}
</style>
