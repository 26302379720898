import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "dialog-danger" }
const _hoisted_2 = { class: "dialog-content" }
const _hoisted_3 = {
  key: 0,
  class: "title"
}
const _hoisted_4 = { class: "text" }
const _hoisted_5 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "danger-icon" }, [
      _createElementVNode("i", { class: "las la-exclamation-triangle" })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      _createElementVNode("p", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Button, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('avbryt')))
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Avbryt")
          ])),
          _: 1
        }),
        _createVNode(_component_Button, {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('bekreft'))),
          type: "primary"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Bekreft")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}