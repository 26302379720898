import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@/styles/base.less";
import authService from "./utils/auth/authService";
import { registerBasisComponents } from "./components/_basis";
import { handleGlobalError, handleGlobalRejection, handleVueError } from "./utils";
import { hentEnvironmentFraBackend } from "./clients/environmentClient";
import "@andrlriis/komponentbibliotek/base.css";
import "@andrlriis/komponentbibliotek/style.css";

let environment = "";

export const getEnvironment = (): string => environment;

const init = async () => {
  window.addEventListener("error", handleGlobalError);
  window.addEventListener("unhandledrejection", handleGlobalRejection);

  await authService.init();
  const app = createApp(App);
  app.config.errorHandler = handleVueError;
  registerBasisComponents(app);
  app.use(router).mount("#app");

  environment = await hentEnvironmentFraBackend();

  if (environment !== "Production") {
    console.log("Environment: ", environment);
    document.title = `[${environment}] ${document.title}`;
  }
};

init();
