import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "button-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonRouterLink = _resolveComponent("ButtonRouterLink")!

  return (_openBlock(), _createElementBlock("table", null, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        _cache[0] || (_cache[0] = _createElementVNode("th", null, "Id", -1)),
        _cache[1] || (_cache[1] = _createElementVNode("th", null, "Ordrenummer", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("th", null, "Kunde", -1)),
        (_ctx.isAdmin || _ctx.isKundesenter)
          ? (_openBlock(), _createElementBlock("th", _hoisted_1, "Lager"))
          : _createCommentVNode("", true),
        _cache[3] || (_cache[3] = _createElementVNode("th", null, "Rute", -1)),
        _cache[4] || (_cache[4] = _createElementVNode("th", null, "Status", -1)),
        _cache[5] || (_cache[5] = _createElementVNode("th", null, null, -1))
      ])
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bestillinger, (bestilling) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: bestilling.id
        }, [
          _createElementVNode("td", null, _toDisplayString(bestilling.id), 1),
          _createElementVNode("td", null, _toDisplayString(bestilling.ordreNummer ?? ""), 1),
          _createElementVNode("td", null, [
            _createTextVNode(_toDisplayString(bestilling.kundeNr) + " ", 1),
            (bestilling.kunde.navn)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, " - " + _toDisplayString(bestilling.kunde.navn), 1))
              : _createCommentVNode("", true)
          ]),
          (_ctx.isAdmin || _ctx.isKundesenter)
            ? (_openBlock(), _createElementBlock("td", _hoisted_3, _toDisplayString(bestilling.lager), 1))
            : _createCommentVNode("", true),
          _createElementVNode("td", null, _toDisplayString(bestilling.rute.beskrivelse ?? ""), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.bestillingStatusTilTekst(bestilling.status)), 1),
          _createElementVNode("td", _hoisted_4, [
            _createVNode(_component_ButtonRouterLink, {
              to: `/bestillinger/${bestilling.id}/plukkliste`,
              title: "Skriv ut plukkliste"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("i", { class: "las la-clipboard-list" }, null, -1)
              ])),
              _: 2
            }, 1032, ["to"]),
            _createVNode(_component_ButtonRouterLink, {
              to: `/bestillinger/${bestilling.id}/fraktetikett`,
              title: "Skriv ut fraktetikett"
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createElementVNode("i", { class: "las la-truck" }, null, -1)
              ])),
              _: 2
            }, 1032, ["to"])
          ])
        ]))
      }), 128))
    ])
  ]))
}