<template>
  <div class="spinner-wrapper">
    <Spinner />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {},

  setup() {
    return {};
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.spinner-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
</style>
