<template>
  <div class="undermeny">
    <button class="open-knapp" @click.stop="handleOpenClick">
      {{ tittel }}
      <i v-if="!aktiv" class="las la-angle-right" />
      <i v-if="aktiv" class="las la-angle-down" />
    </button>
    <div class="undermeny-lenker" v-if="aktiv">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted } from "vue";

export default defineComponent({
  props: {
    tittel: {
      type: String,
      required: true,
    },
    aktiv: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const handleOpenClick = () => {
      if (props.aktiv) {
        emit("close");
      } else {
        emit("open");
      }
    };

    const onWindowClick = () => {
      if (props.aktiv) {
        emit("close");
      }
    };

    onMounted(() => {
      window.addEventListener("click", onWindowClick);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("click", onWindowClick);
    });

    return { handleOpenClick };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.open-knapp {
  background: none;
  color: white;
  border: none;
  font-size: 1rem;
  padding: 1rem 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.undermeny-lenker {
  z-index: 99;

  display: flex;
  flex-direction: column;
  position: absolute;
  background: @blue-dark;

  box-shadow: 0 3px 5px 0 @black40;
}
</style>
