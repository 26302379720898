<template>
  <div class="spinner-wrapper" v-if="!lastet">
    <Spinner />
  </div>
  <div v-else>
    <BestillingDetaljer :bestilling="bestilling" />
  </div>
</template>

<script lang="ts">
import { hentBestilling } from "@/clients/bestillingClient";
import { INyBestilling } from "@/types";
import { defineComponent, ref } from "vue";
import { BestillingDetaljer } from "@/components";

export default defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const bestilling = ref<INyBestilling>();
    const lastet = ref(false);

    hentBestilling(props.id).then((result) => {
      bestilling.value = result;
      lastet.value = true;
    });

    return { lastet, bestilling };
  },

  components: { BestillingDetaljer },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.spinner-wrapper {
  display: flex;
  justify-content: center;
}
</style>
