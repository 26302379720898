import { IBruker, IBrukerOpprettelseToken, IRolle, ITokenUtsendelse } from "@/types";
import { del, get, post, put } from "@/utils";

export const hentBrukere = async (): Promise<IBruker[]> => {
  return await get("/api/brukere");
};

export const hentBruker = async (id: string): Promise<IBruker> => {
  return await get(`/api/brukere/${id}`);
};

export const hentRoller = async (): Promise<IRolle[]> => {
  return await get(`/api/brukere/roller`);
};

export const hentRollerForBruker = async (brukerId: string): Promise<IRolle[]> => {
  return await get(`/api/brukere/${brukerId}/roller`);
};

export const leggTilRolleForBruker = async (brukerId: string, rolleNavn: string): Promise<void> => {
  await post(`/api/brukere/${brukerId}/roller`, rolleNavn);
};

export const fjernRolleForBruker = async (brukerId: string, rolleNavn: string): Promise<void> => {
  await del(`/api/brukere/${brukerId}/roller/${rolleNavn}`);
};

export const opprettBruker = async (brukernavn: string, passord: string, lager = "", kundeNr = ""): Promise<void> => {
  await post(`/api/brukere`, { brukernavn, passord, lager, kundeNr });
};

export const redigerBruker = async (brukerId: string, lager: string, kundeNr: string): Promise<void> => {
  await put(`/api/brukere/${brukerId}`, { lager, kundeNr });
};

export const tilbakestillPassord = async (userId: string, passord: string): Promise<void> => {
  await put(`/api/brukere/${userId}/passord`, passord);
};

export const endrePassord = async (gammeltPassord: string, nyttPassord: string): Promise<void> => {
  await post(`/api/brukere/endre-passord`, {
    gammeltPassord,
    nyttPassord,
  });
};

export const opprettToken = async (kunde?: string): Promise<string> => {
  const result = await post(`/api/brukere/opprett-token${kunde ? `?kunde=${kunde}` : ""}`);

  return result;
};

export const opprettTokensForAlleBgpKunder = async (
  sendEpost: boolean,
  sendSms: boolean
): Promise<IBrukerOpprettelseToken[]> => {
  const result = await post(`/api/brukere/opprett-tokens`, { sendEpost, sendSms });

  return result;
};

export const hentForrigeUtsendelse = async (): Promise<ITokenUtsendelse> => {
  const result = await get(`/api/brukere/forrige-utsendelse`);

  return { ...result, tidspunkt: new Date(result.tidspunkt) };
};

export const registrerBrukerMedToken = async (token: string, brukernavn: string, passord: string): Promise<void> => {
  await post(`/api/brukere/registrer?token=${token}`, { brukernavn, passord });
};

export const validerToken = async (token: string): Promise<boolean> => {
  const result = get(`/api/brukere/valider-token/${token}`);

  return result;
};

export const slettBruker = async (brukerId: string): Promise<void> => {
  await del(`/api/brukere/${brukerId}`);
};
