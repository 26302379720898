import {
  INyBestilling,
  INyBestillingLinje,
  IBestilling,
  IHandlekurvLinje,
  ITransportRute,
  IBestillingerMedSideInfo,
  IBestillingFilter,
  LagerStatus,
  BestillingStatus,
  BestillingSortering,
} from "@/types";
import { CrudError, get, post } from "@/utils";
import authService from "@/utils/auth/authService";

export const opprettBestilling = async (bestilling: INyBestilling): Promise<void> => {
  await post(`/api/bestillinger`, bestilling);
};

export const opprettBestillingForHandlekurv = async (
  linjer: IHandlekurvLinje[],
  kundeNr?: string | undefined
): Promise<void> => {
  if (!kundeNr) {
    const user = await authService.getUser();
    kundeNr = user?.kundeNr;
  }

  if (!kundeNr) {
    throw Error("Mangler kundenummer for bestilling.");
  }

  const bestillingLinjer: INyBestillingLinje[] = linjer.flatMap((handlekurvLinje) => {
    const linjer: INyBestillingLinje[] = [];

    linjer.push({
      antall: handlekurvLinje.antall,
      artikkelNr: handlekurvLinje.artikkel.artikkelNummer,
      merknad: handlekurvLinje.merknad,
    });

    linjer.push(
      ...handlekurvLinje.følgeartikler.map((linje) => ({
        antall: linje.antall,
        artikkelNr: linje.artikkel.artikkelNummer,
        merknad: "",
      }))
    );

    return linjer;
  });

  const bestilling: INyBestilling = {
    kundeNr,
    lager: "",
    linjer: bestillingLinjer,
  };

  await opprettBestilling(bestilling);
};

export const hentBestilling = async (id: number): Promise<IBestilling> => {
  const bestilling = await get(`/api/bestillinger/${id}`);
  return { ...bestilling, ønsketLeveringsDato: new Date(bestilling.ønsketLeveringsDato) };
};

export const hentBestillinger = async (
  filter: IBestillingFilter = {},
  pageSize = 10,
  page = 0
): Promise<IBestillingerMedSideInfo> => {
  const params = new URLSearchParams();
  if (filter.lager) params.append("lager", filter.lager);
  if (filter.ordreNummer) params.append("ordreNummer", filter.ordreNummer.toString());
  if (filter.bestillingId) params.append("id", filter.bestillingId.toString());
  if (filter.status) filter.status.forEach((status) => params.append("status", status.toString()));
  if (filter.kunde) params.append("kunde", filter.kunde);
  if (filter.sorterEtter) params.append("sorterEtter", filter.sorterEtter.toString());
  if (filter.lagerStatus) {
    filter.lagerStatus.forEach((status) => {
      params.append("lagerStatus", status.toString());
    });
  }
  if (filter.restOrdre !== undefined) params.append("restOrdre", filter.restOrdre?.toString());
  params.append("sorterSynkende", filter.sorterSynkende?.toString() ?? "false");
  params.append("page", page.toString());
  params.append("pageSize", pageSize.toString());

  const bestillinger = await get(`/api/bestillinger?${params.toString()}`);
  bestillinger.bestillinger = bestillinger.bestillinger.map((x: any) => ({
    ...x,
    ønsketLeveringsDato: new Date(x.ønsketLeveringsDato),
  }));
  return bestillinger;
};

export const hentBestillingerEtterAvgang = async (
  lager = "",
  lagerStatus: LagerStatus[] = [],
  restOrdre = false,
  kunde: string | null = null
): Promise<IBestilling[]> => {
  const result = await hentBestillinger(
    {
      lager,
      lagerStatus: lagerStatus,
      status: [BestillingStatus.åpen, BestillingStatus.underPlukking],
      sorterEtter: restOrdre ? BestillingSortering.ønsketLeveringstid : BestillingSortering.nesteAvgang,
      restOrdre: restOrdre,
      kunde: kunde ?? undefined,
    },
    0
  );

  return result.bestillinger;
};

export const startPlukking = async (id: number): Promise<void> => {
  return await post(`/api/bestillinger/${id}/start-plukking`);
};

export const avbrytPlukking = async (id: number): Promise<void> => {
  return await post(`/api/bestillinger/${id}/avbryt-plukking`);
};

export const bekreftPlukking = async (id: number, antall: { [linjeId: number]: number } = {}): Promise<void> => {
  return await post(`/api/bestillinger/${id}/bekreft-plukking`, antall);
};

export const hentRuteForBestilling = async (id: number): Promise<ITransportRute | undefined> => {
  try {
    return await get(`/api/bestillinger/${id}/rute`);
  } catch (e: unknown) {
    if (e instanceof CrudError && e.status === 404) {
      return undefined;
    }
  }
};
