import { IArtikkel, IFølgeartikkel, IHovedLokasjon, ILagerSaldo } from "@/types";
import { get } from "@/utils";

export const hentFølgeArtikler = async (artikkel: IArtikkel): Promise<IFølgeartikkel[]> => {
  const result = await get(`/api/artikler/${artikkel.artikkelNummer}/følgeartikler`);

  return result;
};

export const søkEtterArtikler = async (artikkelNr: string): Promise<IArtikkel[]> => {
  const result = await get(`/api/artikler/søk/${artikkelNr}`);

  return result;
};

export const hentLagerSaldo = async (artikkelNr: string, lager: string): Promise<ILagerSaldo[]> => {
  const result = await get(`/api/artikler/${artikkelNr}/saldo?lager=${lager}`);

  return result;
};

export const hentArtikkel = async (artikkelNr: string): Promise<IArtikkel> => {
  const result = await get(`/api/artikler/${artikkelNr}`);
  return result;
};

export const hentHovedlokasjon = async (artikkelNr: string, lager?: string): Promise<IHovedLokasjon> => {
  const result = await get(`/api/artikler/${artikkelNr}/hovedlokasjon${lager ? `?lager=${lager}` : ""}`);
  return result;
};

export const hentLimArtikler = async (): Promise<IArtikkel[]> => {
  const result = await get(`/api/artikler/lim`);
  return result;
};
