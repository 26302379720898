<template>
  <div>
    <h1>Oppdrag</h1>
    <p v-if="visEksportKnapp">
      <OppdragEksport class="eksport-knapp"/>Her finner du oppdrag som ble eksportert fra Bilglassportalen før behovet for reserveløsningen oppstod.
      </p>
    <OppdragListe />
  </div>
</template>

<script setup lang="ts">
import { OppdragEksport, OppdragListe } from "@/components";
import { onMounted, ref } from "vue";
import authService from "@/utils/auth/authService";

const visEksportKnapp = ref(false);

onMounted(async () => {
  const user = await authService.getUser();
  visEksportKnapp.value = user?.kundeNr != undefined;
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
.eksport-knapp {
  display: inline-block;
  margin-right: var(--riis-luft-4);
}
p {
  margin-bottom: 40px;
}
</style>
