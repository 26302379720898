<template>
  <form class="endre-passord" @submit.prevent="utførEndrePassord()">
    <ul v-if="errors.length" class="errors">
      <li v-for="error in errors" :key="error">
        {{ error }}
      </li>
    </ul>
    <div class="input-gruppe">
      <label for="passord">Gammelt passord</label>
      <input
        type="password"
        id="gammeltPassord"
        v-model="gammeltPassord"
        placeholder="Gammelt passord"
        @input="lagret = false"
      />
    </div>
    <div class="input-gruppe">
      <label for="passord">Nytt passord</label>
      <input type="password" id="passord" v-model="passord" placeholder="Nytt passord" @input="lagret = false" />
    </div>
    <div class="input-gruppe">
      <label for="gjentaPassord">Gjenta passord</label>
      <input type="password" id="gjentaPassord" v-model="gjentaPassord" placeholder="Gjenta passord" />
    </div>
    <Button type="primary" @click="utførEndrePassord()">Lagre</Button>
  </form>
</template>

<script lang="ts">
import { endrePassord } from "@/clients/brukerClient";
import { toast, validerPassord } from "@/utils";
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {},

  setup() {
    const gammeltPassord = ref("");
    const passord = ref("");
    const gjentaPassord = ref("");
    const errors = ref<string[]>([]);
    const lagret = ref(false);

    const utførEndrePassord = async () => {
      if (passord.value !== gjentaPassord.value) {
        errors.value = ["Passordene er ikke like"];
        return;
      }

      errors.value = [];

      const [passordGyldig, valideringsfeil] = validerPassord(passord.value);
      if (!passordGyldig) {
        errors.value = valideringsfeil;
        return;
      }

      await endrePassord(gammeltPassord.value, passord.value);
      gammeltPassord.value = "";
      passord.value = "";
      gjentaPassord.value = "";
      lagret.value = true;
      toast("Passordet ble lagret");
    };

    return { gammeltPassord, passord, gjentaPassord, utførEndrePassord, errors, lagret };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.errors {
  color: @red;
  list-style: none;
  padding: 0;
  margin: 0;
}

.endre-passord {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.input-gruppe {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
