import { IArtikkel, IHovedgruppe, IUndergruppe } from "@/types";
import { get } from "@/utils";

export const hentHovedgrupper = (): Promise<IHovedgruppe[]> => {
  const result = get("/api/verktøy/hovedgrupper");
  return result;
};

export const hentUndergrupper = (hovedgruppeId: number): Promise<IUndergruppe[]> => {
  const result = get(`/api/verktøy/hovedgrupper/${hovedgruppeId}/undergrupper`);
  return result;
};

export const hentVerktøy = (hovedgruppeId: number, undergruppeId: number): Promise<IArtikkel[]> => {
  const result = get(`/api/verktøy/hovedgrupper/${hovedgruppeId}/undergrupper/${undergruppeId}/verktøy`);
  return result;
};
