<template>
  <table>
    <thead>
      <tr>
        <th></th>
        <th>Id</th>
        <th v-if="restordre">Ordrenummer</th>
        <th>Kunde</th>
        <th>Status</th>
        <th>Rute</th>
        <th>Avgangstider</th>
        <th v-if="restordre">Ønsket leveringsdato</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="bestilling in bestillinger" :key="bestilling.id">
        <td class="right lagerstatus-celle">
          <button @click="$emit('vis-detaljer', bestilling)" class="lagerstatus-knapp">
            <LagerStatusIkon :lagerStatus="bestilling.lagerStatus" />
          </button>
        </td>
        <td>
          {{ bestilling.id }}
        </td>
        <td v-if="restordre">
          {{ bestilling.ordreNummer }}
        </td>
        <td>
          <router-link :to="{ path: '/apne-bestillinger', query: { kunde: bestilling.kundeNr } }">
            {{ bestilling.kundeNr }}
            <span v-if="bestilling.kunde.navn"> - {{ bestilling.kunde.navn }}</span>
          </router-link>
        </td>
        <td :class="{ 'status-under-plukking': bestilling.status === BestillingStatus.underPlukking }">
          {{ bestillingStatusTilTekst(bestilling.status) }}
        </td>
        <td>{{ bestilling.rute.beskrivelse }}</td>
        <td v-if="bestilling.rute.avganger.length">{{ lagAvgangsstreng(bestilling) }}</td>
        <td v-else>Fant ingen avganger</td>
        <td v-if="restordre">{{ formatterDato(bestilling.ønsketLeveringsDato) }}</td>
        <td>
          <div class="buttons">
            <ButtonRouterLink :to="`/bestillinger/${bestilling.id}/plukkliste`" title="Skriv ut plukkliste">
              <i class="las la-clipboard-list" />
            </ButtonRouterLink>
            <ButtonRouterLink :to="`/bestillinger/${bestilling.id}/fraktetikett`" title="Skriv ut fraktetikett">
              <i class="las la-truck" />
            </ButtonRouterLink>

            <Button
              v-if="bestilling.status === BestillingStatus.åpen"
              class="btn-start-plukking"
              @click="$emit('start-plukking', bestilling)"
              :disabled="bestilling.linjer.length === 0"
              :title="bestilling.linjer.length === 0 ? 'Ingen linjer å plukke' : 'Start plukking'"
            >
              <i class="las la-dolly" />
            </Button>
            <Button
              v-if="bestilling.status === BestillingStatus.underPlukking"
              class="btn-avbryt-plukking"
              @click="$emit('avbryt-plukking', bestilling)"
              :disabled="bestilling.linjer.length === 0"
              title="Avbryt plukking"
            >
              <i class="las la-times-circle" />
            </Button>
            <Button
              v-if="bestilling.status === BestillingStatus.underPlukking"
              class="btn-bekreft-plukking"
              @click="$emit('vis-detaljer', bestilling)"
              :disabled="bestilling.linjer.length === 0"
              :title="bestilling.linjer.length === 0 ? 'Ingen linjer å plukke' : 'Bekreft plukking'"
            >
              <i class="las la-check-circle" />
            </Button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { formaterKlokkeslett, formatterDato } from "@/utils";
import { IBestilling, IBestillingMedAvgang, BestillingStatus, BestillingStatusTekst } from "@/types";
import LagerStatusIkon from "./LagerStatusIkon.vue";

export default defineComponent({
  props: {
    bestillinger: {
      type: Array as PropType<IBestillingMedAvgang[]>,
      required: true,
    },
    restordre: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const erÅpen = (bestilling: IBestilling): boolean => {
      return bestilling.status === BestillingStatus.åpen || bestilling.status === BestillingStatus.underPlukking;
    };

    const lagAvgangsstreng = (bestilling: IBestilling): string =>
      bestilling.rute.avganger.map((avgang) => formaterKlokkeslett(avgang.avgangTidspunkt)).join(", ") ?? "";

    const bestillingStatusTilTekst = (status: BestillingStatus): string | undefined => {
      return BestillingStatusTekst.get(status);
    };

    return { lagAvgangsstreng, erÅpen, bestillingStatusTilTekst, formatterDato, BestillingStatus };
  },

  components: { LagerStatusIkon },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.buttons {
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;

  button,
  a {
    font-size: 1.1rem;
  }

  .btn-bekreft-plukking {
    background: @green;
  }

  .btn-avbryt-plukking {
    background: @orange;
  }
}

td {
  vertical-align: middle;
}

.lagerstatus-celle {
  padding-right: 0;
  padding-left: 0;

  .lagerstatus-knapp {
    background: none;
    border: none;
  }
}

.status-under-plukking {
  color: @red-light;
  font-weight: bold;
}
</style>
