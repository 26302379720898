import authRoutes from "@/views/auth/authRoutes";
import authService from "@/utils/auth/authService";
import {
  KundeInfoView,
  BrukerListeView,
  BrukerDetaljerView,
  DevTools,
  BestillingView,
  NyBrukerView,
  BestillingDetaljerView,
  PlukklisteView,
  ÅpneBestillingerView,
  BestillingArkivView,
  FraktEtikettView,
  MinSideView,
  RegistrerBrukerView,
  OpprettTokensView,
  DriftSettingView,
  EksportView,
  ArtikkelInfoView,
  ArtikkelSøkView,
  MineBestillingerView,
  KundeSøkView,
  Home,
  RedigerBrukerView,
  RutesøkView,
  OppdragView, OppdragDetaljerView,
} from "@/views";
import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import { BrukerRolle } from "./types";

const ROLE_ADMIN: BrukerRolle = "Admin";
const ROLE_DEVELOPER: BrukerRolle = "Developer";
const ROLE_KUNDE: BrukerRolle = "Kunde";
const ROLE_KUNDESENTER: BrukerRolle = "Kundesenter";
const ROLE_LAGER: BrukerRolle = "Lager";


export const ROUTE_NAMES = {
  home: "Home",
  developer: "Developer",
  kundesok: "Kundesøk",
  kunder: "Kunder",
  varebestilling: "Varebestilling",
  mineBestillinger: "Mine-Bestillinger",
  rutesok: "Rutesok",
  oppdrag: "Oppdrag",
  oppdragDetaljer: "OppdragDetaljer",
  apneBestillinger: "ÅpneBestillinger",
  bestillingArkiv: "BestillingArkiv",
  bestillingDetaljer: "BestillingDetaljer",
  plukkliste: "Plukkliste",
  fraktetikett: "Fraktetikett",
  artikkelSok: "Artikkel-Søk",
  artikkelInfo: "Artikkel-Info",
  brukere: "Brukere",
  brukerDetaljer: "Brukerdetaljer",
  redigerBruker: "Rediger-Bruker",
  nyBruker: "Ny-bruker",
  opprettTokens: "Opprett-tokens",
  driftsetting: "Driftsetting",
  eksport: "Eksport",
  registrerMedToken: "Registrer-med-token",
  minSide: "Min-side",

};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: ROUTE_NAMES.home,
    component: Home,
    beforeEnter(_, __, next) {
      const executeGuard = async () => {
        if (await authService.hasRole(ROLE_KUNDE)) {
          next({ path: "/bestill" });
        } else if (
          (await authService.hasRole(ROLE_LAGER)) ||
          (await authService.hasRole(ROLE_KUNDESENTER)) ||
          (await authService.hasRole(ROLE_ADMIN))
        ) {
          next({ path: "/apne-bestillinger" });
        } else {
          next();
        }
      };

      executeGuard();
    },
  },
  {
    path: "/developer",
    name: ROUTE_NAMES.developer,
    component: DevTools,
    meta: {
      roles: [ROLE_DEVELOPER],
    },
  },

  {
    path: "/kunder",
    name: ROUTE_NAMES.kundesok,
    component: KundeSøkView,
    meta: {
      roles: [ROLE_ADMIN, ROLE_KUNDESENTER],
    },
  },
  {
    path: "/kunder/:kundeNummer",
    name: ROUTE_NAMES.kunder,
    component: KundeInfoView,
    props: (route) => ({ kundeNummer: route.params.kundeNummer }),
    meta: {
      roles: [ROLE_ADMIN, ROLE_KUNDESENTER],
    },
  },

  {
    path: "/bestill",
    name: ROUTE_NAMES.varebestilling,
    component: BestillingView,
    meta: {
      roles: [ROLE_ADMIN, ROLE_KUNDE, ROLE_KUNDESENTER],
    },
    props: (route) => {
      return {
        leggTilArtikkelNr: route.query.leggTilArtikkelNr as string | undefined,
        leggTilArtikkelMerknad: route.query.leggTilArtikkelMerknad as string | undefined,
      };
    },
  },
  {
    path: "/mine-bestillinger",
    name: ROUTE_NAMES.mineBestillinger,
    component: MineBestillingerView,
    meta: {
      roles: [ROLE_KUNDE],
    },
  },
  {
    path: "/rutesok",
    name: ROUTE_NAMES.rutesok,
    component: RutesøkView,
    meta: {
      roles: [ROLE_KUNDE, ROLE_KUNDESENTER, ROLE_ADMIN],
    },
  },
  {
    path: "/oppdrag",
    name: ROUTE_NAMES.oppdrag,
    component: OppdragView,
    meta: {
      roles: [ROLE_KUNDE, ROLE_ADMIN, ROLE_KUNDESENTER],
    },
  },
  {
    path: "/oppdrag/:oppdragId",
    name: ROUTE_NAMES.oppdragDetaljer,
    component: OppdragDetaljerView,
    meta: {
      roles: [ROLE_KUNDE, ROLE_ADMIN, ROLE_KUNDESENTER],
    },
    props: (route) => ({oppdragId: parseInt(route.params.oppdragId as string)}),
  },

  {
    path: "/apne-bestillinger",
    name: ROUTE_NAMES.apneBestillinger,
    component: ÅpneBestillingerView,
    meta: {
      roles: [ROLE_ADMIN, ROLE_LAGER],
    },
  },
  {
    path: "/bestillinger",
    name: ROUTE_NAMES.bestillingArkiv,
    component: BestillingArkivView,
    meta: {
      roles: [ROLE_ADMIN, ROLE_LAGER],
    },
  },
  {
    path: "/bestillinger/:id",
    name: ROUTE_NAMES.bestillingDetaljer,
    component: BestillingDetaljerView,
    meta: {
      roles: [ROLE_ADMIN, ROLE_LAGER, ROLE_KUNDESENTER],
    },
    props: (route) => ({ id: parseInt(route.params.id as string) }),
  },
  {
    path: "/bestillinger/:id/plukkliste",
    name: ROUTE_NAMES.plukkliste,
    component: PlukklisteView,
    meta: {
      roles: [ROLE_ADMIN, ROLE_LAGER],
    },
    props: (route) => ({ id: parseInt(route.params.id as string) }),
  },
  {
    path: "/bestillinger/:id/fraktetikett",
    name: ROUTE_NAMES.fraktetikett,
    component: FraktEtikettView,
    meta: {
      roles: [ROLE_ADMIN, ROLE_LAGER, ROLE_KUNDESENTER],
    },
    props: (route) => ({ id: parseInt(route.params.id as string) }),
  },
  {
    path: "/artikler",
    name: ROUTE_NAMES.artikkelSok,
    component: ArtikkelSøkView,
    meta: {
      roles: [ROLE_LAGER, ROLE_ADMIN, ROLE_KUNDESENTER],
    },
  },
  {
    path: "/artikler/:id",
    name: ROUTE_NAMES.artikkelInfo,
    component: ArtikkelInfoView,
    meta: {
      roles: [ROLE_ADMIN, ROLE_LAGER, ROLE_KUNDESENTER],
    },
    props: (route) => ({ artikkelNr: route.params.id }),
  },

  {
    path: "/brukere",
    name: ROUTE_NAMES.brukere,
    component: BrukerListeView,
    meta: {
      roles: [ROLE_ADMIN],
    },
  },
  {
    path: "/brukere/:id",
    name: ROUTE_NAMES.brukerDetaljer,
    component: BrukerDetaljerView,
    props: (route) => ({ brukerId: route.params.id }),
    meta: {
      roles: [ROLE_ADMIN],
    },
  },
  {
    path: "/brukere/:id/rediger",
    name: ROUTE_NAMES.redigerBruker,
    component: RedigerBrukerView,
    props: (route) => ({ brukerId: route.params.id }),
    meta: {
      roles: [ROLE_ADMIN],
    },
  },
  {
    path: "/brukere/ny",
    name: ROUTE_NAMES.nyBruker,
    component: NyBrukerView,
    meta: {
      roles: [ROLE_ADMIN],
    },
  },
  {
    path: "/brukere/opprett-tokens",
    name: ROUTE_NAMES.opprettTokens,
    component: OpprettTokensView,
    meta: {
      roles: [ROLE_ADMIN],
    },
  },
  {
    path: "/driftsetting",
    name: ROUTE_NAMES.driftsetting,
    component: DriftSettingView,
    meta: {
      roles: [ROLE_ADMIN],
    },
  },
  {
    path: "/eksport",
    name: ROUTE_NAMES.eksport,
    component: EksportView,
    meta: {
      roles: [ROLE_ADMIN],
    },
  },

  {
    path: "/registrer/:token",
    name: ROUTE_NAMES.registrerMedToken,
    component: RegistrerBrukerView,
    meta: {
      guest: true,
    },
    props: (route) => ({ token: route.params.token }),
  },

  {
    path: "/min-side",
    name: ROUTE_NAMES.minSide,
    component: MinSideView,
  },

  ...authRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const isAuthorized = (to: RouteLocationNormalized): boolean => {
  const record = to.matched[to.matched.length - 1];

  if (record.meta.roles) {
    return (record.meta.roles as string[]).some((role) => authService.hasRole(role as BrukerRolle));
  }

  return true;
};

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    return next();
  } else {
    const isAuthenticated = await authService.isAuthenticated();
    if (isAuthenticated && isAuthorized(to)) {
      return next();
    }
    if (isAuthenticated) {
      return next({
        path: "/auth/not-allowed",
      });
    } else {
      return next({
        path: "/auth/login",
        query: { redirect: to.fullPath },
      });
    }
  }
});

export default router;
