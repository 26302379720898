import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Import = _resolveComponent("Import")!
  const _component_MailTester = _resolveComponent("MailTester")!
  const _component_SmsTester = _resolveComponent("SmsTester")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[1] || (_cache[1] = _createElementVNode("h2", null, "Automatisk import", -1)),
    _createTextVNode(" Automatisk import: " + _toDisplayString(_ctx.automatiskImport ? "Aktivert" : "Deaktivert") + " ", 1),
    _createVNode(_component_Button, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.settAutomatiskImport(!_ctx.automatiskImport)))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.automatiskImport ? "Deaktiver" : "Aktiver"), 1)
      ]),
      _: 1
    }),
    _cache[2] || (_cache[2] = _createElementVNode("h2", null, "Manuell import", -1)),
    _createVNode(_component_Import),
    _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Mailtester", -1)),
    _createVNode(_component_MailTester),
    _cache[4] || (_cache[4] = _createElementVNode("h2", null, "SMS tester", -1)),
    _createVNode(_component_SmsTester)
  ]))
}