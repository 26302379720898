import { IBestilling, IKunde } from "@/types";
import { get } from "@/utils";
import authService from "@/utils/auth/authService";

export const hentKunde = async (kundeNummer: string): Promise<IKunde> => {
  return await get(`/api/kunder/${kundeNummer}`);
};

export const hentKunder = async (): Promise<IKunde[]> => {
  return await get(`/api/kunder`);
};

export const søkEtterKunder = async (søkestreng: string): Promise<IKunde[]> => {
  return await get(`/api/kunder/søk/${søkestreng}`);
};

export const hentMineBestillinger = async (): Promise<IBestilling[]> => {
  const user = await authService.getUser();

  if (!user) return [];

  let result = await get(`/api/kunder/${user?.kundeNr}/bestillinger`);

  result = result.map((r: IBestilling): IBestilling => ({ ...r, opprettet: new Date(r.opprettet) }));

  return result;
};
