import { IOppdrag } from "@/types";
import { get } from "@/utils";

export const hentOppdragListe = async (): Promise<IOppdrag[]> => {
  const oppdragListe = await get(`/api/oppdrag`);
  return oppdragListe.map((oppdrag: any) => ({...oppdrag, verkstedDato: new Date(oppdrag.verkstedDato)}));
};

export const hentOppdrag = async (oppdragId: number): Promise<IOppdrag> => {
  const oppdrag = await get(`/api/oppdrag/${oppdragId}`);
  return { ...oppdrag, verkstedDato: new Date(oppdrag.verkstedDato) };
};

export const hentVerkstedNrListe = async (): Promise<string[]> => {
  return await get(`/api/oppdrag/verksteder`);
}