<template>
  <button :class="className" :disabled="disabled">
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export type ButtonType = "default" | "primary" | "danger";

const getClass = (type: ButtonType) => {
  if (type == "primary") return "btn-primary";
  if (type == "danger") return "btn-danger";
  return "";
};

export default defineComponent({
  props: {
    type: {
      type: String as PropType<ButtonType>,
      default: () => "default",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props) {
    const className = getClass(props.type);

    return { className };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

button {
  font-size: 0.8rem;
  background: @black80;
  border: none;
  border-radius: 0.2rem;
  padding: 0.4rem 0.9rem;
  color: @black10;
  cursor: pointer;
  white-space: nowrap;

  &:disabled {
    filter: brightness(1.1);
    cursor: not-allowed;
  }
  &:not(:disabled):hover {
    filter: brightness(0.9);
  }
}

.btn-primary {
  background: @blue-dark;
  color: @white;

  &:disabled {
    filter: saturate(0.1) brightness(1.5);
  }
}

.btn-danger {
  background: @red;
  color: white;
  &:disabled {
    filter: saturate(0.1) brightness(1.5);
  }
}
</style>
