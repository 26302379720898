<template>
  <ApplikasjonLayout>
    <div class="app">
      <Dialoger/>
      <HovedMeny v-if="isAuthenticated"/>
      <div class="content">
        <router-view/>
      </div>
      <Toasts class="toasts"/>
    </div>
  </ApplikasjonLayout>
</template>

<script>
import { defineComponent, ref } from "vue";
import authService from "./utils/auth/authService";
import HovedMeny from "@/components/meny/HovedMeny.vue";
import Toasts from "@/components/_basis/Toasts.vue";
import Dialoger from "@/components/dialog/Dialoger.vue";
import { ApplikasjonLayout } from "@andrlriis/komponentbibliotek";

export default defineComponent({
  setup() {
    const isAuthenticated = ref(false);

    authService.isAuthenticated().then((result) => (isAuthenticated.value = result));

    return {isAuthenticated};
  },
  components: {
    HovedMeny,
    Toasts,
    Dialoger,
  },
});
</script>

<style lang="less">
@import "~@/styles/variables.less";

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.toasts {
  position: fixed;
  bottom: 0;
  right: 0;
}
</style>
