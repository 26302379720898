import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "import-verktøy"
}
const _hoisted_2 = { class: "input-gruppe" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "input-gruppe" }
const _hoisted_5 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CenteredSpinner = _resolveComponent("CenteredSpinner")!
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.laster)
    ? (_openBlock(), _createBlock(_component_CenteredSpinner, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[3] || (_cache[3] = _createElementVNode("label", { for: "hovedfil" }, " Hovedfil ", -1)),
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valgtHovedfil) = $event)),
            id: "hovedfil"
          }, [
            _cache[2] || (_cache[2] = _createElementVNode("option", { value: "" }, "-- Ikke valgt --", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hovedfiler, (fil) => {
              return (_openBlock(), _createElementBlock("option", {
                key: fil,
                value: fil
              }, _toDisplayString(fil), 9, _hoisted_3))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.valgtHovedfil]
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[5] || (_cache[5] = _createElementVNode("label", { for: "suppleringsfil" }, " Suppleringsfil ", -1)),
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.valgtSuppleringsfil) = $event)),
            id: "suppleringsfil"
          }, [
            _cache[4] || (_cache[4] = _createElementVNode("option", { value: "" }, "-- Ikke valgt --", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.suppleringsfiler, (fil) => {
              return (_openBlock(), _createElementBlock("option", {
                key: fil,
                value: fil
              }, _toDisplayString(fil), 9, _hoisted_5))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.valgtSuppleringsfil]
          ])
        ]),
        _createVNode(_component_Button, {
          type: "primary",
          onClick: _ctx.importer
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("Importer")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]))
}