import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CenteredSpinner = _resolveComponent("CenteredSpinner")!
  const _component_MineBestillinger = _resolveComponent("MineBestillinger")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", null, "Mine bestillinger", -1)),
    (_ctx.laster)
      ? (_openBlock(), _createBlock(_component_CenteredSpinner, { key: 0 }))
      : (_openBlock(), _createBlock(_component_MineBestillinger, {
          key: 1,
          bestillinger: _ctx.bestillinger
        }, null, 8, ["bestillinger"]))
  ]))
}