<template>
  <div>
    <h1>Kunde</h1>
    <CenteredSpinner v-if="!lastet" />
    <span v-if="feilVedLasting"> Klarte ikke laste kunde med kundenummer {{ kundeNummer }}. </span>
    <KundeInfo v-if="lastet && !feilVedLasting" :kunde="kunde" />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { KundeInfo } from "@/components";
import { DEFAULT_KUNDE, IKunde } from "@/types";
import { hentKunde } from "@/clients/kundeClient";

export default defineComponent({
  props: {
    kundeNummer: String,
  },

  components: { KundeInfo },

  setup(props) {
    const kunde = ref<IKunde>({ ...DEFAULT_KUNDE });
    const lastet = ref(false);
    const feilVedLasting = ref(false);
    lastet.value = true;

    onMounted(async () => {
      lastet.value = false;
      feilVedLasting.value = false;

      if (!props.kundeNummer) return;

      try {
        kunde.value = await hentKunde(props.kundeNummer);
        lastet.value = true;
      } catch {
        feilVedLasting.value = true;
      }
    });

    return { kunde, lastet, feilVedLasting };
  },
});
</script>

<style lang="less" scoped></style>
