import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "underlinje" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "artikkelnummer" }
const _hoisted_4 = { class: "artikkelnavn" }
const _hoisted_5 = { class: "egenskaper" }
const _hoisted_6 = {
  key: 1,
  class: "ingen-resultat"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonLink = _resolveComponent("ButtonLink")!

  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("td", null, null, -1)),
    _cache[2] || (_cache[2] = _createElementVNode("td", { class: "eurokode-title" }, null, -1)),
    (!!_ctx.resultat.artikkel)
      ? (_openBlock(), _createElementBlock("td", _hoisted_2, [
          _createElementVNode("p", null, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.resultat.artikkel.artikkelNummer) + ": ", 1),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.resultat.artikkel.navn), 1)
          ]),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.resultat.artikkel.egenskaper.map((egenskap) => egenskap.beskrivelse).join(", ")), 1)
        ]))
      : (_openBlock(), _createElementBlock("td", _hoisted_6, "Fant ingen artikkel som matchet eurokode " + _toDisplayString(_ctx.resultat.eurokode), 1)),
    _createElementVNode("td", null, [
      (!!_ctx.resultat.artikkel)
        ? (_openBlock(), _createBlock(_component_ButtonLink, {
            key: 0,
            href: `/bestill?leggTilArtikkelNr=${encodeURIComponent(
          _ctx.resultat.artikkel.artikkelNummer
        )}&leggTilArtikkelMerknad=${encodeURIComponent(_ctx.regNr)}`
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Opprett bestilling")
            ])),
            _: 1
          }, 8, ["href"]))
        : _createCommentVNode("", true)
    ])
  ]))
}