import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "button-row" }
const _hoisted_3 = { class: "saldo-liste" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlyttArtikler = _resolveComponent("FlyttArtikler")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_FyllP229Artikler = _resolveComponent("FyllPåArtikler")!
  const _component_OpprettLokasjon = _resolveComponent("OpprettLokasjon")!
  const _component_CenteredSpinner = _resolveComponent("CenteredSpinner")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Modal, {
      aktiv: _ctx.aktivModal === 'flytt',
      onLukk: _cache[1] || (_cache[1] = ($event: any) => (_ctx.aktivModal = null))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FlyttArtikler, {
          onLukk: _cache[0] || (_cache[0] = ($event: any) => (_ctx.håndterModalLukk($event))),
          lokasjoner: _ctx.saldo,
          artikkelNr: _ctx.artikkelNr,
          lager: _ctx.lager
        }, null, 8, ["lokasjoner", "artikkelNr", "lager"])
      ]),
      _: 1
    }, 8, ["aktiv"]),
    _createVNode(_component_Modal, {
      aktiv: _ctx.aktivModal === 'fyll-på',
      onLukk: _cache[3] || (_cache[3] = ($event: any) => (_ctx.aktivModal = null))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FyllP229Artikler, {
          onLukk: _cache[2] || (_cache[2] = ($event: any) => (_ctx.håndterModalLukk($event))),
          lokasjoner: _ctx.saldo,
          artikkelNr: _ctx.artikkelNr,
          lager: _ctx.lager
        }, null, 8, ["lokasjoner", "artikkelNr", "lager"])
      ]),
      _: 1
    }, 8, ["aktiv"]),
    _createVNode(_component_Modal, {
      aktiv: _ctx.aktivModal === 'opprett-lokasjon',
      onLukk: _cache[5] || (_cache[5] = ($event: any) => (_ctx.aktivModal = null))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_OpprettLokasjon, {
          onLukk: _cache[4] || (_cache[4] = ($event: any) => (_ctx.håndterModalLukk($event))),
          artikkelNr: _ctx.artikkelNr,
          lager: _ctx.lager
        }, null, 8, ["artikkelNr", "lager"])
      ]),
      _: 1
    }, 8, ["aktiv"]),
    (!_ctx.lastet)
      ? (_openBlock(), _createBlock(_component_CenteredSpinner, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Button, {
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.aktivModal = 'flytt'))
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("Flytt artikler")
              ])),
              _: 1
            }),
            _createVNode(_component_Button, {
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.aktivModal = 'fyll-på'))
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode("Fyll på artikler")
              ])),
              _: 1
            }),
            _createVNode(_component_Button, {
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.aktivModal = 'opprett-lokasjon'))
            }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode("Legg til lokasjon")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("table", _hoisted_3, [
            _cache[12] || (_cache[12] = _createElementVNode("thead", null, [
              _createElementVNode("th", null, "Lokasjon"),
              _createElementVNode("th", null, "Lagerført saldo"),
              _createElementVNode("th", null, "Allokert antall"),
              _createElementVNode("th", null, "Disponibel saldo")
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.saldo, (lokasjon) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: lokasjon.lokasjon
                }, [
                  _createElementVNode("td", null, _toDisplayString(lokasjon.lokasjon), 1),
                  _createElementVNode("td", null, _toDisplayString(lokasjon.saldo), 1),
                  _createElementVNode("td", null, _toDisplayString(lokasjon.allokert), 1),
                  _createElementVNode("td", null, _toDisplayString(lokasjon.saldo - lokasjon.allokert), 1)
                ]))
              }), 128))
            ])
          ])
        ]))
  ]))
}