<template>
  <CenteredSpinner v-if="!lastet" />
  <div v-else class="artikkel-info">
    <div class="field-group">
      <label>Artikkelnummer</label>
      <p>{{ artikkel.artikkelNummer }}</p>
    </div>
    <div class="field-group">
      <label>Eurokode</label>
      <p>{{ artikkel?.euroKoder.map((k) => k).join(", ") }}</p>
    </div>
    <div class="field-group">
      <label>Navn</label>
      <p>{{ artikkel.navn }}</p>
    </div>
    <div class="field-group">
      <label>Egenskaper</label>
      <p>{{ artikkel.egenskaper.map((e) => e.beskrivelse).join(", ") }}</p>
    </div>

    <div class="field-group">
      <label>Hovedlokasjon</label>
      <p>{{ hovedlokasjon.hovedLokasjon }}</p>
    </div>
    <div class="field-group">
      <label>Årsbehov</label>
      <p>{{ hovedlokasjon.årsbehov }}</p>
    </div>
    <div class="field-group">
      <label>Bruttopris</label>
      <p>{{ artikkel.bruttoPris }} kr</p>
    </div>
  </div>
</template>

<script lang="ts">
import { hentArtikkel, hentHovedlokasjon } from "@/clients/artikkelClient";
import { IArtikkel, IHovedLokasjon } from "@/types";
import { defineComponent, ref, watchEffect } from "vue";

export default defineComponent({
  props: {
    artikkelNr: {
      type: String,
      required: true,
    },
    lager: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const artikkel = ref<IArtikkel>();
    const hovedlokasjon = ref<IHovedLokasjon>();
    const lastet = ref(false);

    const doHentArtikkel = async () => {
      lastet.value = false;
      [artikkel.value, hovedlokasjon.value] = await Promise.all([
        hentArtikkel(props.artikkelNr),
        hentHovedlokasjon(props.artikkelNr, props.lager),
      ]);
      lastet.value = true;
    };

    watchEffect(doHentArtikkel);

    return { artikkel, lastet, hovedlokasjon };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.artikkel-info {
  display: grid;
  gap: 1rem;
  grid-template-rows: repeat(3, 1fr);
  grid-auto-flow: column;
}
</style>
