<template>
  <NyBruker />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { NyBruker } from "@/components";

export default defineComponent({
  props: {},

  setup() {
    return {};
  },

  components: { NyBruker },
});
</script>

<style lang="less" scoped></style>
