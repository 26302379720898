import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "vareliste" }
const _hoisted_2 = { class: "hovedlinje" }
const _hoisted_3 = { class: "hovedlinje-rad" }
const _hoisted_4 = ["onUpdate:modelValue", "onChange"]
const _hoisted_5 = { class: "pris" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "hovedlinje-rad" }
const _hoisted_8 = ["onUpdate:modelValue"]
const _hoisted_9 = {
  key: 0,
  class: "følgeartikler"
}
const _hoisted_10 = ["onUpdate:modelValue", "onChange"]
const _hoisted_11 = { class: "pris" }
const _hoisted_12 = { class: "bestill-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Handlekurv", -1)),
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.linjer, (linje) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "linje",
          key: linje.linjeId
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", null, [
                _createElementVNode("strong", null, _toDisplayString(linje.artikkel.artikkelNummer) + ":", 1),
                _createTextVNode(" " + _toDisplayString(linje.artikkel.navn), 1)
              ]),
              _withDirectives(_createElementVNode("input", {
                class: "antall",
                type: "number",
                "onUpdate:modelValue": ($event: any) => ((linje.antall) = $event),
                min: "0",
                onChange: ($event: any) => (linje.antall = Math.max(0, linje.antall))
              }, null, 40, _hoisted_4), [
                [_vModelText, linje.antall]
              ]),
              _createElementVNode("span", _hoisted_5, _toDisplayString((linje.antall * linje.artikkel.bruttoPris).toFixed(2)) + " kr", 1),
              _createElementVNode("button", {
                onClick: ($event: any) => (_ctx.$emit('slett-linje', linje)),
                class: "slett-knapp"
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("i", { class: "las la-trash" }, null, -1)
              ]), 8, _hoisted_6)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _cache[2] || (_cache[2] = _createElementVNode("label", null, "Merknad:", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": ($event: any) => ((linje.merknad) = $event),
                placeholder: "F.eks. registreringsnummer",
                maxlength: "250"
              }, null, 8, _hoisted_8), [
                [_vModelText, linje.merknad]
              ])
            ])
          ]),
          (linje.følgeartikler.length > 0)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(linje.følgeartikler, (følgeartikkel, j) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: "rad",
                    key: følgeartikkel.sekvens
                  }, [
                    _createElementVNode("span", null, [
                      _createElementVNode("strong", null, _toDisplayString(følgeartikkel.artikkel.artikkelNummer) + ":", 1),
                      _createTextVNode(" " + _toDisplayString(følgeartikkel.artikkel.navn), 1)
                    ]),
                    _withDirectives(_createElementVNode("input", {
                      class: "antall",
                      type: "number",
                      "onUpdate:modelValue": ($event: any) => ((linje.følgeartikler[j].antall) = $event),
                      min: "0",
                      onChange: ($event: any) => (linje.følgeartikler[j].antall = Math.max(0, linje.følgeartikler[j].antall))
                    }, null, 40, _hoisted_10), [
                      [_vModelText, linje.følgeartikler[j].antall]
                    ]),
                    _createElementVNode("span", _hoisted_11, _toDisplayString((følgeartikkel.antall * følgeartikkel.artikkel.bruttoPris).toFixed(2)) + " kr", 1)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_Button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('opprett-bestilling'))),
        disabled: _ctx.linjer.length === 0,
        type: "primary",
        class: "bestill-knapp"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("Bestill varer")
        ])),
        _: 1
      }, 8, ["disabled"])
    ])
  ]))
}