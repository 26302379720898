import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ingen-treff-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_CenteredSpinner = _resolveComponent("CenteredSpinner")!
  const _component_RuteVelger = _resolveComponent("RuteVelger")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("input", {
      type: "text",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.artikkelNr) = $event)),
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.harSøkt = false)),
      onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.søkOppArtikler()), ["enter"])),
      placeholder: "Artikkelnummer"
    }, null, 544), [
      [_vModelText, _ctx.artikkelNr]
    ]),
    _createVNode(_component_Button, {
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.søkOppArtikler()))
    }, {
      default: _withCtx(() => _cache[4] || (_cache[4] = [
        _createTextVNode("Søk")
      ])),
      _: 1
    }),
    (_ctx.harSøkt && !_ctx.søker && _ctx.artikler.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Ingen treff"))
      : _createCommentVNode("", true),
    (_ctx.søker)
      ? (_openBlock(), _createBlock(_component_CenteredSpinner, {
          key: 1,
          class: "søk-spinner"
        }))
      : _createCommentVNode("", true),
    (_ctx.artikler.length > 0)
      ? (_openBlock(), _createBlock(_component_RuteVelger, {
          key: 2,
          ruter: _ctx.artikler,
          onVelgRute: _ctx.velgArtikkel
        }, null, 8, ["ruter", "onVelgRute"]))
      : _createCommentVNode("", true)
  ]))
}