<template>
  <div class="eksport">
    <h1>Eksporter data</h1>
    <div>
      <input type="checkbox" v-model="bestillinger" id="bestillinger" />
      <label for="bestillinger">Bestillinger</label>
    </div>
    <div>
      <input type="checkbox" v-model="artikler" id="artikler" />
      <label for="artikler">Artikler</label>
    </div>
    <div>
      <input type="checkbox" v-model="kunder" id="kunder" />
      <label for="kunder">Kunder</label>
    </div>
    <div>
      <input type="checkbox" v-model="lagerSaldo" id="lagerSaldo" />
      <label for="lagerSaldo">Saldoregister</label>
    </div>
    <div>
      <input type="checkbox" v-model="hovedlokasjoner" id="hovedlokasjoner" />
      <label for="hovedlokasjoner">Hovedlokasjoner (og årsbehov)</label>
    </div>
    <div>
      <input type="checkbox" v-model="fremtidigeOppdrag" id="fremtidigeOppdrag" />
      <label for="fremtidigeOppdrag">Oppdrag (fra Bilglassportalen)</label>
    </div>
    <Button v-if="!laster" type="primary" @click="eksporter">Eksporter</Button>
    <CenteredSpinner v-else />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { lastNedEksport } from "@/utils";

export default defineComponent({
  props: {},

  setup() {
    const bestillinger = ref(true);
    const artikler = ref(true);
    const kunder = ref(true);
    const lagerSaldo = ref(false);
    const hovedlokasjoner = ref(false);
    const fremtidigeOppdrag = ref(false);
    const laster = ref(false);

    const eksporter = async () => {
      laster.value = true;
      await lastNedEksport({
        bestillinger: bestillinger.value,
        artikler: artikler.value,
        kunder: kunder.value,
        lagerSaldo: lagerSaldo.value,
        hovedlokasjoner: hovedlokasjoner.value,
        fremtidigeOppdrag: fremtidigeOppdrag.value,
      });
      laster.value = false;
    };

    return { eksporter, bestillinger, artikler, kunder, lagerSaldo, hovedlokasjoner, fremtidigeOppdrag, laster };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.eksport {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

label {
  margin-left: 0.6rem;
}

h1 {
  margin: 0;
}
</style>
