import { post } from "@/utils";

export const flyttArtikler = async (
  artikkelNr: string,
  fraLokasjon: string,
  tilLokasjon: string,
  antall: number,
  lager?: string
): Promise<void> => {
  await post(`/api/lokasjoner/flytt-artikler${lager ? `?lager=${lager}` : ""}`, {
    artikkelNr,
    fraLokasjon,
    tilLokasjon,
    antall,
  });
};

export const opprettLokasjon = async (
  artikkelNr: string,
  lokasjon: string,
  antall: number,
  lager?: string
): Promise<void> => {
  await post(`/api/lokasjoner/opprett-lokasjon${lager ? `?lager=${lager}` : ""}`, {
    artikkelNr,
    lokasjon,
    antall,
  });
};

export const fyllPåArtikler = async (
  artikkelNr: string,
  lokasjon: string,
  antall: number,
  lager?: string
): Promise<void> => {
  await post(`/api/lokasjoner/fyll-på-artikler${lager ? `?lager=${lager}` : ""}`, {
    artikkelNr,
    lokasjon,
    antall,
  });
};
