import { ApplicationPaths, ApplicationName, QueryParameterNames } from "./ApiAuthorizationConstants";
import { UserManager, UserManagerSettings, WebStorageStateStore, User, Profile } from "oidc-client";
import { BrukerRolle } from "@/types";
import { STORAGE_PREFIX } from "@/constants";

const _popupDisabled = true;
let _user: User | null = null;
let _userManager: UserManager | undefined;

const getUserManager = async (): Promise<UserManager> => {
  if (_userManager) return _userManager;

  let settings: UserManagerSettings;
  const settingsFromLocalStorage = loadSettingsFromStorage();

  if (settingsFromLocalStorage) {
    settings = settingsFromLocalStorage;
  } else {
    settings = await loadSettingsFromBackend();
    saveSettingsToStorage(settings);
  }

  const userManager = new UserManager(settings);
  userManager.events.addUserSignedOut(async () => {
    await userManager.removeUser();
    _user = null;
  });

  return userManager;
};

const loadSettingsFromBackend = async (): Promise<UserManagerSettings> => {
  const response = await fetch(ApplicationPaths.ApiAuthorizationClientConfigurationUrl);
  if (!response.ok) {
    throw new Error(`Kunne ikke laste innstillinger for OIDC`);
  }

  let settings = await response.json();
  settings = {
    ...settings,
    automaticSilentRenew: true,
    includeIdTokenInSilentRenew: true,
    userStore: new WebStorageStateStore({
      store: window.localStorage,
      prefix: STORAGE_PREFIX,
    }),
  };

  return settings;
};

const loadSettingsFromStorage = (): UserManagerSettings | undefined => {
  const loadedSettings = window.sessionStorage.getItem(`${STORAGE_PREFIX}.client_settings`);
  if (!loadedSettings) return undefined;

  const parsedSettings = JSON.parse(loadedSettings);
  const settings: UserManagerSettings = {
    ...parsedSettings,
    userStore: new WebStorageStateStore({
      store: window.localStorage,
      prefix: ApplicationName,
    }),
  };

  return settings;
};

const saveSettingsToStorage = (settings: UserManagerSettings): void => {
  window.sessionStorage.setItem(`${STORAGE_PREFIX}.client_settings`, JSON.stringify(settings));
};

const getUser = async (): Promise<Profile | undefined> => {
  if (_user && _user.profile) {
    return _user.profile;
  }

  const userManager = await getUserManager();

  const user = await userManager.getUser();
  _user = user;
  return user?.profile;
};

const isAuthenticated = async (): Promise<boolean> => {
  const userManager = await getUserManager();
  const user = await userManager.getUser();
  return !!user && !user.expired;
};

const hasRole = async (role: BrukerRolle): Promise<boolean> => {
  const user = await getUser();
  return (user?.role === role || (Array.isArray(user?.role) && user?.role.includes(role))) ?? false;
};

const getAccessToken = async (): Promise<string | undefined> => {
  const userManager = await getUserManager();
  const user = await userManager.getUser();
  return user?.access_token;
};

const signIn = async (state: AuthState = {}): Promise<AuthResponse> => {
  const userManager = await getUserManager();
  try {
    const silentUser = await userManager.signinSilent(createArguments());
    _user = silentUser;
    return success(state);
  } catch (silentError) {
    try {
      if (_popupDisabled) {
        throw new Error("Popup disabled");
      }

      const popUpUser = await userManager.signinPopup(createArguments());
      _user = popUpUser;
      return success(state);
    } catch (popUpError: any) {
      if (popUpError.message === "Popup window closed") {
        // The user explicitly cancelled the login action by closing an opened popup.
        return error("Brukeren lukket vinduet.");
      } else if (!_popupDisabled) {
        console.log("Popup authentication error: ", popUpError);
      }

      try {
        await userManager.signinRedirect(createArguments(state));
        return redirect();
      } catch (redirectError: any) {
        console.error("Redirect authentication error: ", redirectError);
        return error(redirectError);
      }
    }
  }
};

const completeSignIn = async (url: string): Promise<AuthResponse> => {
  const userManager = await getUserManager();
  try {
    const user = await userManager.signinCallback(url);
    _user = user;
    return success(user?.state);
  } catch (e: any) {
    console.error("There was an error signing in: ", e);
    return error("Feil ved innlogging.");
  }
};

const signOut = async (state?: AuthState): Promise<AuthResponse> => {
  const userManager = await getUserManager();
  try {
    if (_popupDisabled) {
      throw new Error("Popup disabled");
    }

    await userManager.signoutPopup(createArguments());
    _user = null;
    return success(state);
  } catch (popupSignOutError) {
    console.log("Popup signout error: ", popupSignOutError);
    try {
      await userManager.signoutRedirect(createArguments(state));
      return redirect();
    } catch (redirectSignOutError: any) {
      console.error("Redirect signout error: ", redirectSignOutError);
      return error(redirectSignOutError);
    }
  }
};

const completeSignOut = async (url: string): Promise<AuthResponse> => {
  const userManager = await getUserManager();
  try {
    const response = await userManager.signoutCallback(url);
    _user = null;
    return success(response?.state);
  } catch (e: any) {
    console.error("There was an error signing out: ", e);
    return error(e);
  }
};

const createArguments = (state?: AuthState): AuthArguments => {
  return { useReplaceToNavigate: true, data: state };
};

type AuthArguments = {
  useReplaceToNavigate: boolean;
  data?: AuthState;
};

type AuthResponse = {
  status: string;
  message?: string;
  state?: AuthState;
};

type AuthState = {
  returnUrl?: string;
};

const error = (message: string): AuthResponse => {
  return { status: AuthenticationResultStatus.Fail, message };
};

const success = (state?: AuthState): AuthResponse => {
  return { status: AuthenticationResultStatus.Success, state };
};

const redirect = (): AuthResponse => {
  return { status: AuthenticationResultStatus.Redirect };
};

const navigateToUrl = (returnUrl: string): void => {
  window.location.replace(returnUrl);
};

const getReturnUrl = (state?: AuthState): string => {
  const params = new URLSearchParams(window.location.search);
  const fromQuery = params.get(QueryParameterNames.ReturnUrl);
  if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
    throw new Error("Invalid return url – must have same origin as current page.");
  }
  return state?.returnUrl || fromQuery || `${window.location.origin}/`;
};

const init = async (): Promise<void> => {
  await getUserManager();
};

export default {
  getUser,
  isAuthenticated,
  hasRole,
  getAccessToken,
  signIn,
  signOut,
  completeSignIn,
  completeSignOut,
  navigateToUrl,
  getReturnUrl,
  init,
};

export const AuthenticationResultStatus = {
  Redirect: "redirect",
  Success: "success",
  Fail: "fail",
};
