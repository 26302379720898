import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OpprettTokensForAlleKunder = _resolveComponent("OpprettTokensForAlleKunder")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", null, "Opprett tokens for alle kunder", -1)),
    _cache[1] || (_cache[1] = _createElementVNode("div", null, [
      _createElementVNode("p", null, " Dette verktøyet lar deg opprette tokens for alle kunder som bruker Bilglassportalen. Disse lar kundene selv registrere brukere i reserveløsningen. Hver token er gyldig i 14 dager. "),
      _createElementVNode("p", null, " Under kan du velge om tokens skal sendes til alle kunder på e-post, SMS, eller om du bare vil laste ned en CSV med alle tokens. ")
    ], -1)),
    _createVNode(_component_OpprettTokensForAlleKunder)
  ]))
}