import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_UnderMeny = _resolveComponent("UnderMeny")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_ctx.isAdmin || _ctx.isKundesenter || _ctx.isKunde)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: "/rutesok"
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Rutesøk")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isAdmin || _ctx.isKundesenter || _ctx.isKunde)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: "/bestill"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Bestill varer")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isKunde)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 2,
          to: "/mine-bestillinger"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Mine bestillinger")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isAdmin || _ctx.isKundesenter || _ctx.isKunde)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 3,
          to: "/oppdrag"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("Oppdrag")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isLager || _ctx.isKundesenter || _ctx.isAdmin)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 4,
          to: "/apne-bestillinger"
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("Åpne bestillinger")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isLager || _ctx.isKundesenter || _ctx.isAdmin)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 5,
          to: "/bestillinger"
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("Bestillingsarkiv")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isLager || _ctx.isKundesenter || _ctx.isAdmin)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 6,
          to: "/artikler"
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode("Artikler")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isLager || _ctx.isKundesenter || _ctx.isAdmin)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 7,
          to: "/kunder"
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode("Kunder")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isAdmin)
      ? (_openBlock(), _createBlock(_component_UnderMeny, {
          key: 8,
          tittel: "Admin",
          aktiv: _ctx.aktivUndermeny == 'admin',
          onOpen: _cache[0] || (_cache[0] = ($event: any) => (_ctx.aktivUndermeny = 'admin')),
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.aktivUndermeny = ''))
        }, {
          default: _withCtx(() => [
            (_ctx.isAdmin)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: "/brukere"
                }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode("Brukere")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.isAdmin)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: "/driftsetting"
                }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createTextVNode("Driftsetting")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.isDeveloper)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 2,
                  to: "/developer"
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode("Utviklerverktøy")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.isAdmin)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 3,
                  to: "/eksport"
                }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode("Eksporter data")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["aktiv"]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_link, {
      to: "/min-side",
      class: "min-side"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.userProfile?.name ?? "Min side"), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, {
      to: "/auth/logout",
      class: "logg-ut"
    }, {
      default: _withCtx(() => _cache[14] || (_cache[14] = [
        _createTextVNode("Logg ut")
      ])),
      _: 1
    })
  ]))
}