<template>
  <table>
    <thead>
      <tr>
        <th>Id</th>
        <th>Ordrenummer</th>
        <th>Kunde</th>
        <th v-if="isAdmin || isKundesenter">Lager</th>
        <th>Rute</th>
        <th>Status</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="bestilling in bestillinger" :key="bestilling.id">
        <td>{{ bestilling.id }}</td>
        <td>{{ bestilling.ordreNummer ?? "" }}</td>
        <td>
          {{ bestilling.kundeNr }}
          <span v-if="bestilling.kunde.navn"> - {{ bestilling.kunde.navn }}</span>
        </td>
        <td v-if="isAdmin || isKundesenter">
          {{ bestilling.lager }}
        </td>
        <td>{{ bestilling.rute.beskrivelse ?? "" }}</td>
        <td>{{ bestillingStatusTilTekst(bestilling.status) }}</td>
        <td class="button-cell">
          <ButtonRouterLink :to="`/bestillinger/${bestilling.id}/plukkliste`" title="Skriv ut plukkliste">
            <i class="las la-clipboard-list" />
          </ButtonRouterLink>
          <ButtonRouterLink :to="`/bestillinger/${bestilling.id}/fraktetikett`" title="Skriv ut fraktetikett">
            <i class="las la-truck" />
          </ButtonRouterLink>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { BestillingStatus, BestillingStatusTekst, IBestilling } from "@/types";
import authService from "@/utils/auth/authService";
import { defineComponent, PropType, ref } from "vue";

export default defineComponent({
  props: {
    bestillinger: {
      type: Array as PropType<IBestilling[]>,
      required: true,
    },
  },

  setup() {
    const isKundesenter = ref(false);
    const isAdmin = ref(false);
    authService.hasRole("Admin").then((result) => (isAdmin.value = result));
    authService.hasRole("Kundesenter").then((result) => (isKundesenter.value = result));

    const bestillingStatusTilTekst = (status: BestillingStatus): string | undefined => {
      return BestillingStatusTekst.get(status);
    };

    return { bestillingStatusTilTekst, isAdmin, isKundesenter };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.button-cell {
  display: flex;
  justify-content: center;
  gap: 0.5rem;

  button,
  a {
    font-size: 1.1rem;
  }
}

td {
  padding: 0.5rem 0.8rem;
  vertical-align: middle;
}
</style>
