import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-gruppe" }
const _hoisted_2 = { class: "input-gruppe" }
const _hoisted_3 = { class: "input-gruppe" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CenteredSpinner = _resolveComponent("CenteredSpinner")!
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.sender)
    ? (_openBlock(), _createBlock(_component_CenteredSpinner, { key: 0 }))
    : (_openBlock(), _createElementBlock("form", {
        key: 1,
        class: "epost-tester",
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.doSendEpost && _ctx.doSendEpost(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _cache[4] || (_cache[4] = _createElementVNode("label", { for: "mottaker" }, " Mottaker ", -1)),
          _withDirectives(_createElementVNode("input", {
            id: "mottaker",
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mottaker) = $event))
          }, null, 512), [
            [_vModelText, _ctx.mottaker]
          ])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _cache[5] || (_cache[5] = _createElementVNode("label", { for: "emne" }, " Emne ", -1)),
          _withDirectives(_createElementVNode("input", {
            id: "emne",
            type: "text",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.emne) = $event))
          }, null, 512), [
            [_vModelText, _ctx.emne]
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[6] || (_cache[6] = _createElementVNode("label", { for: "melding" }, " Melding ", -1)),
          _withDirectives(_createElementVNode("textarea", {
            id: "melding",
            type: "text",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.melding) = $event))
          }, null, 512), [
            [_vModelText, _ctx.melding]
          ])
        ]),
        _createVNode(_component_Button, {
          type: "primary",
          onClick: _ctx.doSendEpost
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("Send")
          ])),
          _: 1
        }, 8, ["onClick"])
      ], 32))
}