import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spinner-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Plukkliste = _resolveComponent("Plukkliste")!

  return (!_ctx.lastet)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Spinner)
      ]))
    : (_openBlock(), _createBlock(_component_Plukkliste, {
        key: 1,
        bestilling: _ctx.bestilling,
        transportRute: _ctx.transportRute
      }, null, 8, ["bestilling", "transportRute"]))
}