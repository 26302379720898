<template>
  <tr class="underlinje">
    <td></td>
    <td class="eurokode-title"></td>
    <td v-if="!!resultat.artikkel">
      <p>
        <span class="artikkelnummer">{{ resultat.artikkel.artikkelNummer }}: </span>
        <span class="artikkelnavn">{{ resultat.artikkel.navn }}</span>
      </p>
      <p class="egenskaper">{{ resultat.artikkel.egenskaper.map((egenskap) => egenskap.beskrivelse).join(", ") }}</p>
    </td>
    <td v-else class="ingen-resultat">Fant ingen artikkel som matchet eurokode {{ resultat.eurokode }}</td>
    <td>
      <ButtonLink
        v-if="!!resultat.artikkel"
        :href="`/bestill?leggTilArtikkelNr=${encodeURIComponent(
          resultat.artikkel.artikkelNummer
        )}&leggTilArtikkelMerknad=${encodeURIComponent(regNr)}`"
        >Opprett bestilling</ButtonLink
      >
    </td>
  </tr>
</template>

<script lang="ts">
import { IRutesøkSvarResultat } from "@/types";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    resultat: {
      type: Object as PropType<IRutesøkSvarResultat>,
    },
    regNr: {
      type: String,
    },
  },

  setup() {
    return {};
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.artikkelnummer {
  font-weight: bold;
}

.egenskaper {
  color: @black40;
}

.ingen-resultat {
  color: @red-light;
}

.underlinje {
  .eurokode-title {
    text-align: right;
  }
}
</style>
