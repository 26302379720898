import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "legg-til-wrapper" }
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.brukerRoller, (rolle) => {
        return (_openBlock(), _createElementBlock("li", {
          key: rolle.id
        }, [
          _createTextVNode(_toDisplayString(rolle.navn) + " ", 1),
          _createVNode(_component_Button, {
            onClick: ($event: any) => (_ctx.fjernRolle(rolle.navn)),
            type: "danger"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Fjern")
            ])),
            _: 2
          }, 1032, ["onClick"])
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valgtRolle) = $event)),
        class: "rollevelger"
      }, [
        _cache[2] || (_cache[2] = _createElementVNode("option", {
          value: undefined,
          disabled: ""
        }, "-- Velg rolle --", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valgbareRoller, (rolle) => {
          return (_openBlock(), _createElementBlock("option", {
            value: rolle,
            key: rolle.id
          }, _toDisplayString(rolle.navn), 9, _hoisted_3))
        }), 128))
      ], 512), [
        [_vModelSelect, _ctx.valgtRolle]
      ]),
      _createVNode(_component_Button, { onClick: _ctx.leggTilRolle }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("Legg til")
        ])),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}