import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "åpne-ikon las la-caret-right"
}
const _hoisted_2 = {
  key: 1,
  class: "åpne-ikon las la-caret-down"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Rutes248kUnderlinje = _resolveComponent("RutesøkUnderlinje")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("tr", {
      class: "hovedlinje",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.håndterLinjeKlikk()))
    }, [
      _createElementVNode("td", null, _toDisplayString(_ctx.rutesøk.bilInfo.regNr), 1),
      _createElementVNode("td", null, _toDisplayString(`${_ctx.rutesøk.bilInfo.merke} ${_ctx.rutesøk.bilInfo.modell} ${_ctx.rutesøk.bilInfo.årsmodell}`), 1),
      _createElementVNode("td", null, _toDisplayString(_ctx.rutesøk.svar ? _ctx.formaterDatoTid(_ctx.rutesøk.svar.opprettet) : "Nei"), 1),
      _createElementVNode("td", null, [
        _createTextVNode(_toDisplayString(_ctx.rutesøk.svar?.eurokode.length ?? "") + " ", 1),
        (!_ctx.viserUnderlinjer && !!_ctx.rutesøk.svar)
          ? (_openBlock(), _createElementBlock("i", _hoisted_1))
          : _createCommentVNode("", true),
        (_ctx.viserUnderlinjer)
          ? (_openBlock(), _createElementBlock("i", _hoisted_2))
          : _createCommentVNode("", true)
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visteUnderlinjer, (resultat) => {
      return (_openBlock(), _createBlock(_component_Rutes248kUnderlinje, {
        key: resultat.eurokode,
        resultat: resultat,
        regNr: _ctx.rutesøk.bilInfo.regNr
      }, null, 8, ["resultat", "regNr"]))
    }), 128))
  ], 64))
}