<template>
  <div class="typeahead">
    <input
      class="søkefelt"
      type="text"
      v-model="søkeVerdi"
      @input="onInputChange"
      @keydown="handleKey"
      :placeholder="placeholder"
    />

    <ul class="typeahead-results" v-if="result.length">
      <li
        v-for="(entry, i) in result"
        :key="entry.key"
        :class="{ active: i === activeResult }"
        @click="selectValue(entry.value)"
      >
        {{ entry.name }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";

export interface ITypeAheadResult {
  // eslint-disable-next-line
  value: any;
  name: string;
  key: string | number;
}

export default defineComponent({
  props: {
    searchFunction: {
      type: Function as PropType<(søkestreng: string) => Promise<ITypeAheadResult[]>>,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },

  setup(props, context) {
    const søkeVerdi = ref("");
    const result = ref<ITypeAheadResult[]>([]);
    const activeResult = ref(-1);

    const onInputChange = async () => {
      activeResult.value = -1;

      if (søkeVerdi.value.length < 3) {
        result.value = [];
        return;
      }

      result.value = await props.searchFunction(søkeVerdi.value);
    };

    // eslint-disable-next-line
    const selectValue = (value: any): void => {
      context.emit("update:modelValue", value);
      result.value = [];
      søkeVerdi.value = "";
    };

    const handleKey = (event: KeyboardEvent): void => {
      if (event.key === "ArrowDown" || event.key === "Tab") {
        event.preventDefault();
        activeResult.value = (activeResult.value + 1) % result.value.length;
        søkeVerdi.value = result.value[activeResult.value].name;
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        activeResult.value = (activeResult.value - 1) % result.value.length;
        søkeVerdi.value = result.value[activeResult.value].name;
      } else if (event.key === "Enter") {
        event.preventDefault();
        selectValue(result.value[activeResult.value].value);
      }
    };

    return { søkeVerdi, result, onInputChange, activeResult, handleKey, selectValue };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.typeahead {
  display: inline-block;
  padding: 0;
  position: relative;
}

.søkefelt {
  padding: 0.5rem;
  width: calc(100% - 1rem);
}

.typeahead-results {
  position: absolute;
  z-index: 99;
  padding: 0;
  min-width: 100%;
  width: max-content;
  margin: 0;

  max-height: 20rem;
  overflow: auto;

  list-style: none;
  background: @white;
  box-shadow: 0 0.1rem 0.3rem 0 @black60;

  display: flex;
  flex-direction: column;

  cursor: pointer;

  li {
    background: @white;
    padding: 0.4rem;

    &:hover {
      filter: brightness(0.95);
    }
  }

  li.active {
    filter: brightness(0.95);
  }
}
</style>
