<template>
  <select class="rutevelger" :value="modelValue" @change="$emit('update:modelValue', $event.target.value)">
    <option :value="frontrute">Frontrute</option>
    <option :value="siderute">Siderute</option>
    <option :value="bakrute">Bakrute</option>
    <option :value="annet">Annet</option>
  </select>
</template>

<script lang="ts">
import { RuteType } from "@/types";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<RuteType>,
      required: true,
    },
  },

  setup() {
    return {
      frontrute: RuteType.frontrute,
      siderute: RuteType.siderute,
      bakrute: RuteType.bakrute,
      annet: RuteType.ukjent,
    };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
</style>
