<template>
  <div class="bekreft-plukking">
    <h2>Bestillingsdetaljer</h2>

    <div v-if="fullBestilling">
      <table>
        <tbody>
          <tr v-for="linje in fullBestilling.linjer" :key="linje.id">
            <td><LagerStatusIkon :lagerStatus="linje.lagerStatus" /></td>
            <td>
              <router-link :to="`/artikler/${linje.artikkel.artikkelNummer}`" target="_blank" rel="noopener noreferrer">
                {{ linje.artikkel.artikkelNummer }} – {{ linje.artikkel.navn }}
              </router-link>
            </td>
            <td class="antall-kolonne">
              <span v-if="linje.antall != antall[linje.id]">Opprinnelig antall: {{ linje.antall }}</span>
              <input type="number" v-model="antall[linje.id]" min="0" />
            </td>
          </tr>
        </tbody>
      </table>

      <div class="button-row">
        <Button @click="avbryt()">Avbryt</Button>
        <Button @click="doBekreftPlukking()" type="primary" v-if="bestilling.status === BestillingStatus.underPlukking"
          >Bekreft</Button
        >
      </div>
    </div>

    <div class="spinner-wrapper" v-else>
      <Spinner />
    </div>
  </div>
</template>

<script lang="ts">
import { BestillingStatus, IBestilling } from "@/types";
import { defineComponent, PropType, ref, watch } from "vue";
import { bekreftPlukking, hentBestilling as getBestilling } from "@/clients/bestillingClient";
import { toast } from "@/utils";
import LagerStatusIkon from "./LagerStatusIkon.vue";

export default defineComponent({
  props: {
    bestilling: {
      type: Object as PropType<IBestilling>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const fullBestilling = ref<IBestilling | null>(null);
    const antall = ref<{ [linjeId: number]: number }>();

    const hentBestilling = async () => {
      fullBestilling.value = null;
      fullBestilling.value = await getBestilling(props.bestilling.id);
      antall.value = Object.assign({}, ...fullBestilling.value.linjer.map((x) => ({ [x.id]: x.antall })));
    };

    watch(props.bestilling, hentBestilling);

    if (props.bestilling) {
      hentBestilling();
    }

    const doBekreftPlukking = async () => {
      await bekreftPlukking(props.bestilling.id, antall.value);
      toast(`Bekreftet plukking for bestilling ${props.bestilling.id}`);
      emit("bestilling-bekreftet", props.bestilling);
    };

    const avbryt = async () => {
      emit("avbryt");
    };

    return { fullBestilling, doBekreftPlukking, antall, avbryt, BestillingStatus };
  },

  components: { LagerStatusIkon },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.spinner-wrapper {
  display: flex;
  justify-content: center;
}

table {
  width: 100%;
}

td {
  vertical-align: middle;
}

.antall-kolonne {
  display: flex;
  justify-content: right;
  gap: 1rem;
  align-items: center;
}

input {
  width: 3rem;
}

.button-row {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.6rem;
}
</style>
