<template>
  <div></div>
</template>

<script lang="ts">
import authService from "@/utils/auth/authService";
import { defineComponent } from "vue";

export default defineComponent({
  props: {},

  setup() {
    authService.navigateToUrl("/");
    return {};
  },
});
</script>

<style lang="less" scoped></style>
