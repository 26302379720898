import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spinner-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BestillingFilter = _resolveComponent("BestillingFilter")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_BestillingArkiv = _resolveComponent("BestillingArkiv")!
  const _component_SideVelger = _resolveComponent("SideVelger")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[3] || (_cache[3] = _createElementVNode("h1", null, "Bestillingsarkiv", -1)),
    _createVNode(_component_BestillingFilter, {
      class: "bestilling-filter",
      onSøk: _cache[0] || (_cache[0] = ($event: any) => {
        _ctx.page = 1;
        _ctx.hentBestillinger($event);
      })
    }),
    (!_ctx.lastet)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Spinner)
        ]))
      : (_openBlock(), _createBlock(_component_BestillingArkiv, {
          key: 1,
          class: "bestilling-liste",
          bestillinger: _ctx.bestillinger
        }, null, 8, ["bestillinger"])),
    (_ctx.lastet)
      ? (_openBlock(), _createBlock(_component_SideVelger, {
          key: 2,
          modelValue: _ctx.page,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.page) = $event)),
          antallPerSide: _ctx.pageSize,
          "onUpdate:antallPerSide": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pageSize) = $event)),
          totaltAntall: _ctx.totaltAntall
        }, null, 8, ["modelValue", "antallPerSide", "totaltAntall"]))
      : _createCommentVNode("", true)
  ]))
}