<template>
  <div>
    <h2>Endre passord</h2>
    <EndrePassord />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { EndrePassord } from "@/components";

export default defineComponent({
  props: {},

  setup() {
    return {};
  },

  components: { EndrePassord },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
</style>
