<template>
  <div>
    <label>Brukernavn</label>
    <p>{{ bruker.brukerNavn }}</p>

    <label>E-post</label>
    <p>{{ bruker.epost }}</p>

    <label>Telefonnummer</label>
    <p>{{ bruker.telefonNummer }}</p>

    <label>Lager</label>
    <p>{{ bruker.lager }}</p>

    <label>Kundenummer</label>
    <p>{{ bruker.kundeNr }}</p>
  </div>
</template>

<script lang="ts">
import { IBruker } from "@/types";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    bruker: {
      type: Object as PropType<IBruker>,
      required: true,
    },
  },

  setup() {
    return {};
  },
});
</script>

<style lang="less" scoped></style>
