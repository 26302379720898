import { post } from "@/utils";

export const hentEksportToken = async (): Promise<string> => {
  const result = post(`/api/eksport/token`);

  return result;
};

export const hentKundeEksportToken = async (): Promise<string> => {
  const result = post(`/api/eksport/token/kunde`);
  return result;
};
