import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CenteredSpinner = _resolveComponent("CenteredSpinner")!
  const _component_KundeInfo = _resolveComponent("KundeInfo")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", null, "Kunde", -1)),
    (!_ctx.lastet)
      ? (_openBlock(), _createBlock(_component_CenteredSpinner, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.feilVedLasting)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, " Klarte ikke laste kunde med kundenummer " + _toDisplayString(_ctx.kundeNummer) + ". ", 1))
      : _createCommentVNode("", true),
    (_ctx.lastet && !_ctx.feilVedLasting)
      ? (_openBlock(), _createBlock(_component_KundeInfo, {
          key: 2,
          kunde: _ctx.kunde
        }, null, 8, ["kunde"]))
      : _createCommentVNode("", true)
  ]))
}