import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, withKeys as _withKeys, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nytt-rutesøk" }
const _hoisted_2 = {
  key: 1,
  class: "skjema-wrapper"
}
const _hoisted_3 = { class: "regnr-wrapper" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 3,
  for: "chassisNr"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CenteredSpinner = _resolveComponent("CenteredSpinner")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_InfoBox = _resolveComponent("InfoBox")!
  const _component_ModellVelger = _resolveComponent("ModellVelger")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.lagrer)
      ? (_openBlock(), _createBlock(_component_CenteredSpinner, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[10] || (_cache[10] = _createElementVNode("label", { for: "ruteType" }, "Rutetype", -1)),
          _withDirectives(_createElementVNode("select", {
            id: "ruteType",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ruteType) = $event))
          }, _cache[7] || (_cache[7] = [
            _createElementVNode("option", { value: 1 }, "Frontrute", -1),
            _createElementVNode("option", { value: 2 }, "Siderute", -1),
            _createElementVNode("option", { value: 3 }, "Bakrute", -1),
            _createElementVNode("option", { value: 4 }, "Takrute", -1)
          ]), 512), [
            [_vModelSelect, _ctx.ruteType]
          ]),
          _cache[11] || (_cache[11] = _createElementVNode("label", { for: "regNr" }, "Registreringsnummer", -1)),
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("input", {
              id: "regNr",
              type: "text",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.regNr) = $event)),
              placeholder: "Registreringsnummer",
              onKeydown: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.søkEtterBil()), ["enter"]))
            }, null, 544), [
              [_vModelText, _ctx.regNr]
            ]),
            _createVNode(_component_Button, {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.søkEtterBil()))
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode("Søk")
              ])),
              _: 1
            })
          ]),
          (_ctx.søkerEtterRegNr)
            ? (_openBlock(), _createBlock(_component_Spinner, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.harSøktEtterRegNr && _ctx.fantBil)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("p", null, "Fant bil med registreringsnummer " + _toDisplayString(_ctx.søktRegNr) + ":", 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.merke) + " " + _toDisplayString(_ctx.modell) + " " + _toDisplayString(_ctx.årsmodell), 1),
                _createElementVNode("p", null, "Chassisnummer: " + _toDisplayString(_ctx.chassisNr), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.harSøktEtterRegNr && !_ctx.fantBil)
            ? (_openBlock(), _createBlock(_component_InfoBox, { key: 2 }, {
                default: _withCtx(() => [
                  _createTextVNode(" Fant ingen bil med registreringsnummer " + _toDisplayString(_ctx.søktRegNr) + " - dobbeltsjekk at det er riktig. Dersom registreringsnummer stemmer må du fylle inn chassisnummer, merke, modell og årsmodell manuelt for å kunne utføre rutesøk. ", 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.harSøktEtterRegNr && !_ctx.fantBil)
            ? (_openBlock(), _createElementBlock("label", _hoisted_5, "Chassisnummer"))
            : _createCommentVNode("", true),
          (_ctx.harSøktEtterRegNr && !_ctx.fantBil)
            ? _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 4,
                type: "text",
                id: "chassisNr",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.chassisNr) = $event)),
                placeholder: "Chassisnummer"
              }, null, 512)), [
                [_vModelText, _ctx.chassisNr]
              ])
            : _createCommentVNode("", true),
          (_ctx.harSøktEtterRegNr && !_ctx.fantBil)
            ? (_openBlock(), _createBlock(_component_ModellVelger, {
                key: 5,
                "onUpdate:bilInfo": _cache[5] || (_cache[5] = ($event: any) => (_ctx.settBilInfoFraModellVelger($event)))
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_Button, {
            type: "primary",
            disabled: !(_ctx.ruteType && _ctx.regNr && _ctx.chassisNr && _ctx.merke && _ctx.modell && _ctx.årsmodell),
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.opprettRutesøk()))
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode(" Opprett rutesøk ")
            ])),
            _: 1
          }, 8, ["disabled"])
        ]))
  ]))
}