<template>
  <div class="wrapper">
    <Spinner />
    <p class="text">Logger inn</p>
  </div>
</template>

<script lang="ts">
import authService, { AuthenticationResultStatus } from "@/utils/auth/authService";
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import Spinner from "@/components/_basis/Spinner.vue";

const signIn = async (returnUrl: string) => {
  const result = await authService.signIn({ returnUrl });

  switch (result.status) {
    case AuthenticationResultStatus.Redirect:
      break;
    case AuthenticationResultStatus.Success:
      await authService.navigateToUrl(returnUrl);
      break;
    case AuthenticationResultStatus.Fail:
      //this.setState({ message: result.message });
      break;
    default:
      throw new Error(`Invalid status result ${result.status}.`);
  }
};

export default defineComponent({
  props: {},

  setup() {
    const route = useRoute();

    authService.isAuthenticated().then((isAuthenticated) => {
      if (isAuthenticated) {
        authService.navigateToUrl("/");
      } else {
        signIn(route.query.redirect ? (route.query.redirect as string) : "");
      }
    });

    return {};
  },

  components: {
    Spinner,
  },
});
</script>

<style lang="less" scoped>
.wrapper {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text {
  margin-top: 2rem;
  font-size: 1rem;
}
</style>
