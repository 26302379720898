<template>
  <section>
    <Dropdown v-if="verkstedNrListe.length > 1" :modelValue="valgtVerkstedNr"
              class="verksted-liste"
              label="Verksteder"
              @update:modelValue="(nyttValgtVerkstedNr) => endreValgtVerksted(nyttValgtVerkstedNr)">
      <option
        v-for="verkstedNr in verkstedNrListe"
        :key="verkstedNr"
        :selected="valgtVerkstedNr === verkstedNr"
        :value="verkstedNr"
      >
        {{ verkstedNr === "0" ? "Alle" : verkstedNr }}
      </option>
    </Dropdown>
    <DataListe v-if="filrertOppdragListe.length > 0">
      <template #headers>
        <span>Dato</span>
        <span>Regnr.</span>
        <span>Status</span>
      </template>
      <template #rows>
        <RouterLink v-for="oppdrag in filrertOppdragListe"
                    :key="oppdrag.oppdragNr"
                    :to="{ name: ROUTE_NAMES.oppdragDetaljer, params: { oppdragId: oppdrag.oppdragNr }}">
          <span>{{ formatterDato(oppdrag.verkstedDato) }}</span>
          <span>{{ oppdrag.regNr }}</span>
          <span>{{ oppdragStatusBeskrivelse.get(oppdrag.status) }}</span>
        </RouterLink>
      </template>
    </DataListe>
    <Spinner v-else-if="laster" class="spinner"/>
    <p v-else>Ingen oppdrag å vise</p>
  </section>
</template>

<script lang="ts" setup>
import { DataListe, Dropdown } from "@andrlriis/komponentbibliotek";
import { IOppdrag, oppdragStatusBeskrivelse } from "@/types";
import { computed, onMounted, ref } from "vue";
import { hentOppdragListe, hentVerkstedNrListe } from "@/clients/oppdragKlient";
import { ROUTE_NAMES } from "@/router";
import { formatterDato } from "../../utils";

const oppdragListe = ref<IOppdrag[]>([]);
const verkstedNrListe = ref<string[]>([]);
const valgtVerkstedNr = ref<string>("0");
const laster = ref(false);

const filrertOppdragListe = computed(() => {
  if (valgtVerkstedNr.value === "0") {
    return oppdragListe.value;
  }
  return oppdragListe.value.filter((oppdrag) => oppdrag.verkstedNr === valgtVerkstedNr.value);
});

onMounted(async () => {
  try {
    laster.value = true;
    oppdragListe.value = await hentOppdragListe();
    const liste = await hentVerkstedNrListe();
    if (liste.length > 1) {
      verkstedNrListe.value = ["0", ...liste];
    }
  } finally {
    laster.value = false;
  }
});

const endreValgtVerksted = (nyttVerkstedNr: string) => {
  valgtVerkstedNr.value = nyttVerkstedNr;
};
</script>

<style lang="less" scoped>
section {
  display: grid;
  gap: 20px;
}

.spinner {
  margin-top: 50px;
  justify-self: center;
  align-self: center;
}

:deep(.verksted-liste) {
  max-width: 150px;
}
</style>