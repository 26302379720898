import { createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "eksport" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_CenteredSpinner = _resolveComponent("CenteredSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[13] || (_cache[13] = _createElementVNode("h1", null, "Eksporter data", -1)),
    _createElementVNode("div", null, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.bestillinger) = $event)),
        id: "bestillinger"
      }, null, 512), [
        [_vModelCheckbox, _ctx.bestillinger]
      ]),
      _cache[6] || (_cache[6] = _createElementVNode("label", { for: "bestillinger" }, "Bestillinger", -1))
    ]),
    _createElementVNode("div", null, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.artikler) = $event)),
        id: "artikler"
      }, null, 512), [
        [_vModelCheckbox, _ctx.artikler]
      ]),
      _cache[7] || (_cache[7] = _createElementVNode("label", { for: "artikler" }, "Artikler", -1))
    ]),
    _createElementVNode("div", null, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.kunder) = $event)),
        id: "kunder"
      }, null, 512), [
        [_vModelCheckbox, _ctx.kunder]
      ]),
      _cache[8] || (_cache[8] = _createElementVNode("label", { for: "kunder" }, "Kunder", -1))
    ]),
    _createElementVNode("div", null, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.lagerSaldo) = $event)),
        id: "lagerSaldo"
      }, null, 512), [
        [_vModelCheckbox, _ctx.lagerSaldo]
      ]),
      _cache[9] || (_cache[9] = _createElementVNode("label", { for: "lagerSaldo" }, "Saldoregister", -1))
    ]),
    _createElementVNode("div", null, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.hovedlokasjoner) = $event)),
        id: "hovedlokasjoner"
      }, null, 512), [
        [_vModelCheckbox, _ctx.hovedlokasjoner]
      ]),
      _cache[10] || (_cache[10] = _createElementVNode("label", { for: "hovedlokasjoner" }, "Hovedlokasjoner (og årsbehov)", -1))
    ]),
    _createElementVNode("div", null, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.fremtidigeOppdrag) = $event)),
        id: "fremtidigeOppdrag"
      }, null, 512), [
        [_vModelCheckbox, _ctx.fremtidigeOppdrag]
      ]),
      _cache[11] || (_cache[11] = _createElementVNode("label", { for: "fremtidigeOppdrag" }, "Oppdrag (fra Bilglassportalen)", -1))
    ]),
    (!_ctx.laster)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          type: "primary",
          onClick: _ctx.eksporter
        }, {
          default: _withCtx(() => _cache[12] || (_cache[12] = [
            _createTextVNode("Eksporter")
          ])),
          _: 1
        }, 8, ["onClick"]))
      : (_openBlock(), _createBlock(_component_CenteredSpinner, { key: 1 }))
  ]))
}