<template>
  <div>
    <Modal v-for="dialog in dialoger" :key="dialog.id" :aktiv="true" @lukk="avbryt(dialog)">
      <Danger v-if="dialog.type === 'danger'" :title="dialog.title" @bekreft="bekreft(dialog)" @avbryt="avbryt(dialog)">
        {{ dialog.text }}
      </Danger>
    </Modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { dialoger, fjernDialog } from "@/utils/dialogUtils";
import { IDialog } from "@/types";
import Danger from "./Danger.vue";
export default defineComponent({
  props: {},

  setup() {
    const bekreft = (dialog: IDialog) => {
      fjernDialog(dialog);
      dialog.onConfirm?.();
    };

    const avbryt = (dialog: IDialog) => {
      fjernDialog(dialog);
      dialog.onCancel?.();
    };

    return { dialoger, bekreft, avbryt };
  },
  components: { Danger },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
</style>
