import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Danger = _resolveComponent("Danger")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dialoger, (dialog) => {
      return (_openBlock(), _createBlock(_component_Modal, {
        key: dialog.id,
        aktiv: true,
        onLukk: ($event: any) => (_ctx.avbryt(dialog))
      }, {
        default: _withCtx(() => [
          (dialog.type === 'danger')
            ? (_openBlock(), _createBlock(_component_Danger, {
                key: 0,
                title: dialog.title,
                onBekreft: ($event: any) => (_ctx.bekreft(dialog)),
                onAvbryt: ($event: any) => (_ctx.avbryt(dialog))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(dialog.text), 1)
                ]),
                _: 2
              }, 1032, ["title", "onBekreft", "onAvbryt"]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["onLukk"]))
    }), 128))
  ]))
}