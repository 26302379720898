import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "artikkel-info"
}
const _hoisted_2 = { class: "field-group" }
const _hoisted_3 = { class: "field-group" }
const _hoisted_4 = { class: "field-group" }
const _hoisted_5 = { class: "field-group" }
const _hoisted_6 = { class: "field-group" }
const _hoisted_7 = { class: "field-group" }
const _hoisted_8 = { class: "field-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CenteredSpinner = _resolveComponent("CenteredSpinner")!

  return (!_ctx.lastet)
    ? (_openBlock(), _createBlock(_component_CenteredSpinner, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("label", null, "Artikkelnummer", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.artikkel.artikkelNummer), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[1] || (_cache[1] = _createElementVNode("label", null, "Eurokode", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.artikkel?.euroKoder.map((k) => k).join(", ")), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("label", null, "Navn", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.artikkel.navn), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[3] || (_cache[3] = _createElementVNode("label", null, "Egenskaper", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.artikkel.egenskaper.map((e) => e.beskrivelse).join(", ")), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[4] || (_cache[4] = _createElementVNode("label", null, "Hovedlokasjon", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.hovedlokasjon.hovedLokasjon), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[5] || (_cache[5] = _createElementVNode("label", null, "Årsbehov", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.hovedlokasjon.årsbehov), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[6] || (_cache[6] = _createElementVNode("label", null, "Bruttopris", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.artikkel.bruttoPris) + " kr", 1)
        ])
      ]))
}