import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "toast-header"
}
const _hoisted_2 = { class: "toast-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["toast", _ctx.toast.type]),
    ref: "toastRef"
  }, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
      class: "close-button"
    }, "X"),
    (_ctx.toast.overskrift)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.toast.overskrift), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.toast.tekst), 1)
  ], 2))
}