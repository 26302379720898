<template>
  <div class="dialog-danger">
    <div class="danger-icon"><i class="las la-exclamation-triangle" /></div>
    <div class="dialog-content">
      <h2 class="title" v-if="title">{{ title }}</h2>
      <p class="text">
        <slot />
      </p>
      <div class="buttons">
        <Button @click="$emit('avbryt')">Avbryt</Button>
        <Button @click="$emit('bekreft')" type="primary">Bekreft</Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
    },
  },

  setup() {
    return {};
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.title {
  margin-top: 0.2rem;
}

.dialog-danger {
  display: flex;
  align-items: flex-start;
}

.danger-icon {
  color: @red-light;
  font-size: 5rem;
}

.dialog-content {
  margin: 0;
  padding: 0.3rem 1.5rem;
  flex-grow: 1;
}

.text {
  white-space: pre-wrap;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
  margin-top: 1.5rem;
}
</style>
