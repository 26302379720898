import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "søk-wrapper" }
const _hoisted_2 = {
  key: 1,
  class: "resultat"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CenteredSpinner = _resolveComponent("CenteredSpinner")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.søkestreng) = $event)),
        placeholder: "Søk etter kunde",
        onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.søk()), ["enter"]))
      }, null, 544), [
        [_vModelText, _ctx.søkestreng]
      ])
    ]),
    (_ctx.søker)
      ? (_openBlock(), _createBlock(_component_CenteredSpinner, { key: 0 }))
      : (_ctx.harSøkt && _ctx.resultat.length > 0)
        ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
            _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
              _createElementVNode("th", null, "Kundenummer"),
              _createElementVNode("th", null, "Navn")
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resultat, (kunde) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: kunde.kundeNummer,
                  onClick: ($event: any) => (_ctx.velgKunde(kunde)),
                  class: "kunde-rad"
                }, [
                  _createElementVNode("td", null, _toDisplayString(kunde.kundeNummer), 1),
                  _createElementVNode("td", null, _toDisplayString(kunde.navn), 1)
                ], 8, _hoisted_3))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
    (_ctx.harSøkt && _ctx.resultat.length === 0)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, "Ingen resultater"))
      : _createCommentVNode("", true)
  ]))
}