<template>
  <div>
    <h1>Fraktetikett</h1>
    <Button @click="print">Skriv ut</Button>

    <div ref="printContainer" class="print-container">
      <table>
        <tbody>
          <tr class="avsender">
            <label>Avsender</label>
            <span>
              {{ lager.navn }}
            </span>
            <span>
              {{ lager.adresselinje1 }}
            </span>
            <span>
              {{ lager.postNr }}
              {{ lager.postSted }}
            </span>
          </tr>

          <tr class="rute">
            <label>Rute</label>
            <span> {{ transportRute?.beskrivelse ?? "Ukjent" }} </span>
            <label>Avganger</label>
            <span>{{ avganger }}</span>
          </tr>

          <tr class="mottaker">
            <label>Mottaker</label>
            <span>
              {{ kunde.navn }}
            </span>
            <span>
              {{ kunde.adresselinje1 }}
            </span>
            <span>
              {{ kunde.postNr }}
              {{ kunde.postSted }}
            </span>
          </tr>

          <tr class="kolli">
            <span class="dato">
              <label>Dato</label>
              <input type="text" disabled />
            </span>
            <span class="kolli-info">
              <label>Kolli</label>
              <input type="text" disabled />
            </span>
            <span class="vekt">
              <label>Vekt</label>
              <input type="text" disabled />
            </span>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { hentBestilling, hentRuteForBestilling } from "@/clients/bestillingClient";
import { hentKunde } from "@/clients/kundeClient";
import { hentLager } from "@/clients/lagerClient";
import { IBestilling, IKunde, ILager, ITransportRute } from "@/types";
import { formaterKlokkeslett } from "@/utils";
import { Printd } from "printd";
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    bestillingId: {
      type: Number,
      required: true,
    },
  },

  async setup(props) {
    const bestilling = ref<IBestilling>();
    const lager = ref<ILager>();
    const kunde = ref<IKunde>();
    const transportRute = ref<ITransportRute | undefined>();
    const printContainer = ref<null | HTMLElement>(null);
    const printd = new Printd();

    [bestilling.value, transportRute.value] = await Promise.all([
      hentBestilling(props.bestillingId),
      hentRuteForBestilling(props.bestillingId),
    ]);

    [lager.value, kunde.value] = await Promise.all([
      hentLager(bestilling.value.lager),
      hentKunde(bestilling.value.kundeNr),
    ]);

    const avganger = computed(
      () =>
        transportRute.value?.avganger.map((avgang) => formaterKlokkeslett(avgang.avgangTidspunkt)).join(", ") ??
        "Fant ingen avganger"
    );

    const print = () => {
      if (!printContainer.value) {
        return;
      }

      printd.print(printContainer.value, [cssText]);
    };

    setTimeout(print, 200);

    return { bestilling, lager, transportRute, kunde, avganger, printContainer, print };
  },
});

const cssText = `
/* * * * * * * * * * * * * * * * * * * * * * */
/*             Lim inn under her             */
/* * * * * * * * * * * * * * * * * * * * * * */

.print-container {
  font-size: 11px;
}

tr {
  box-sizing: border-box;
  width: 350px;
  border: 1px solid black;
  padding: 0.5em 1em;
}

label {
  font-weight: bold;
}

.avsender {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
}

.rute {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
}

.mottaker {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  font-size: 1.4em;
  padding: 2em;
}

.kolli {
  display: flex;
  gap: 1em;
  min-height: 200px;
  align-items: stretch;
}

input {
  width: 100%;
  border: 1px solid black;
}

.kolli > * {
  display: flex;
  flex-direction: column;
  width: 10px;
}

.kolli .dato {
  flex-grow: 1;
}

.kolli .kolli-info {
  flex-grow: 3;
}

.kolli .vekt {
  flex-grow: 1;
}
`;
</script>

<style lang="css" scoped>
.print-container {
  margin-top: 1rem;
  border: 1px solid black;
  padding: 1rem;
  min-height: 30rem;
}

input {
  background: initial;
  border: 1px solid black;
  padding: initial;
}

/* * * * * * * * * * * * * * * * * * * * * * */
/*  Styles under her må kopieres til cssText */
/* * * * * * * * * * * * * * * * * * * * * * */

.print-container {
  font-size: 11px;
}

tr {
  box-sizing: border-box;
  width: 350px;
  border: 1px solid black;
  padding: 0.5em 1em;
}

label {
  font-weight: bold;
}

.avsender {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
}

.rute {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
}

.mottaker {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  font-size: 1.4em;
  padding: 2em;
}

.kolli {
  display: flex;
  gap: 1em;
  min-height: 200px;
  align-items: stretch;
}

input {
  width: 100%;
  border: 1px solid black;
}

.kolli > * {
  display: flex;
  flex-direction: column;
  width: 10px;
}

.kolli .dato {
  flex-grow: 1;
}

.kolli .kolli-info {
  flex-grow: 3;
}

.kolli .vekt {
  flex-grow: 1;
}
</style>
