<template>
  <div>
    <h2>Automatisk import</h2>
    Automatisk import: {{ automatiskImport ? "Aktivert" : "Deaktivert" }}
    <Button @click="settAutomatiskImport(!automatiskImport)">{{ automatiskImport ? "Deaktiver" : "Aktiver" }}</Button>

    <h2>Manuell import</h2>
    <Import />

    <h2>Mailtester</h2>
    <MailTester />

    <h2>SMS tester</h2>
    <SmsTester/>
  </div>
</template>

<script lang="ts">
import { hentAutomatiskImportVerdi, settAutomatiskImport as postAutomatiskImport } from "@/clients/configClient";
import { defineComponent, ref } from "vue";
import { Import, MailTester, SmsTester } from "@/components";

export default defineComponent({
  props: {},

  setup() {
    const automatiskImport = ref(false);

    hentAutomatiskImportVerdi().then((result) => (automatiskImport.value = result));

    const importerData = async () => {
      await importerData();
      alert("Import ferdig");
    };

    const settAutomatiskImport = async (verdi: boolean) => {
      await postAutomatiskImport(verdi);
      automatiskImport.value = verdi;
    };

    return { importerData, automatiskImport, settAutomatiskImport };
  },

  components: {
    SmsTester,
    Import,
    MailTester,
  },
});
</script>

<style lang="less" scoped>
h2:not(:first-child) {
  margin-top: 5rem;
}
</style>
