import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "kunde-bulk-knapper"
}
const _hoisted_3 = { class: "vis-alle-kunder-wrapper" }
const _hoisted_4 = {
  key: 2,
  class: "spinner-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LagerVelger = _resolveComponent("LagerVelger")!
  const _component_ButtonRouterLink = _resolveComponent("ButtonRouterLink")!
  const _component_Button = _resolveComponent("Button")!
  const _component_LagerStatusFilter = _resolveComponent("LagerStatusFilter")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_ApneBestillinger = _resolveComponent("ApneBestillinger")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isAdmin || _ctx.isKundesenter)
      ? (_openBlock(), _createBlock(_component_LagerVelger, {
          key: 0,
          modelValue: _ctx.valgtLager,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valgtLager) = $event))
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    _createElementVNode("h1", null, [
      _cache[8] || (_cache[8] = _createTextVNode(" Åpne bestillinger ")),
      (_ctx.kunde)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, "for kunde " + _toDisplayString(_ctx.kunde), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.lastet && _ctx.kunde)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, [
            _createVNode(_component_ButtonRouterLink, { to: "/apne-bestillinger" }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode(" Vis alle kunder")
              ])),
              _: 1
            })
          ]),
          (
          [..._ctx.bestillinger, ..._ctx.restordre].some((bestilling) => bestilling.status === _ctx.BestillingStatus.underPlukking)
        )
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                class: "btn-avbryt-plukking-kunde",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.håndterAvbrytPlukkingForKunde()))
              }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createElementVNode("i", { class: "las la-times-circle" }, null, -1),
                  _createTextVNode(" Avbryt plukking for valgt kunde ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          ([..._ctx.bestillinger, ..._ctx.restordre].some((bestilling) => bestilling.status === _ctx.BestillingStatus.åpen))
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 1,
                class: "btn-start-plukking-kunde",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.håndterStartPlukkingForKunde()))
              }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createElementVNode("i", { class: "las la-dolly" }, null, -1),
                  _createTextVNode(" Start plukking for valgt kunde ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_LagerStatusFilter, {
      class: "lager-status-filter",
      modelValue: _ctx.lagerStatus,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.lagerStatus) = $event)),
      onHent: _cache[4] || (_cache[4] = ($event: any) => (_ctx.hentBestillinger()))
    }, null, 8, ["modelValue"]),
    (!_ctx.lastet && _ctx.valgtLager)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_Spinner)
        ]))
      : _createCommentVNode("", true),
    (_ctx.lastet)
      ? (_openBlock(), _createBlock(_component_ApneBestillinger, {
          key: 3,
          class: "bestilling-liste",
          bestillinger: _ctx.bestillinger,
          restordre: _ctx.restordre,
          onFjernBestilling: _cache[5] || (_cache[5] = ($event: any) => {
        _ctx.bestillinger = _ctx.bestillinger.filter((x) => x !== $event);
        _ctx.restordre = _ctx.restordre.filter((x) => x !== $event);
      }),
          onStartPlukking: _cache[6] || (_cache[6] = ($event: any) => (_ctx.håndterStartPlukking($event))),
          onAvbrytPlukking: _cache[7] || (_cache[7] = ($event: any) => (_ctx.håndterAvbrytPlukking($event)))
        }, null, 8, ["bestillinger", "restordre"]))
      : _createCommentVNode("", true)
  ]))
}