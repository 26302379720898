export const validerPassord = (passord: string): [gyldig: boolean, feil: string[]] => {
  const errors: string[] = [];

  if (passord.length < 6) {
    errors.push("Passordet må inneholde minst 6 tegn");
  }

  const lowerCase = /[a-zæøå]/g;
  if (!passord.match(lowerCase)) {
    errors.push("Passordet må inneholde minst én liten bokstav");
  }

  const upperCase = /[A-ZÆØÅ]/g;
  if (!passord.match(upperCase)) {
    errors.push("Passordet må inneholde minst én stor bokstav");
  }

  const numbers = /[0-9]/g;
  if (!passord.match(numbers)) {
    errors.push("Passordet må inneholde minst ett tall");
  }

  const nonAlphaNumeric = /[^a-zA-Z\d\s:]/g;
  if (!passord.match(nonAlphaNumeric)) {
    errors.push("Passordet må inneholde minst ett spesialtegn");
  }

  return [errors.length === 0, errors];
};
