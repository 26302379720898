<template>
  <div class="home">
    <h1>Bilglassgruppen sin reserveløsning</h1>
  </div>
</template>

<script lang="ts">
export default {
  props: {},
};
</script>

<style lang="less" scoped></style>
