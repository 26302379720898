import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "rediger-bruker" }
const _hoisted_2 = {
  key: 0,
  class: "errors"
}
const _hoisted_3 = { class: "input-gruppe" }
const _hoisted_4 = { class: "input-gruppe" }
const _hoisted_5 = { class: "input-gruppe" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CenteredSpinner = _resolveComponent("CenteredSpinner")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[9] || (_cache[9] = _createElementVNode("h1", null, "Rediger bruker", -1)),
    (_ctx.laster || _ctx.lagrer)
      ? (_openBlock(), _createBlock(_component_CenteredSpinner, { key: 0 }))
      : (_openBlock(), _createElementBlock("form", {
          key: 1,
          class: "form",
          onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.lagre()), ["prevent"]))
        }, [
          (_ctx.errors.length)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
                  return (_openBlock(), _createElementBlock("li", { key: error }, _toDisplayString(error), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _cache[5] || (_cache[5] = _createElementVNode("label", { for: "brukernavn" }, "Brukernavn", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              id: "brukernavn",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.bruker.brukerNavn) = $event)),
              disabled: ""
            }, null, 512), [
              [_vModelText, _ctx.bruker.brukerNavn]
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _cache[6] || (_cache[6] = _createElementVNode("label", { for: "lager" }, "Lager", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              id: "lager",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.bruker.lager) = $event))
            }, null, 512), [
              [_vModelText, _ctx.bruker.lager]
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[7] || (_cache[7] = _createElementVNode("label", { for: "kundeNr" }, "Kundenummer", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              id: "kundeNr",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.bruker.kundeNr) = $event))
            }, null, 512), [
              [_vModelText, _ctx.bruker.kundeNr]
            ])
          ]),
          _createVNode(_component_Button, {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.lagre())),
            type: "primary",
            disabled: _ctx.lagrer
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode(" Lagre ")
            ])),
            _: 1
          }, 8, ["disabled"])
        ], 32))
  ]))
}