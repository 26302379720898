import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "driftsetting" }
const _hoisted_2 = {
  id: "steg1",
  class: "steg"
}
const _hoisted_3 = { class: "verktøy" }
const _hoisted_4 = {
  id: "steg2",
  class: "steg"
}
const _hoisted_5 = { class: "verktøy" }
const _hoisted_6 = {
  id: "steg3",
  class: "steg"
}
const _hoisted_7 = { class: "verktøy" }
const _hoisted_8 = {
  id: "steg4",
  class: "steg"
}
const _hoisted_9 = { class: "verktøy" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Import = _resolveComponent("Import")!
  const _component_OpprettTokensForAlleKunder = _resolveComponent("OpprettTokensForAlleKunder")!
  const _component_ButtonRouterLink = _resolveComponent("ButtonRouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[19] || (_cache[19] = _createElementVNode("div", { class: "steg" }, [
      _createElementVNode("a", {
        href: "#steg1",
        class: "neste-steg"
      }, [
        _createElementVNode("i", { class: "las la-angle-double-down" })
      ]),
      _createElementVNode("h1", null, "Driftsetting av reserveløsningen"),
      _createElementVNode("p", null, "Nedenfor er den stegvise prosessen for å gjøre løsningen driftsklar i en nødssituasjon beskrevet.")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("a", {
        href: "#steg2",
        class: "neste-steg"
      }, [
        _createElementVNode("i", { class: "las la-angle-double-down" })
      ], -1)),
      _cache[5] || (_cache[5] = _createElementVNode("h2", null, "Steg 1: Stans automatisk import av data", -1)),
      _cache[6] || (_cache[6] = _createElementVNode("p", null, " I en normalsituasjon importerer reserveløsningen oppdaterte data jevnlig. Ved overgang til reservedrift må denne importen stanses slik at dataene blir statiske, og dermed kan brukes til bestilling av varer, lagerstyring osv. ", -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[2] || (_cache[2] = _createTextVNode(" Automatisk import er ")),
        _createElementVNode("strong", null, _toDisplayString(_ctx.automatiskImportAktivert ? "aktivert" : "deaktivert"), 1),
        _cache[3] || (_cache[3] = _createTextVNode(". ")),
        (_ctx.automatiskImportAktivert)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.settAutomatiskImport(false)))
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Deaktiver")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[7] || (_cache[7] = _createElementVNode("a", {
        href: "#steg3",
        class: "neste-steg"
      }, [
        _createElementVNode("i", { class: "las la-angle-double-down" })
      ], -1)),
      _cache[8] || (_cache[8] = _createElementVNode("h2", null, "Steg 2: Last inn nyeste gyldige data til reserveløsningen", -1)),
      _cache[9] || (_cache[9] = _createElementVNode("p", null, " Som beskrevet i steg 1 importerer reserveløsningen oppdaterte data jevnlig. I et krisetilfelle kan det være at nyeste data ikke er gyldige, og at en eldre backup av data må benyttes. Hvis de nyeste dataene er gyldige kan man hoppe over dette steget. ", -1)),
      _cache[10] || (_cache[10] = _createElementVNode("p", null, " Vær oppmerksom på at importprosessen tar lang tid – normalt sett opp mot 10 minutter. Hvis du blir værende på siden, vil du få et varsel når import er ferdig. ", -1)),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Import)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[11] || (_cache[11] = _createElementVNode("a", {
        href: "#steg4",
        class: "neste-steg"
      }, [
        _createElementVNode("i", { class: "las la-angle-double-down" })
      ], -1)),
      _cache[12] || (_cache[12] = _createElementVNode("h2", null, "Steg 3: Opprett brukere for alle aktive kunder", -1)),
      _cache[13] || (_cache[13] = _createElementVNode("p", null, " Vi må opprette brukere for alle aktive kunder av Bilglassgruppen. Verktøyet under lar deg automatisk opprette og sende ut lenker til alle kunder, som igjen lar kundene selv registrere brukere. Lenkene er gyldige i 14 dager, og ved registrering vil brukerene automatisk knyttes til riktig kunde, slik at de kan legge inn bestillinger så fort brukeren er opprettet og de har logget inn. ", -1)),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_OpprettTokensForAlleKunder)
      ]),
      _cache[14] || (_cache[14] = _createElementVNode("p", null, " Ved behov kan nye lenker for enkeltkunder opprettes ved å bruke verktøyet under \"Admin\" -> \"Brukere\" -> \"Opprett registreringstoken\". ", -1))
    ]),
    _createElementVNode("div", _hoisted_8, [
      _cache[16] || (_cache[16] = _createElementVNode("a", {
        href: "#ferdig",
        class: "neste-steg"
      }, [
        _createElementVNode("i", { class: "las la-angle-double-down" })
      ], -1)),
      _cache[17] || (_cache[17] = _createElementVNode("h2", null, "Steg 4: Opprett brukere for lager- og kundesenter-medarbeidere", -1)),
      _cache[18] || (_cache[18] = _createElementVNode("p", null, " Brukere for ansatte i Bilglassgruppen som jobber på lager eller kundesenter må opprettes manuelt – enten ved å lage tokens som beskrevet over uten å velge en kundetilknytning, eller ved å bruke verktøyet under \"Admin\" -> \"Brukere\" -> \"Opprett ny bruker\". Etter at brukeren er opprettet må riktig roller settes fra brukerlisten. Brukeren vil så ha riktige privilegier etter å ha logget ut og inn igjen. ", -1)),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_ButtonRouterLink, { to: "/brukere" }, {
          default: _withCtx(() => _cache[15] || (_cache[15] = [
            _createTextVNode("Gå til brukeradministrasjon")
          ])),
          _: 1
        })
      ])
    ]),
    _cache[20] || (_cache[20] = _createElementVNode("div", {
      id: "ferdig",
      class: "steg"
    }, [
      _createElementVNode("h2", null, "Løsningen er driftsklar"),
      _createElementVNode("p", null, "Etter at alle stegene ovenfor er gjennomført er løsningen klar til drift.")
    ], -1))
  ]))
}