import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "right lagerstatus-celle" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { key: 3 }
const _hoisted_10 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LagerStatusIkon = _resolveComponent("LagerStatusIkon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ButtonRouterLink = _resolveComponent("ButtonRouterLink")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("table", null, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        _cache[0] || (_cache[0] = _createElementVNode("th", null, null, -1)),
        _cache[1] || (_cache[1] = _createElementVNode("th", null, "Id", -1)),
        (_ctx.restordre)
          ? (_openBlock(), _createElementBlock("th", _hoisted_1, "Ordrenummer"))
          : _createCommentVNode("", true),
        _cache[2] || (_cache[2] = _createElementVNode("th", null, "Kunde", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("th", null, "Status", -1)),
        _cache[4] || (_cache[4] = _createElementVNode("th", null, "Rute", -1)),
        _cache[5] || (_cache[5] = _createElementVNode("th", null, "Avgangstider", -1)),
        (_ctx.restordre)
          ? (_openBlock(), _createElementBlock("th", _hoisted_2, "Ønsket leveringsdato"))
          : _createCommentVNode("", true),
        _cache[6] || (_cache[6] = _createElementVNode("th", null, null, -1))
      ])
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bestillinger, (bestilling) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: bestilling.id
        }, [
          _createElementVNode("td", _hoisted_3, [
            _createElementVNode("button", {
              onClick: ($event: any) => (_ctx.$emit('vis-detaljer', bestilling)),
              class: "lagerstatus-knapp"
            }, [
              _createVNode(_component_LagerStatusIkon, {
                lagerStatus: bestilling.lagerStatus
              }, null, 8, ["lagerStatus"])
            ], 8, _hoisted_4)
          ]),
          _createElementVNode("td", null, _toDisplayString(bestilling.id), 1),
          (_ctx.restordre)
            ? (_openBlock(), _createElementBlock("td", _hoisted_5, _toDisplayString(bestilling.ordreNummer), 1))
            : _createCommentVNode("", true),
          _createElementVNode("td", null, [
            _createVNode(_component_router_link, {
              to: { path: '/apne-bestillinger', query: { kunde: bestilling.kundeNr } }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(bestilling.kundeNr) + " ", 1),
                (bestilling.kunde.navn)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, " - " + _toDisplayString(bestilling.kunde.navn), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _createElementVNode("td", {
            class: _normalizeClass({ 'status-under-plukking': bestilling.status === _ctx.BestillingStatus.underPlukking })
          }, _toDisplayString(_ctx.bestillingStatusTilTekst(bestilling.status)), 3),
          _createElementVNode("td", null, _toDisplayString(bestilling.rute.beskrivelse), 1),
          (bestilling.rute.avganger.length)
            ? (_openBlock(), _createElementBlock("td", _hoisted_7, _toDisplayString(_ctx.lagAvgangsstreng(bestilling)), 1))
            : (_openBlock(), _createElementBlock("td", _hoisted_8, "Fant ingen avganger")),
          (_ctx.restordre)
            ? (_openBlock(), _createElementBlock("td", _hoisted_9, _toDisplayString(_ctx.formatterDato(bestilling.ønsketLeveringsDato)), 1))
            : _createCommentVNode("", true),
          _createElementVNode("td", null, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_ButtonRouterLink, {
                to: `/bestillinger/${bestilling.id}/plukkliste`,
                title: "Skriv ut plukkliste"
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createElementVNode("i", { class: "las la-clipboard-list" }, null, -1)
                ])),
                _: 2
              }, 1032, ["to"]),
              _createVNode(_component_ButtonRouterLink, {
                to: `/bestillinger/${bestilling.id}/fraktetikett`,
                title: "Skriv ut fraktetikett"
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createElementVNode("i", { class: "las la-truck" }, null, -1)
                ])),
                _: 2
              }, 1032, ["to"]),
              (bestilling.status === _ctx.BestillingStatus.åpen)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    class: "btn-start-plukking",
                    onClick: ($event: any) => (_ctx.$emit('start-plukking', bestilling)),
                    disabled: bestilling.linjer.length === 0,
                    title: bestilling.linjer.length === 0 ? 'Ingen linjer å plukke' : 'Start plukking'
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createElementVNode("i", { class: "las la-dolly" }, null, -1)
                    ])),
                    _: 2
                  }, 1032, ["onClick", "disabled", "title"]))
                : _createCommentVNode("", true),
              (bestilling.status === _ctx.BestillingStatus.underPlukking)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 1,
                    class: "btn-avbryt-plukking",
                    onClick: ($event: any) => (_ctx.$emit('avbryt-plukking', bestilling)),
                    disabled: bestilling.linjer.length === 0,
                    title: "Avbryt plukking"
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createElementVNode("i", { class: "las la-times-circle" }, null, -1)
                    ])),
                    _: 2
                  }, 1032, ["onClick", "disabled"]))
                : _createCommentVNode("", true),
              (bestilling.status === _ctx.BestillingStatus.underPlukking)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 2,
                    class: "btn-bekreft-plukking",
                    onClick: ($event: any) => (_ctx.$emit('vis-detaljer', bestilling)),
                    disabled: bestilling.linjer.length === 0,
                    title: bestilling.linjer.length === 0 ? 'Ingen linjer å plukke' : 'Bekreft plukking'
                  }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createElementVNode("i", { class: "las la-check-circle" }, null, -1)
                    ])),
                    _: 2
                  }, 1032, ["onClick", "disabled", "title"]))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}