<template>
  <CenteredSpinner v-if="laster" />
  <RuteVelger v-else :ruter="limArtikler" @velg-rute="velgArtikkel" />
</template>

<script setup lang="ts">
import { IArtikkel } from "@/types";
import { ref } from "vue";
import { hentLimArtikler } from "@/clients/artikkelClient";
import RuteVelger from "./RuteVelger.vue";

interface IEmits {
  (e: "legg-til-artikkel", artikkel: IArtikkel): void;
}
const emit = defineEmits<IEmits>();

const laster = ref(false);
const limArtikler = ref<IArtikkel[]>([]);

const lastArtikler = async () => {
  laster.value = true;
  limArtikler.value = await hentLimArtikler();
  laster.value = false;
};
lastArtikler();

const velgArtikkel = (artikkel: IArtikkel) => {
  emit("legg-til-artikkel", artikkel);
};
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
</style>
