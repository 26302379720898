<template>
  <div>
    <h1>Opprett bruker</h1>
    <RegistrerBruker :token="token" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import RegistrerBruker from "@/components/bruker/RegistrerBruker.vue";

export default defineComponent({
  props: {
    token: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {};
  },

  components: { RegistrerBruker },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
</style>
