<template>
  <div v-if="lastet">
    <Modal :aktiv="visTokenDialog" @lukk="visTokenDialog = false">
      <OpprettToken />
    </Modal>

    <div class="knapper">
      <Button @click="visTokenDialog = true">Opprett registreringstoken</Button>
      <button-router-link to="/brukere/ny">Opprett ny bruker</button-router-link>
    </div>
    <div class="list-container">
      <BrukerListe v-if="lastet" :brukere="brukere" />
    </div>
  </div>
  <div v-else class="spinner-container">
    <Spinner />
  </div>
</template>

<script lang="ts">
import { IBruker } from "@/types";
import { defineComponent, ref } from "vue";
import { hentBrukere } from "@/clients/brukerClient";
import { BrukerListe } from "@/components";
import Spinner from "@/components/_basis/Spinner.vue";
import ButtonRouterLink from "@/components/_basis/ButtonRouterLink.vue";
import OpprettToken from "@/components/admin/OpprettToken.vue";

export default defineComponent({
  props: {},

  setup() {
    const brukere = ref<IBruker[]>([]);
    const lastet = ref(false);
    const visTokenDialog = ref(false);

    hentBrukere().then((result) => {
      brukere.value = result;
      lastet.value = true;
    });

    return { brukere, lastet, visTokenDialog };
  },

  components: {
    BrukerListe,
    Spinner,
    ButtonRouterLink,
    OpprettToken,
  },
});
</script>

<style lang="less" scoped>
.spinner-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.knapper {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.list-container {
  margin-top: 1rem;
}
</style>
