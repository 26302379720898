<template>
  <form class="registrer-bruker" v-if="!lagrer && tokenSjekket && tokenGyldig" @submit.prevent="registrer">
    <ul v-if="errors.length" class="errors">
      <li v-for="error in errors" :key="error">{{ error }}</li>
    </ul>
    <div class="input-gruppe">
      <label for="brukernavn">Brukernavn</label>
      <input type="text" v-model="brukernavn" id="brukernavn" />
    </div>
    <div class="input-gruppe">
      <label for="passord">Passord</label>
      <input type="password" v-model="passord" id="passord" />
    </div>
    <div class="input-gruppe">
      <label for="gjentaPassord">Gjenta passord</label>
      <input type="password" v-model="gjentaPassord" id="gjentaPassord" />
    </div>
    <Button type="primary" @click="registrer">Registrer</Button>
  </form>
  <div v-else-if="!tokenGyldig">
    Lenken du har fulgt for å opprette bruker er ikke gyldig. Ta kontakt med kundesenteret dersom du mener dette er en
    feil.
  </div>
  <div v-else class="spinner-wrapper">
    <Spinner />
  </div>
</template>

<script lang="ts">
import { registrerBrukerMedToken, validerToken } from "@/clients/brukerClient";
import { validerPassord } from "@/utils";
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {
    token: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const brukernavn = ref("");
    const passord = ref("");
    const gjentaPassord = ref("");
    const lagrer = ref(false);
    const tokenSjekket = ref(false);
    const tokenGyldig = ref(false);
    const errors = ref<string[]>([]);

    const router = useRouter();

    validerToken(props.token).then((resultat) => {
      tokenSjekket.value = true;
      tokenGyldig.value = resultat;
    });

    const registrer = async () => {
      lagrer.value = true;
      errors.value = [];

      const validationErrors = [];

      if (!brukernavn.value) {
        validationErrors.push("Brukernavn er påkrevd");
      }

      const passordFeil = validerPassord(passord.value)[1];
      validationErrors.push(...passordFeil);

      if (passord.value !== gjentaPassord.value) {
        validationErrors.push("Passordene må være like");
      }

      if (validationErrors.length) {
        errors.value = validationErrors;
        lagrer.value = false;
        return;
      }

      try {
        await registrerBrukerMedToken(props.token, brukernavn.value, passord.value);
      } finally {
        lagrer.value = false;
      }
      router.push("/");
    };

    return { brukernavn, passord, gjentaPassord, lagrer, registrer, errors, tokenSjekket, tokenGyldig };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.registrer-bruker {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.input-gruppe {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
}

.errors {
  list-style: none;
  color: @red;
  margin: 0;
  padding: 0;
}
</style>
