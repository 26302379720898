import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LagerVelger = _resolveComponent("LagerVelger")!
  const _component_ArtikkelInfo = _resolveComponent("ArtikkelInfo")!
  const _component_SaldoListe = _resolveComponent("SaldoListe")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isAdmin || _ctx.isKundesenter)
      ? (_openBlock(), _createBlock(_component_LagerVelger, {
          key: 0,
          modelValue: _ctx.valgtLager,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valgtLager) = $event))
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    _cache[1] || (_cache[1] = _createElementVNode("h2", null, "Artikkelinfo", -1)),
    _createVNode(_component_ArtikkelInfo, {
      artikkelNr: _ctx.artikkelNr,
      lager: _ctx.valgtLager
    }, null, 8, ["artikkelNr", "lager"]),
    _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "title-lagerstatus" }, "Lagerstatus", -1)),
    _createVNode(_component_SaldoListe, {
      lager: _ctx.valgtLager,
      artikkelNr: _ctx.artikkelNr
    }, null, 8, ["lager", "artikkelNr"])
  ]))
}