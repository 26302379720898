<template>
  <div>
    <input type="text" v-model="filterValue" placeholder="Søk etter brukernavn, kundenummer eller lager" />
    <table class="bruker-tabell">
      <thead>
        <tr>
          <th>Brukernavn</th>
          <th>Kunde</th>
          <th>Lager</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="bruker in filtrerteBrukere" :key="bruker.id" @click="velgBruker(bruker)" class="bruker-rad">
          <td>
            {{ bruker.brukerNavn }}
          </td>
          <td>
            {{ bruker.kundeNr }}
          </td>
          <td>
            {{ bruker.lager }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { IBruker } from "@/types";
import { computed, defineComponent, PropType, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {
    brukere: {
      type: Array as PropType<IBruker[]>,
      required: true,
    },
  },

  setup(props) {
    const filterValue = ref("");

    const filtrerteBrukere = computed((): IBruker[] => {
      const regex = new RegExp(filterValue.value, "i");
      return props.brukere.filter(
        (bruker) => regex.test(bruker.brukerNavn) || regex.test(bruker.kundeNr) || regex.test(bruker.lager)
      );
    });

    const router = useRouter();

    const velgBruker = (bruker: IBruker) => {
      router.push(`/brukere/${bruker.id}`);
    };

    return { filterValue, filtrerteBrukere, velgBruker };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

input {
  width: calc(100% - 1.4rem);
  padding: 0.7rem;
  font-size: 0.8rem;
}

.bruker-tabell {
  width: 100%;
}

.bruker-rad {
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}
</style>
