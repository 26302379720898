<template>
  <div class="bestilling-view">
    <KundeVelger
      v-if="isAdmin || isKundesenter"
      v-show="!senderBestilling && !bestillingFullført"
      v-model="valgtKunde"
    />

    <div
      v-if="!senderBestilling && !bestillingFullført && (!(isAdmin || isKundesenter) || valgtKunde)"
      class="bestilling-content"
    >
      <div>
        <h2 @click="aktivVelger = 'regnr'">
          Søk etter bil
          <span class="caret" :class="{ 'caret-down': aktivVelger === 'regnr' }">❯</span>
        </h2>
        <RuteFinnerRegNr v-if="aktivVelger === 'regnr'" @legg-til-artikkel="leggTilArtikkel" />

        <h2 @click="aktivVelger = 'årsmodell'">
          Velg bilmodell
          <span class="caret" :class="{ 'caret-down': aktivVelger === 'årsmodell' }">❯</span>
        </h2>
        <RuteFinnerModell v-if="aktivVelger === 'årsmodell'" @legg-til-artikkel="leggTilArtikkel" />

        <h2 @click="aktivVelger = 'lim'">
          Lim
          <span class="caret" :class="{ 'caret-down': aktivVelger === 'lim' }">❯</span>
        </h2>
        <LimArtikler v-if="aktivVelger === 'lim'" @legg-til-artikkel="leggTilArtikkel" />

        <h2 @click="aktivVelger = 'verktøy'">
          Verktøy
          <span class="caret" :class="{ 'caret-down': aktivVelger === 'verktøy' }">❯</span>
        </h2>
        <VerktøyFinner v-if="aktivVelger == 'verktøy'" @legg-til-artikkel="leggTilArtikkel" />

        <h2 @click="aktivVelger = 'artikkelnummer'">
          Søk etter artikkelnummer
          <span class="caret" :class="{ 'caret-down': aktivVelger === 'artikkelnummer' }">❯</span>
        </h2>
        <ArtikkelVelgerArtikkelNr v-if="aktivVelger === 'artikkelnummer'" @legg-til-artikkel="leggTilArtikkel" />
      </div>

      <Handlekurv
        :linjer="handlekurv"
        :valgtKunde="valgtKunde"
        class="handlekurv"
        @opprett-bestilling="opprettBestilling"
        @slett-linje="slettLinje"
      />
    </div>
    <div class="sender-bestilling-wrapper" v-else-if="senderBestilling">
      <Spinner />
      <h3>Sender bestilling</h3>
    </div>
    <div v-else>
      <h3>Bestilling fullført</h3>
      <Button @click="nullstill()">Legg inn ny bestilling</Button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { RuteFinnerModell, RuteFinnerRegNr, ArtikkelVelgerArtikkelNr, Handlekurv, KundeVelger } from "@/components";
import { IArtikkel, IHandlekurvLinje } from "@/types";
import { hentArtikkel, hentFølgeArtikler } from "@/clients/artikkelClient";
import authService from "@/utils/auth/authService";
import { opprettBestillingForHandlekurv } from "@/clients/bestillingClient";
import { onBeforeRouteLeave } from "vue-router";
import { toast, toastError } from "@/utils";
import LimArtikler from "@/components/kunde/LimArtikler.vue";
import VerktøyFinner from "@/components/kunde/VerktøyFinner.vue";

type VelgerType = "regnr" | "årsmodell" | "artikkelnummer" | "lim" | "verktøy";

export default defineComponent({
  props: {
    leggTilArtikkelNr: {
      type: String,
      required: false,
    },
    leggTilArtikkelMerknad: {
      type: String,
      required: false,
    },
  },

  setup(props) {
    const handlekurv = ref<IHandlekurvLinje[]>([]);
    const aktivVelger = ref<VelgerType>("regnr");
    const valgtKunde = ref("");

    const senderBestilling = ref(false);
    const bestillingFullført = ref(false);

    const isKundesenter = ref(false);
    const isAdmin = ref(false);
    authService.hasRole("Admin").then((result) => (isAdmin.value = result));
    authService.hasRole("Kundesenter").then((result) => (isKundesenter.value = result));

    const leggTilArtikkel = async (artikkel: IArtikkel, merknad = "") => {
      const linjeId = handlekurv.value.length ? Math.max(...handlekurv.value.map((x) => x.linjeId)) + 1 : 1;
      const følgeartikler = await hentFølgeArtikler(artikkel);

      handlekurv.value = [
        ...handlekurv.value,
        {
          linjeId,
          artikkel,
          antall: 1,
          følgeartikler: følgeartikler,
          merknad: merknad,
        },
      ];
    };

    const opprettBestilling = async () => {
      const startTime = Date.now();
      senderBestilling.value = true;
      try {
        if (isAdmin.value || isKundesenter.value) {
          await opprettBestillingForHandlekurv(handlekurv.value, valgtKunde.value);
        } else {
          await opprettBestillingForHandlekurv(handlekurv.value);
        }
      } catch (e) {
        senderBestilling.value = false;
        bestillingFullført.value = false;
        throw e;
      }

      const elapsedTime = Date.now() - startTime;

      setTimeout(() => {
        senderBestilling.value = false;
        bestillingFullført.value = true;
        handlekurv.value = [];
      }, Math.max(0, 1000 - elapsedTime));
    };

    const slettLinje = (linje: IHandlekurvLinje): void => {
      handlekurv.value = handlekurv.value.filter((l) => l !== linje);
    };

    const nullstill = () => {
      senderBestilling.value = false;
      bestillingFullført.value = false;
      handlekurv.value = [];
      aktivVelger.value = "regnr";
    };

    onBeforeRouteLeave((to, from, next) => {
      if (
        handlekurv.value.length === 0 ||
        confirm("Du har varer i handlekurven som ikke er bestilt. Vil du likevel navigere bort fra siden?")
      ) {
        next();
      }
    });

    if (props.leggTilArtikkelNr) {
      const leggTilArtikkelMedArtikkelNr = async () => {
        const søkeResultat = await hentArtikkel(props.leggTilArtikkelNr as string);
        if (søkeResultat) {
          leggTilArtikkel(søkeResultat, props.leggTilArtikkelMerknad ?? "");
          toast(`La til artikkel med artikkelnummer ${props.leggTilArtikkelNr} i bestillingen`);
        } else {
          toastError(`Fant ingen artikkel med artikkelnummer ${props.leggTilArtikkelNr}`);
        }
      };
      leggTilArtikkelMedArtikkelNr();
    }

    return {
      leggTilArtikkel,
      handlekurv,
      aktivVelger,
      valgtKunde,
      isAdmin,
      isKundesenter,
      nullstill,
      senderBestilling,
      bestillingFullført,
      opprettBestilling,
      slettLinje,
    };
  },

  components: {
    RuteFinnerModell,
    RuteFinnerRegNr,
    ArtikkelVelgerArtikkelNr,
    Handlekurv,
    KundeVelger,
    LimArtikler,
    VerktøyFinner,
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.bestilling-content {
  width: 100%;
  display: flex;
  gap: 3.5rem;

  & > * {
    width: calc(50% - 1.25rem);
  }
}

.handlekurv {
  position: sticky;
  top: 0;
  align-self: flex-start;
}

h2 {
  border-bottom: 1px solid @black80;
  padding: 0.5rem 0;
  cursor: pointer;
}

.caret {
  margin-left: 0.5rem;
  display: inline-block;
  transition: transform 0.1s;
}

.caret-down {
  transform: rotate(90deg);
}

.sender-bestilling-wrapper {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

@media @mobile {
  .bestilling-content {
    flex-direction: column;

    & > * {
      width: 100%;
    }
  }
}
</style>
