<template>
  <div>
    <Modal :aktiv="aktivModal === 'flytt'" @lukk="aktivModal = null">
      <FlyttArtikler @lukk="håndterModalLukk($event)" :lokasjoner="saldo" :artikkelNr="artikkelNr" :lager="lager" />
    </Modal>
    <Modal :aktiv="aktivModal === 'fyll-på'" @lukk="aktivModal = null">
      <FyllPåArtikler @lukk="håndterModalLukk($event)" :lokasjoner="saldo" :artikkelNr="artikkelNr" :lager="lager" />
    </Modal>
    <Modal :aktiv="aktivModal === 'opprett-lokasjon'" @lukk="aktivModal = null">
      <OpprettLokasjon @lukk="håndterModalLukk($event)" :artikkelNr="artikkelNr" :lager="lager" />
    </Modal>

    <CenteredSpinner v-if="!lastet" />
    <div v-else>
      <div class="button-row">
        <Button @click="aktivModal = 'flytt'">Flytt artikler</Button>
        <Button @click="aktivModal = 'fyll-på'">Fyll på artikler</Button>
        <Button @click="aktivModal = 'opprett-lokasjon'">Legg til lokasjon</Button>
      </div>
      <table class="saldo-liste">
        <thead>
          <th>Lokasjon</th>
          <th>Lagerført saldo</th>
          <th>Allokert antall</th>
          <th>Disponibel saldo</th>
        </thead>
        <tbody>
          <tr v-for="lokasjon in saldo" :key="lokasjon.lokasjon">
            <td>{{ lokasjon.lokasjon }}</td>
            <td>{{ lokasjon.saldo }}</td>
            <td>{{ lokasjon.allokert }}</td>
            <td>{{ lokasjon.saldo - lokasjon.allokert }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { hentLagerSaldo } from "@/clients/artikkelClient";
import { ILagerSaldo } from "@/types";
import { defineComponent, ref, watchEffect } from "vue";
import FlyttArtikler from "./FlyttArtikler.vue";
import FyllPåArtikler from "./FyllPåArtikler.vue";
import OpprettLokasjon from "./OpprettLokasjon.vue";

type AktivModal = "fyll-på" | "opprett-lokasjon" | "flytt" | null;

export default defineComponent({
  props: {
    lager: {
      type: String,
      required: true,
    },
    artikkelNr: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const aktivModal = ref<AktivModal>(null);

    const lastet = ref(false);
    const saldo = ref<ILagerSaldo[]>([]);

    const doHentLagerSaldo = async () => {
      lastet.value = false;
      saldo.value = await hentLagerSaldo(props.artikkelNr, props.lager);
      lastet.value = true;
    };

    watchEffect(doHentLagerSaldo);

    const håndterModalLukk = (endringUtført?: boolean) => {
      aktivModal.value = null;
      if (endringUtført) {
        doHentLagerSaldo();
      }
    };

    return { lastet, saldo, aktivModal, håndterModalLukk };
  },

  components: { FlyttArtikler, FyllPåArtikler, OpprettLokasjon },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.button-row {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}

.saldo-liste {
  width: 100%;
}
</style>
