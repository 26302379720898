import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "artikkel-tabell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[2] || (_cache[2] = _createElementVNode("label", null, "Kundenummer", -1)),
    _createElementVNode("p", null, _toDisplayString(_ctx.bestilling.kundeNr), 1),
    _cache[3] || (_cache[3] = _createElementVNode("label", null, "Status", -1)),
    _createElementVNode("p", null, _toDisplayString(_ctx.statusTekst), 1),
    _cache[4] || (_cache[4] = _createElementVNode("label", null, "Lager", -1)),
    _createElementVNode("p", null, _toDisplayString(_ctx.bestilling.lager), 1),
    _cache[5] || (_cache[5] = _createElementVNode("label", null, "Leveringsmåte", -1)),
    _createElementVNode("p", null, _toDisplayString(_ctx.bestilling.leveringsmåte), 1),
    _cache[6] || (_cache[6] = _createElementVNode("label", null, "Artikler", -1)),
    _createElementVNode("table", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", null, "Artikkelnummer"),
          _createElementVNode("th", null, "Artikkelnavn"),
          _createElementVNode("th", { class: "right" }, "Antall")
        ])
      ], -1)),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bestilling.linjer, (linje) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: linje.artikkelNr
          }, [
            _createElementVNode("td", null, _toDisplayString(linje.artikkelNr), 1),
            _createElementVNode("td", null, _toDisplayString(linje.artikkel.navn), 1),
            _createElementVNode("td", null, _toDisplayString(linje.antall), 1)
          ]))
        }), 128))
      ])
    ]),
    (_ctx.erÅpen)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          class: "btn-bekreft-plukking",
          onClick: _ctx.doBekreftPlukking
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Bekreft plukking")
          ])),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}