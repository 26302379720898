import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { IArtikkel } from "@/types";
import { ref } from "vue";
import { hentLimArtikler } from "@/clients/artikkelClient";
import RuteVelger from "./RuteVelger.vue";

interface IEmits {
  (e: "legg-til-artikkel", artikkel: IArtikkel): void;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'LimArtikler',
  emits: ["legg-til-artikkel"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const laster = ref(false);
const limArtikler = ref<IArtikkel[]>([]);

const lastArtikler = async () => {
  laster.value = true;
  limArtikler.value = await hentLimArtikler();
  laster.value = false;
};
lastArtikler();

const velgArtikkel = (artikkel: IArtikkel) => {
  emit("legg-til-artikkel", artikkel);
};

return (_ctx: any,_cache: any) => {
  const _component_CenteredSpinner = _resolveComponent("CenteredSpinner")!

  return (laster.value)
    ? (_openBlock(), _createBlock(_component_CenteredSpinner, { key: 0 }))
    : (_openBlock(), _createBlock(RuteVelger, {
        key: 1,
        ruter: limArtikler.value,
        onVelgRute: velgArtikkel
      }, null, 8, ["ruter"]))
}
}

})