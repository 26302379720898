<template>
  <CenteredSpinner v-if="sender" />
  <form v-else class="epost-tester" @submit.prevent="doSendEpost">
    <div class="input-gruppe">
      <label for="mottaker"> Mottaker </label>
      <input id="mottaker" type="text" v-model="mottaker" />
    </div>
    <div class="input-gruppe">
      <label for="emne"> Emne </label>
      <input id="emne" type="text" v-model="emne" />
    </div>
    <div class="input-gruppe">
      <label for="melding"> Melding </label>
      <textarea id="melding" type="text" v-model="melding" />
    </div>

    <Button type="primary" @click="doSendEpost">Send</Button>
  </form>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { sendEpost } from "@/clients/epostClient";
import { toast } from "@/utils";

export default defineComponent({
  props: {},

  setup() {
    const mottaker = ref("");
    const emne = ref("");
    const melding = ref("");

    const sender = ref(false);

    const doSendEpost = async () => {
      sender.value = true;
      await sendEpost(mottaker.value, emne.value, melding.value);
      sender.value = false;
      toast(`Epost med emne "${emne.value}" ble sendt til "${mottaker.value}"`);
      mottaker.value = "";
      emne.value = "";
      melding.value = "";
    };

    return { mottaker, emne, melding, sender, doSendEpost };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.epost-tester {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.input-gruppe {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
