import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "undermeny" }
const _hoisted_2 = {
  key: 0,
  class: "las la-angle-right"
}
const _hoisted_3 = {
  key: 1,
  class: "las la-angle-down"
}
const _hoisted_4 = {
  key: 0,
  class: "undermeny-lenker"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "open-knapp",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleOpenClick && _ctx.handleOpenClick(...args)), ["stop"]))
    }, [
      _createTextVNode(_toDisplayString(_ctx.tittel) + " ", 1),
      (!_ctx.aktiv)
        ? (_openBlock(), _createElementBlock("i", _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.aktiv)
        ? (_openBlock(), _createElementBlock("i", _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    (_ctx.aktiv)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ]))
}