<template>
  <table>
    <thead>
      <tr>
        <th>Registreringsnummer</th>
        <th>Bilmodell</th>
        <th>Svar mottatt</th>
        <th>Antall treff</th>
      </tr>
    </thead>
    <tbody>
      <RutesøkLinje v-for="søk in rutesøk" :key="søk.id" :rutesøk="søk" />
    </tbody>
  </table>
</template>

<script lang="ts">
import { IRutesøk } from "@/types";
import { defineComponent, PropType } from "vue";
import RutesøkLinje from "./RutesøkLinje.vue";

export default defineComponent({
  props: {
    rutesøk: {
      type: Array as PropType<IRutesøk[]>,
    },
  },

  setup() {
    return {};
  },

  components: { RutesøkLinje },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

table {
  width: 100%;
}
</style>
