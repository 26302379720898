<template>
  <div class="lagervelger">
    <div>
      <span v-if="!modelValue">Du har ikke valgt hvilket lager du gjennomfører handlinger for.</span>
      <span v-else>Du gjør handlinger på vegne av lager {{ modelValue }}.</span>

      <Button class="button" v-if="modelValue && !redigerer" @click="redigerer = true">Endre</Button>
    </div>

    <div v-if="redigerer">
      <input
        type="text"
        class="inputfelt"
        placeholder="Lagernummer"
        @keyup.enter="onVelgLager()"
        v-model="valgtLager"
      />
      <Button class="button" type="primary" @click="onVelgLager()">Lagre</Button>
      <Button class="button" :disabled="!modelValue" @click="redigerer = false">Avbryt</Button>
    </div>
  </div>
</template>

<script lang="ts">
import { STORAGE_PREFIX } from "@/constants";
import { defineComponent, ref } from "vue";

const LOCAL_STORAGE_KEY = `${STORAGE_PREFIX}.valgtLager`;

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },

  setup(props, context) {
    const redigerer = ref<boolean>(!props.modelValue);
    const valgtLager = ref("");

    const onVelgLager = () => {
      redigerer.value = false;
      localStorage.setItem(LOCAL_STORAGE_KEY, valgtLager.value);

      context.emit("update:modelValue", valgtLager.value);
    };

    if (!props.modelValue) {
      const valueFromStorage = localStorage.getItem(LOCAL_STORAGE_KEY);

      if (valueFromStorage) {
        redigerer.value = false;
        context.emit("update:modelValue", valueFromStorage);
      } else {
        // Emitter uansett en verdi ved last, slik at lyttende komponenter kan laste sine data
        context.emit("update:modelValue", "");
      }
    }

    return { redigerer, valgtLager, onVelgLager };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.lagervelger {
  background: @yellow-lighter;
  border-radius: 0.5rem;
  padding: 1.2rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.inputfelt {
  width: 20rem;
}

.button {
  margin-left: 1.2rem;
}
</style>
