<template>
  <div class="lager-status-filter">
    <h3>Filtrer etter lagerstatus</h3>
    <div class="options">
      <span class="filter-option">
        <input
          type="checkbox"
          id="ok"
          class="ok"
          :checked="modelValue?.includes(LagerStatus.ok)"
          @change="håndterVerdiEndret(LagerStatus.ok, $event)"
        />
        <label for="ok">Lagerstatus ok</label>
      </span>
      <span class="filter-option">
        <input
          type="checkbox"
          id="overallokert"
          class="overallokert"
          :checked="modelValue?.includes(LagerStatus.overallokert)"
          @change="håndterVerdiEndret(LagerStatus.overallokert, $event)"
        />
        <label for="overallokert">Overallokert</label>
      </span>
      <span class="filter-option">
        <input
          type="checkbox"
          id="utilgjengelig-på-hovedlokasjon"
          class="utilgjengelig-på-hovedlokasjon"
          :checked="modelValue?.includes(LagerStatus.utilgjengeligPåHovedlokasjon)"
          @change="håndterVerdiEndret(LagerStatus.utilgjengeligPåHovedlokasjon, $event)"
        />
        <label for="utilgjengelig-på-hovedlokasjon">Utilgjengelig på hovedlokasjon</label>
      </span>
      <span class="filter-option">
        <input
          type="checkbox"
          id="tomt-på-lager"
          class="tomt-på-lager"
          :checked="modelValue?.includes(LagerStatus.tomtPåLager)"
          @change="håndterVerdiEndret(LagerStatus.tomtPåLager, $event)"
        />
        <label for="tomt-på-lager">Tomt på lager</label>
      </span>
      <Button @click="$emit('hent')">Oppdater</Button>
    </div>
  </div>
</template>

<script lang="ts">
import { LagerStatus } from "@/types";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: Array as PropType<LagerStatus[]>,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const håndterVerdiEndret = (type: LagerStatus) => {
      if (!props.modelValue.includes(type)) {
        emit("update:modelValue", [...props.modelValue, type]);
      } else if (props.modelValue.includes(type)) {
        emit(
          "update:modelValue",
          props.modelValue.filter((x) => x !== type)
        );
      }
    };

    return { LagerStatus, håndterVerdiEndret };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.options {
  padding-left: 1rem;
  display: flex;
  gap: 1.5rem;
}

.filter-option {
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    appearance: none;
    cursor: pointer;

    padding: 0.35rem;
    margin-right: 0.5rem;
    border: 0.15rem solid @black70;
    outline: 0.05rem solid @black80;
    background: @white;

    &:checked {
      background: @black80;
      position: relative;

      &:before {
        content: "\2713";
        color: @black30;
        font-size: 1.25rem;
        position: absolute;
        bottom: -0.45rem;
        left: -0.1rem;
      }
    }

    &.ok {
      border-color: @green;

      &:checked {
        background: @green;
      }
    }
    &.overallokert {
      border-color: @yellow;

      &:checked {
        background: @yellow;
      }
    }
    &.utilgjengelig-på-hovedlokasjon {
      border-color: @orange;

      &:checked {
        background: @orange;
      }
    }
    &.tomt-på-lager {
      border-color: @red-light;

      &:checked {
        background: @red-light;
      }
    }
  }

  label {
    cursor: pointer;
  }
}
</style>
