<template>
  <div>
    <div class="verktøyvelger-wrapper">
      <label>Kategori</label>
      <select v-model="valgtHovedgruppe">
        <option v-for="hovedgruppe in hovedgrupper" :value="hovedgruppe" :key="hovedgruppe.hovedgruppeId">
          {{ hovedgruppe.beskrivelse }}
        </option>
      </select>

      <label>Underkategori</label>
      <select v-model="valgtUndergruppe" :disabled="undergrupper.length === 0">
        <option v-for="undergruppe in undergrupper" :value="undergruppe" :key="undergruppe.undergruppeId">
          {{ undergruppe.beskrivelse }}
        </option>
      </select>
    </div>

    <CenteredSpinner v-if="søker" />
    <RuteVelger v-else-if="artikler.length > 0" :ruter="artikler" @velg-rute="velgArtikkel" />
    <p v-else-if="valgtHovedgruppe && valgtUndergruppe">Fant ingen verktøy</p>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { IHovedgruppe, IUndergruppe, IArtikkel } from "@/types";
import { hentHovedgrupper, hentUndergrupper, hentVerktøy } from "@/clients/verktøyClient";
import RuteVelger from "./RuteVelger.vue";

interface IEmits {
  (e: "legg-til-artikkel", artikkel: IArtikkel): void;
}
const emit = defineEmits<IEmits>();

const valgtHovedgruppe = ref<IHovedgruppe | undefined>();
const valgtUndergruppe = ref<IUndergruppe | undefined>();

const hovedgrupper = ref<IHovedgruppe[]>([]);
const undergrupper = ref<IUndergruppe[]>([]);

const artikler = ref<IArtikkel[]>([]);
const søker = ref(false);

(async () => {
  hovedgrupper.value = await hentHovedgrupper();
})();

watch(valgtHovedgruppe, async (hovedgruppe) => {
  if (hovedgruppe) {
    undergrupper.value = await hentUndergrupper(hovedgruppe.hovedgruppeId);
    valgtUndergruppe.value = undergrupper.value.length === 1 ? undergrupper.value[0] : undefined;
  }
});

watch(valgtUndergruppe, async (undergruppe) => {
  if (undergruppe) {
    søker.value = true;
    artikler.value = await hentVerktøy(undergruppe.hovedgruppeId, undergruppe.undergruppeId);
    søker.value = false;
  } else {
    artikler.value = [];
  }
});

const velgArtikkel = (artikkel: IArtikkel) => {
  emit("legg-til-artikkel", artikkel);
};
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.verktøyvelger-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
