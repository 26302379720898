import { IImportFil } from "@/types";
import { get, post } from "@/utils";

export const importerData = async (
  hovedfil: null | string = null,
  suppleringsfil: null | string = null
): Promise<void> => {
  const params = new URLSearchParams();
  if (hovedfil !== null) {
    params.append("hovedfil", hovedfil);
  }

  if (suppleringsfil !== null) {
    params.append("suppleringsfil", suppleringsfil);
  }

  const query = params.toString();

  await post(`/api/import/${query ? `?${query}` : ""}`);
};

export const hentFilListe = async (): Promise<IImportFil[]> => {
  const result = await get(`/api/import/filer`);

  return result;
};
