<template>
  <div class="nytt-rutesøk">
    <CenteredSpinner v-if="lagrer" />
    <div v-else class="skjema-wrapper">
      <label for="ruteType">Rutetype</label>
      <select id="ruteType" v-model="ruteType">
        <option :value="1">Frontrute</option>
        <option :value="2">Siderute</option>
        <option :value="3">Bakrute</option>
        <option :value="4">Takrute</option>
      </select>
      <label for="regNr">Registreringsnummer</label>
      <div class="regnr-wrapper">
        <input
          id="regNr"
          type="text"
          v-model="regNr"
          placeholder="Registreringsnummer"
          @keydown.enter="søkEtterBil()"
        />
        <Button @click="søkEtterBil()">Søk</Button>
      </div>
      <Spinner v-if="søkerEtterRegNr" />
      <div v-if="harSøktEtterRegNr && fantBil">
        <p>Fant bil med registreringsnummer {{ søktRegNr }}:</p>
        <p>{{ merke }} {{ modell }} {{ årsmodell }}</p>
        <p>Chassisnummer: {{ chassisNr }}</p>
      </div>
      <InfoBox v-if="harSøktEtterRegNr && !fantBil">
        Fant ingen bil med registreringsnummer {{ søktRegNr }} - dobbeltsjekk at det er riktig. Dersom
        registreringsnummer stemmer må du fylle inn chassisnummer, merke, modell og årsmodell manuelt for å kunne utføre
        rutesøk.
      </InfoBox>
      <label for="chassisNr" v-if="harSøktEtterRegNr && !fantBil">Chassisnummer</label>
      <input
        type="text"
        id="chassisNr"
        v-model="chassisNr"
        v-if="harSøktEtterRegNr && !fantBil"
        placeholder="Chassisnummer"
      />
      <ModellVelger v-if="harSøktEtterRegNr && !fantBil" @update:bilInfo="settBilInfoFraModellVelger($event)" />
      <Button
        type="primary"
        :disabled="!(ruteType && regNr && chassisNr && merke && modell && årsmodell)"
        @click="opprettRutesøk()"
      >
        Opprett rutesøk
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import { hentBilInfo } from "@/clients/bilInfoClient";
import { opprettRutesøk as postRutesøk } from "@/clients/rutesøkClient";
import { IBilInfo, RuteType } from "@/types";
import { defineComponent, ref } from "vue";
import { ModellVelger } from "@/components";

export default defineComponent({
  props: {
    valgtKunde: {
      type: String,
    },
  },

  setup(props, context) {
    const ruteType = ref<RuteType>(1);
    const regNr = ref("");
    const harSøktEtterRegNr = ref(false);
    const søkerEtterRegNr = ref(false);
    const fantBil = ref(false);
    const søktRegNr = ref("");
    const merke = ref("");
    const modell = ref("");
    const årsmodell = ref("");
    const chassisNr = ref("");
    const lagrer = ref(false);

    const søkEtterBil = async () => {
      søktRegNr.value = regNr.value;
      harSøktEtterRegNr.value = false;
      søkerEtterRegNr.value = true;

      try {
        const bilInfo = await hentBilInfo(søktRegNr.value);
        chassisNr.value = bilInfo.chassisNr;
        merke.value = bilInfo.merke;
        modell.value = bilInfo.modell;
        årsmodell.value = bilInfo.årsmodellFra;
        fantBil.value = true;
      } catch {
        fantBil.value = false;
      }

      søkerEtterRegNr.value = false;
      harSøktEtterRegNr.value = true;
    };

    const settBilInfoFraModellVelger = (bilInfo: IBilInfo) => {
      merke.value = bilInfo.merke;
      modell.value = bilInfo.modell;
      årsmodell.value = bilInfo.årsmodellFra;
    };

    const opprettRutesøk = async () => {
      lagrer.value = true;
      try {
        await postRutesøk(
          props.valgtKunde,
          regNr.value,
          chassisNr.value,
          ruteType.value,
          merke.value,
          modell.value,
          årsmodell.value
        );

        context.emit("opprettet");
        context.emit("gjennomførtSøk", søktRegNr.value);
      } finally {
        lagrer.value = false;
      }
    };

    return {
      ruteType,
      regNr,
      søkEtterBil,
      søkerEtterRegNr,
      harSøktEtterRegNr,
      fantBil,
      merke,
      modell,
      årsmodell,
      chassisNr,
      søktRegNr,
      settBilInfoFraModellVelger,
      opprettRutesøk,
      lagrer,
    };
  },
  components: { ModellVelger },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.skjema-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  input,
  select,
  &::v-deep select {
    box-sizing: content-box;
    min-width: 20rem;
  }
}

.regnr-wrapper {
  display: flex;
  gap: 0.5rem;
  & > input {
    flex-grow: 1;
  }
}
</style>
