import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["lagerstatus", {
      'tomt-på-lager': _ctx.lagerStatus === _ctx.LagerStatus.tomtPåLager,
      utilgjengelig: _ctx.lagerStatus === _ctx.LagerStatus.utilgjengeligPåHovedlokasjon,
      overallokert: _ctx.lagerStatus === _ctx.LagerStatus.overallokert,
      ukjent: _ctx.lagerStatus === _ctx.LagerStatus.ukjent,
    }]),
    title: _ctx.lagLagerStatusBeskrivelse(_ctx.lagerStatus)
  }, null, 10, _hoisted_1))
}