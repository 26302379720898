import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-gruppe" }
const _hoisted_2 = { class: "input-gruppe" }

import { ref } from "vue";
import { sendSms } from "@/clients/smsClient";
import { toast } from "@/utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'SmsTester',
  setup(__props) {

const mottaker = ref("");
const emne = ref("");
const melding = ref("");

const sender = ref(false);

const sendSmsKlikk = async () => {
  try {
    sender.value = true;
    await sendSms(mottaker.value, melding.value);
    toast(`SMS ble sendt til "${mottaker.value}"`);
  } catch (e: any) {
    toast(`SMS sending feilet: ${e.message}`);
  } finally {
    sender.value = false;
    mottaker.value = "";
    emne.value = "";
    melding.value = "";
  }
};

return (_ctx: any,_cache: any) => {
  const _component_CenteredSpinner = _resolveComponent("CenteredSpinner")!
  const _component_Button = _resolveComponent("Button")!

  return (sender.value)
    ? (_openBlock(), _createBlock(_component_CenteredSpinner, { key: 0 }))
    : (_openBlock(), _createElementBlock("form", {
        key: 1,
        class: "sms-tester",
        onSubmit: _withModifiers(sendSmsKlikk, ["prevent"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _cache[2] || (_cache[2] = _createElementVNode("label", { for: "mottaker" }, " Mottaker ", -1)),
          _withDirectives(_createElementVNode("input", {
            id: "mottaker",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((mottaker).value = $event)),
            type: "text"
          }, null, 512), [
            [_vModelText, mottaker.value]
          ])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _cache[3] || (_cache[3] = _createElementVNode("label", { for: "melding" }, " Melding ", -1)),
          _withDirectives(_createElementVNode("textarea", {
            id: "melding",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((melding).value = $event)),
            type: "text"
          }, null, 512), [
            [_vModelText, melding.value]
          ])
        ]),
        _createVNode(_component_Button, {
          type: "primary",
          onClick: sendSmsKlikk
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Send")
          ])),
          _: 1
        })
      ], 32))
}
}

})