import { IArtikkel, IBilInfo, IHovedgruppe, IUndergruppe, IÅrsmodell, RuteType } from "@/types";
import { get } from "@/utils";

export const hentHovedgrupper = async (): Promise<IHovedgruppe[]> => {
  const result = await get(`/api/biler/hovedgrupper`);

  return result;
};

export const hentUndergrupper = async (hovedgruppeId: number): Promise<IUndergruppe[]> => {
  const result = await get(`/api/biler/hovedgrupper/${hovedgruppeId}/undergrupper`);

  return result;
};

export const hentÅrsmodeller = async (hovedgruppeId: number, undergruppeId: number): Promise<IÅrsmodell[]> => {
  const result = await get(`/api/biler/hovedgrupper/${hovedgruppeId}/undergrupper/${undergruppeId}/årsmodeller`);

  return result;
};

export const hentRuterForÅrsmodell = async (
  hovedgruppeId: number,
  undergruppeId: number,
  fra: string,
  ruteType: RuteType | null
): Promise<IArtikkel[]> => {
  const result = await get(
    `/api/biler/hovedgrupper/${hovedgruppeId}/undergrupper/${undergruppeId}/årsmodeller/${fra}/ruter${
      ruteType ? `?ruteType=${ruteType}` : ""
    }`
  );

  return result;
};

export const hentRuterForRegNr = async (regNr: string, ruteType: RuteType | null = null): Promise<IArtikkel[]> => {
  const result = await get(`/api/biler/${regNr}/ruter${ruteType ? `?ruteType=${ruteType}` : ""}`);

  return result;
};

export const hentBilInfo = async (regNr: string): Promise<IBilInfo> => {
  const result = await get(`/api/biler/${regNr}`);

  return result;
};
