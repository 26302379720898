<template>
  <span
    class="lagerstatus"
    :class="{
      'tomt-på-lager': lagerStatus === LagerStatus.tomtPåLager,
      utilgjengelig: lagerStatus === LagerStatus.utilgjengeligPåHovedlokasjon,
      overallokert: lagerStatus === LagerStatus.overallokert,
      ukjent: lagerStatus === LagerStatus.ukjent,
    }"
    :title="lagLagerStatusBeskrivelse(lagerStatus)"
  />
</template>

<script lang="ts">
import { LagerStatus } from "@/types";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    lagerStatus: {
      type: Object as PropType<LagerStatus>,
      required: true,
    },
  },

  setup() {
    const lagLagerStatusBeskrivelse = (lagerStatus: LagerStatus): string => {
      if (lagerStatus === LagerStatus.tomtPåLager) return "Manko av artikkel på lageret";
      if (lagerStatus === LagerStatus.utilgjengeligPåHovedlokasjon) return "Ikke nok artikler på hovedlokasjon";
      if (lagerStatus === LagerStatus.overallokert)
        return "Lagerbeholdning på hovedlokasjon er overallokert, og vil gå tom med nåværende bestillinger";
      if (lagerStatus === LagerStatus.ok) return "Lagerstatus ok";

      return "Ukjent lagerstatus";
    };
    return { lagLagerStatusBeskrivelse, LagerStatus };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.lagerstatus {
  @size: 0.7rem;

  background: @green;
  display: inline-block;
  width: @size;
  height: @size;
  border-radius: calc(@size / 2);
  cursor: pointer;

  box-shadow: 0 0 2px @black10;
}

.overallokert,
.ukjent {
  background: @yellow;
}

.utilgjengelig {
  background: @orange;
}

.tomt-på-lager {
  background: @red-light;
}
</style>
