import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "typeahead" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "typeahead-results"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: "søkefelt",
      type: "text",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.søkeVerdi) = $event)),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInputChange && _ctx.onInputChange(...args))),
      onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleKey && _ctx.handleKey(...args))),
      placeholder: _ctx.placeholder
    }, null, 40, _hoisted_2), [
      [_vModelText, _ctx.søkeVerdi]
    ]),
    (_ctx.result.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.result, (entry, i) => {
            return (_openBlock(), _createElementBlock("li", {
              key: entry.key,
              class: _normalizeClass({ active: i === _ctx.activeResult }),
              onClick: ($event: any) => (_ctx.selectValue(entry.value))
            }, _toDisplayString(entry.name), 11, _hoisted_4))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}