<template>
  <div>
    <label>Kundenummer</label>
    <p>{{ bestilling.kundeNr }}</p>

    <label>Status</label>
    <p>{{ statusTekst }}</p>

    <label>Lager</label>
    <p>{{ bestilling.lager }}</p>

    <label>Leveringsmåte</label>
    <p>{{ bestilling.leveringsmåte }}</p>

    <label>Artikler</label>
    <table class="artikkel-tabell">
      <thead>
        <tr>
          <th>Artikkelnummer</th>
          <th>Artikkelnavn</th>
          <th class="right">Antall</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="linje in bestilling.linjer" :key="linje.artikkelNr">
          <td>{{ linje.artikkelNr }}</td>
          <td>{{ linje.artikkel.navn }}</td>
          <td>{{ linje.antall }}</td>
        </tr>
      </tbody>
    </table>

    <Button v-if="erÅpen" class="btn-bekreft-plukking" @click="doBekreftPlukking">Bekreft plukking</Button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { BestillingStatus, BestillingStatusTekst, IBestilling } from "@/types";
import { bekreftPlukking } from "@/clients/bestillingClient";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {
    bestilling: {
      type: Object as PropType<IBestilling>,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();

    const doBekreftPlukking = async () => {
      await bekreftPlukking(props.bestilling.id);
      router.go(0);
    };

    const erÅpen = computed(() => props.bestilling.status == BestillingStatus.åpen);

    const statusTekst = computed(() => BestillingStatusTekst.get(props.bestilling.status));

    return { doBekreftPlukking, erÅpen, statusTekst };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

label {
  font-size: 1rem;
}

.artikkel-tabell {
  margin-top: 1rem;
}

.btn-bekreft-plukking {
  margin-top: 2rem;
}
</style>
