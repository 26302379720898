import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "infobox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("i", { class: "icon las la-info-circle" }, null, -1)),
    _createElementVNode("p", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}