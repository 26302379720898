import { resolveComponent as _resolveComponent, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "bestilling-view" }
const _hoisted_2 = {
  key: 1,
  class: "bestilling-content"
}
const _hoisted_3 = {
  key: 2,
  class: "sender-bestilling-wrapper"
}
const _hoisted_4 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KundeVelger = _resolveComponent("KundeVelger")!
  const _component_RuteFinnerRegNr = _resolveComponent("RuteFinnerRegNr")!
  const _component_RuteFinnerModell = _resolveComponent("RuteFinnerModell")!
  const _component_LimArtikler = _resolveComponent("LimArtikler")!
  const _component_Verkt248yFinner = _resolveComponent("VerktøyFinner")!
  const _component_ArtikkelVelgerArtikkelNr = _resolveComponent("ArtikkelVelgerArtikkelNr")!
  const _component_Handlekurv = _resolveComponent("Handlekurv")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isAdmin || _ctx.isKundesenter)
      ? _withDirectives((_openBlock(), _createBlock(_component_KundeVelger, {
          key: 0,
          modelValue: _ctx.valgtKunde,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valgtKunde) = $event))
        }, null, 8, ["modelValue"])), [
          [_vShow, !_ctx.senderBestilling && !_ctx.bestillingFullført]
        ])
      : _createCommentVNode("", true),
    (!_ctx.senderBestilling && !_ctx.bestillingFullført && (!(_ctx.isAdmin || _ctx.isKundesenter) || _ctx.valgtKunde))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("h2", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.aktivVelger = 'regnr'))
            }, [
              _cache[7] || (_cache[7] = _createTextVNode(" Søk etter bil ")),
              _createElementVNode("span", {
                class: _normalizeClass(["caret", { 'caret-down': _ctx.aktivVelger === 'regnr' }])
              }, "❯", 2)
            ]),
            (_ctx.aktivVelger === 'regnr')
              ? (_openBlock(), _createBlock(_component_RuteFinnerRegNr, {
                  key: 0,
                  onLeggTilArtikkel: _ctx.leggTilArtikkel
                }, null, 8, ["onLeggTilArtikkel"]))
              : _createCommentVNode("", true),
            _createElementVNode("h2", {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.aktivVelger = 'årsmodell'))
            }, [
              _cache[8] || (_cache[8] = _createTextVNode(" Velg bilmodell ")),
              _createElementVNode("span", {
                class: _normalizeClass(["caret", { 'caret-down': _ctx.aktivVelger === 'årsmodell' }])
              }, "❯", 2)
            ]),
            (_ctx.aktivVelger === 'årsmodell')
              ? (_openBlock(), _createBlock(_component_RuteFinnerModell, {
                  key: 1,
                  onLeggTilArtikkel: _ctx.leggTilArtikkel
                }, null, 8, ["onLeggTilArtikkel"]))
              : _createCommentVNode("", true),
            _createElementVNode("h2", {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.aktivVelger = 'lim'))
            }, [
              _cache[9] || (_cache[9] = _createTextVNode(" Lim ")),
              _createElementVNode("span", {
                class: _normalizeClass(["caret", { 'caret-down': _ctx.aktivVelger === 'lim' }])
              }, "❯", 2)
            ]),
            (_ctx.aktivVelger === 'lim')
              ? (_openBlock(), _createBlock(_component_LimArtikler, {
                  key: 2,
                  onLeggTilArtikkel: _ctx.leggTilArtikkel
                }, null, 8, ["onLeggTilArtikkel"]))
              : _createCommentVNode("", true),
            _createElementVNode("h2", {
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.aktivVelger = 'verktøy'))
            }, [
              _cache[10] || (_cache[10] = _createTextVNode(" Verktøy ")),
              _createElementVNode("span", {
                class: _normalizeClass(["caret", { 'caret-down': _ctx.aktivVelger === 'verktøy' }])
              }, "❯", 2)
            ]),
            (_ctx.aktivVelger == 'verktøy')
              ? (_openBlock(), _createBlock(_component_Verkt248yFinner, {
                  key: 3,
                  onLeggTilArtikkel: _ctx.leggTilArtikkel
                }, null, 8, ["onLeggTilArtikkel"]))
              : _createCommentVNode("", true),
            _createElementVNode("h2", {
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.aktivVelger = 'artikkelnummer'))
            }, [
              _cache[11] || (_cache[11] = _createTextVNode(" Søk etter artikkelnummer ")),
              _createElementVNode("span", {
                class: _normalizeClass(["caret", { 'caret-down': _ctx.aktivVelger === 'artikkelnummer' }])
              }, "❯", 2)
            ]),
            (_ctx.aktivVelger === 'artikkelnummer')
              ? (_openBlock(), _createBlock(_component_ArtikkelVelgerArtikkelNr, {
                  key: 4,
                  onLeggTilArtikkel: _ctx.leggTilArtikkel
                }, null, 8, ["onLeggTilArtikkel"]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_Handlekurv, {
            linjer: _ctx.handlekurv,
            valgtKunde: _ctx.valgtKunde,
            class: "handlekurv",
            onOpprettBestilling: _ctx.opprettBestilling,
            onSlettLinje: _ctx.slettLinje
          }, null, 8, ["linjer", "valgtKunde", "onOpprettBestilling", "onSlettLinje"])
        ]))
      : (_ctx.senderBestilling)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_Spinner),
            _cache[12] || (_cache[12] = _createElementVNode("h3", null, "Sender bestilling", -1))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _cache[14] || (_cache[14] = _createElementVNode("h3", null, "Bestilling fullført", -1)),
            _createVNode(_component_Button, {
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.nullstill()))
            }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode("Legg inn ny bestilling")
              ])),
              _: 1
            })
          ]))
  ]))
}