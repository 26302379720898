<template>
  <div>
    <div class="søk-wrapper">
      <input type="text" v-model="søkestreng" placeholder="Søk etter kunde" @keydown.enter="søk()" />
    </div>
    <CenteredSpinner v-if="søker" />
    <table class="resultat" v-else-if="harSøkt && resultat.length > 0">
      <thead>
        <th>Kundenummer</th>
        <th>Navn</th>
      </thead>
      <tbody>
        <tr v-for="kunde in resultat" :key="kunde.kundeNummer" @click="velgKunde(kunde)" class="kunde-rad">
          <td>{{ kunde.kundeNummer }}</td>
          <td>{{ kunde.navn }}</td>
        </tr>
      </tbody>
    </table>
    <p v-if="harSøkt && resultat.length === 0">Ingen resultater</p>
  </div>
</template>

<script lang="ts">
import { søkEtterKunder } from "@/clients/kundeClient";
import { IKunde } from "@/types";
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {},

  setup() {
    const søkestreng = ref("");
    const resultat = ref<IKunde[]>([]);
    const søker = ref(false);
    const harSøkt = ref(false);

    const søk = async () => {
      søker.value = true;
      resultat.value = await søkEtterKunder(søkestreng.value);
      søker.value = false;
      harSøkt.value = true;
    };

    const router = useRouter();

    const velgKunde = (kunde: IKunde) => {
      router.push(`/kunder/${kunde.kundeNummer}`);
    };

    return { søkestreng, resultat, søker, harSøkt, søk, velgKunde };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.søk-wrapper {
  display: flex;
  flex-direction: column;
}

.resultat {
  width: 100%;
}

.kunde-rad {
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.07);
  }
}
</style>
