<template>
  <tr class="hovedlinje" @click="håndterLinjeKlikk()">
    <td>{{ rutesøk.bilInfo.regNr }}</td>
    <td>{{ `${rutesøk.bilInfo.merke} ${rutesøk.bilInfo.modell} ${rutesøk.bilInfo.årsmodell}` }}</td>
    <td>{{ rutesøk.svar ? formaterDatoTid(rutesøk.svar.opprettet) : "Nei" }}</td>
    <td>
      {{ rutesøk.svar?.eurokode.length ?? "" }}
      <i v-if="!viserUnderlinjer && !!rutesøk.svar" class="åpne-ikon las la-caret-right" />
      <i v-if="viserUnderlinjer" class="åpne-ikon las la-caret-down" />
    </td>
  </tr>
  <RutesøkUnderlinje
    v-for="resultat in visteUnderlinjer"
    :key="resultat.eurokode"
    :resultat="resultat"
    :regNr="rutesøk.bilInfo.regNr"
  />
</template>

<script lang="ts">
import { IRutesøk } from "@/types";
import { computed, defineComponent, PropType, ref } from "vue";
import { formaterDatoTid } from "@/utils";
import RutesøkUnderlinje from "./RutesøkUnderlinje.vue";

export default defineComponent({
  props: {
    rutesøk: {
      type: Object as PropType<IRutesøk>,
    },
  },

  setup(props) {
    const viserUnderlinjer = ref(false);
    const visteUnderlinjer = computed(() => (viserUnderlinjer.value ? props.rutesøk?.svar?.resultater : []));

    const håndterLinjeKlikk = () => {
      viserUnderlinjer.value = !!props.rutesøk?.svar && !viserUnderlinjer.value;
    };

    return { viserUnderlinjer, formaterDatoTid, visteUnderlinjer, håndterLinjeKlikk };
  },

  components: { RutesøkUnderlinje },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.hovedlinje {
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }

  .åpne-ikon {
    float: right;
  }
}
</style>
