import { App } from "vue";
import Button from "./Button.vue";
import ButtonRouterLink from "./ButtonRouterLink.vue";
import ButtonLink from "./ButtonLink.vue";
import Spinner from "./Spinner.vue";
import CenteredSpinner from "./CenteredSpinner.vue";
import TypeAhead from "./TypeAhead.vue";
import Modal from "./Modal.vue";
import InfoBox from "./InfoBox.vue";
import SideVelger from "./SideVelger.vue";

export const registerBasisComponents = (app: App): void => {
  app.component("Button", Button);
  app.component("ButtonRouterLink", ButtonRouterLink);
  app.component("ButtonLink", ButtonLink);
  app.component("Spinner", Spinner);
  app.component("TypeAhead", TypeAhead);
  app.component("Modal", Modal);
  app.component("CenteredSpinner", CenteredSpinner);
  app.component("InfoBox", InfoBox);
  app.component("SideVelger", SideVelger);
};
