import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "knapper" }
const _hoisted_3 = { class: "list-container" }
const _hoisted_4 = {
  key: 1,
  class: "spinner-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OpprettToken = _resolveComponent("OpprettToken")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_Button = _resolveComponent("Button")!
  const _component_button_router_link = _resolveComponent("button-router-link")!
  const _component_BrukerListe = _resolveComponent("BrukerListe")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_ctx.lastet)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Modal, {
          aktiv: _ctx.visTokenDialog,
          onLukk: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visTokenDialog = false))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_OpprettToken)
          ]),
          _: 1
        }, 8, ["aktiv"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.visTokenDialog = true))
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Opprett registreringstoken")
            ])),
            _: 1
          }),
          _createVNode(_component_button_router_link, { to: "/brukere/ny" }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Opprett ny bruker")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.lastet)
            ? (_openBlock(), _createBlock(_component_BrukerListe, {
                key: 0,
                brukere: _ctx.brukere
              }, null, 8, ["brukere"]))
            : _createCommentVNode("", true)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createVNode(_component_Spinner)
      ]))
}