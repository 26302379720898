import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spinner-wrapper"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_BestillingDetaljer = _resolveComponent("BestillingDetaljer")!

  return (!_ctx.lastet)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Spinner)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_BestillingDetaljer, { bestilling: _ctx.bestilling }, null, 8, ["bestilling"])
      ]))
}