import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import authService from "@/utils/auth/authService";
import { lastNedKundeEksport } from "@/utils/lastNedEksport";


export default /*@__PURE__*/_defineComponent({
  __name: 'OppdragEksport',
  setup(__props) {

const eksporter = async () => {
  const user = await authService.getUser();
  await lastNedKundeEksport(user?.kundeNr);
};

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Button, {
      type: "primary",
      onClick: eksporter
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Last ned mine oppdrag")
      ])),
      _: 1
    })
  ]))
}
}

})