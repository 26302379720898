<template>
  <div>
    <ul class="ruteliste">
      <li class="rad" v-for="rute in ruter" :key="rute.artikkelNr">
        <div class="artikkel-info">
          <span class="artikkel-nummer">{{ rute.artikkelNummer }} </span>
          <span class="artikkel-navn">
            {{ rute.navn }}
            <span class="egenskaper" v-if="rute.egenskaper.length">
              –
              {{ rute.egenskaper.map((x) => x.beskrivelse).join(", ") }}
            </span>
          </span>
        </div>
        <div class="knapper">
          <Button @click="leggTilArtikkel(rute)">Legg til</Button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { IArtikkel } from "@/types";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    ruter: {
      type: Object as PropType<IArtikkel[]>,
      required: true,
    },
  },

  setup(props, context) {
    const leggTilArtikkel = (artikkel: IArtikkel) => {
      context.emit("velg-rute", artikkel);
    };

    return { leggTilArtikkel };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
.ruteliste {
  list-style: none;
  padding: 0;
}

.rad {
  padding: 1rem 0.7rem;
  display: flex;
  gap: 0.5rem;
}

.rad:not(:last-child) {
  border-bottom: 1px solid @black80;
}

.artikkel-info {
  display: flex;
  flex-direction: column;
}

.artikkel-nummer {
  font-weight: bold;
}

.egenskaper {
  font-style: italic;
}

.knapper {
  margin-left: auto;
  flex-shrink: 0;
}
</style>
