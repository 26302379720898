import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "mine-bestillinger" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BestillingDetaljer = _resolveComponent("BestillingDetaljer")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Modal, {
      aktiv: !!_ctx.valgtBestilling,
      onLukk: _cache[0] || (_cache[0] = ($event: any) => (_ctx.valgtBestilling = null))
    }, {
      default: _withCtx(() => [
        (_ctx.valgtBestilling)
          ? (_openBlock(), _createBlock(_component_BestillingDetaljer, {
              key: 0,
              bestilling: _ctx.valgtBestilling
            }, null, 8, ["bestilling"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["aktiv"]),
    _createElementVNode("table", null, [
      _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
        _createElementVNode("th", null, "Bestilling-ID"),
        _createElementVNode("th", null, "Dato"),
        _createElementVNode("th", null, "Antall varer")
      ], -1)),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bestillinger, (bestilling) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: bestilling.id,
            class: "bestilling-rad",
            onClick: ($event: any) => (_ctx.valgtBestilling = bestilling)
          }, [
            _createElementVNode("td", null, _toDisplayString(bestilling.id), 1),
            _createElementVNode("td", null, _toDisplayString(_ctx.formaterDatoTid(bestilling.opprettet)), 1),
            _createElementVNode("td", null, _toDisplayString(bestilling.linjer.length), 1)
          ], 8, _hoisted_2))
        }), 128))
      ])
    ])
  ]))
}