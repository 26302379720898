<template>
  <router-link :to="to" class="router-link" :title="title">
    <slot />
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    to: {
      type: String,
      required: true,
    },
    class: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
  },

  setup() {
    return {};
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.router-link {
  font-size: 0.8rem;
  background: @black80;
  border: none;
  border-radius: 0.2rem;
  padding: 0.4rem 0.9rem;
  color: @black10;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
}
</style>
