import { hentEksportToken, hentKundeEksportToken } from "@/clients/eksportClient";

export interface IEksportConfig {
  bestillinger: boolean;
  artikler: boolean;
  kunder: boolean;
  lagerSaldo: boolean;
  hovedlokasjoner: boolean;
  fremtidigeOppdrag: boolean;
}

export const lastNedEksport = async (config: IEksportConfig): Promise<void> => {
  const token = await hentEksportToken();

  const params = new URLSearchParams();
  params.append("token", token);

  for (const [key, value] of Object.entries(config)) {
    params.append(key, value);
  }

  const url = `/api/eksport?${params.toString()}`;

  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", "eksport.csv");
  document.body.appendChild(link);

  link.click();
};

export const lastNedKundeEksport = async (kundeNr: string | null = null): Promise<void> => {
  const token = await hentKundeEksportToken();

  const params = new URLSearchParams();
  params.append("token", token);
  if (kundeNr) {
    params.append("kundeNr", kundeNr);
  }

  const url = `/api/eksport/oppdrag?${params.toString()}`;

  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", "oppdrag.xlsx");
  document.body.appendChild(link);

  link.click();
};
