import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "errors"
}
const _hoisted_2 = { class: "input-gruppe" }
const _hoisted_3 = { class: "input-gruppe" }
const _hoisted_4 = { class: "input-gruppe" }
const _hoisted_5 = { class: "input-gruppe" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "input-gruppe" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("form", {
    class: "wrapper",
    onSubmit: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.lagre()), ["prevent"]))
  }, [
    _cache[13] || (_cache[13] = _createElementVNode("h1", null, "Ny bruker", -1)),
    (_ctx.errors.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
            return (_openBlock(), _createElementBlock("li", { key: error }, _toDisplayString(error), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _cache[7] || (_cache[7] = _createElementVNode("label", { for: "brukernavn" }, "Brukernavn", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        id: "brukernavn",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.brukerNavn) = $event))
      }, null, 512), [
        [_vModelText, _ctx.brukerNavn]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[8] || (_cache[8] = _createElementVNode("label", { for: "lager" }, "Lager", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        id: "lager",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lager) = $event))
      }, null, 512), [
        [_vModelText, _ctx.lager]
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[9] || (_cache[9] = _createElementVNode("label", { for: "kundeNr" }, "Kundenummer", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        id: "kundeNr",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.kundeNr) = $event))
      }, null, 512), [
        [_vModelText, _ctx.kundeNr]
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[10] || (_cache[10] = _createElementVNode("label", { for: "passord1" }, "Passord", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "password",
        id: "passord1",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.passord1) = $event))
      }, null, 512), [
        [_vModelText, _ctx.passord1]
      ])
    ]),
    (_ctx.passord2 && _ctx.passord1 !== _ctx.passord2)
      ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Passordene er ikke like"))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _cache[11] || (_cache[11] = _createElementVNode("label", { for: "passord2" }, "Gjenta passord", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "password",
        id: "passord2",
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.passord2) = $event))
      }, null, 512), [
        [_vModelText, _ctx.passord2]
      ])
    ]),
    _createVNode(_component_Button, {
      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.lagre())),
      type: "primary",
      disabled: _ctx.lagrer || !_ctx.passord1 || !_ctx.brukerNavn || _ctx.passord1 !== _ctx.passord2
    }, {
      default: _withCtx(() => _cache[12] || (_cache[12] = [
        _createTextVNode(" Lagre ")
      ])),
      _: 1
    }, 8, ["disabled"])
  ], 32))
}