<template>
  <div>
    <LagerVelger v-if="isAdmin || isKundesenter" v-model="valgtLager" />

    <h2>Artikkelinfo</h2>
    <ArtikkelInfo :artikkelNr="artikkelNr" :lager="valgtLager" />

    <h2 class="title-lagerstatus">Lagerstatus</h2>
    <SaldoListe :lager="valgtLager" :artikkelNr="artikkelNr" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

import { LagerVelger, SaldoListe, ArtikkelInfo } from "@/components";
import authService from "@/utils/auth/authService";

export default defineComponent({
  props: {
    artikkelNr: {
      type: String,
      required: true,
    },
  },

  setup() {
    const valgtLager = ref("");

    const isKundesenter = ref(false);
    const isAdmin = ref(false);
    authService.hasRole("Admin").then((result) => (isAdmin.value = result));
    authService.hasRole("Kundesenter").then((result) => (isKundesenter.value = result));

    return {
      valgtLager,
      isAdmin,
      isKundesenter,
    };
  },

  components: {
    LagerVelger,
    SaldoListe,
    ArtikkelInfo,
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.saldo-spinner {
  margin-top: 1rem;
}

.title-lagerstatus {
  margin-top: 3rem;
}
</style>
