<template>
  <RedigerBruker :brukerId="brukerId" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RedigerBruker } from "@/components";

export default defineComponent({
  props: {
    brukerId: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {};
  },

  components: { RedigerBruker },
});
</script>

<style lang="less" scoped></style>
