<template>
  <div class="fyll-på-artikler">
    <h2>Fyll på artikler</h2>
    <CenteredSpinner v-if="lagrer" />
    <form class="skjema" v-else @submit.prevent="lagre">
      <div class="input-gruppe">
        <label for="lokasjon"> Lokasjon </label>
        <select id="lokasjon" v-model="lokasjon">
          <option v-for="lokasjon in lokasjoner" :value="lokasjon.lokasjon" :key="lokasjon.lokasjon">
            {{ lokasjon.lokasjon }}: {{ lokasjon.saldo }}
          </option>
        </select>
      </div>
      <div class="input-gruppe">
        <label for="antall">Antall</label>
        <input type="number" id="antall" v-model="antall" placeholder="Antall" />
      </div>
      <Button type="primary" @click="lagre">Lagre</Button>
    </form>
  </div>
</template>

<script lang="ts">
import { fyllPåArtikler } from "@/clients/lokasjonClient";
import { ILagerSaldo } from "@/types";
import { toast } from "@/utils";
import { defineComponent, PropType, ref } from "vue";

export default defineComponent({
  props: {
    lokasjoner: {
      type: Array as PropType<ILagerSaldo[]>,
      required: true,
    },
    artikkelNr: {
      type: String,
      required: true,
    },
    lager: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const lokasjon = ref("");
    const antall = ref(0);
    const lagrer = ref(false);

    const lagre = async () => {
      lagrer.value = true;
      await fyllPåArtikler(props.artikkelNr, lokasjon.value, antall.value);
      lagrer.value = false;
      toast(
        `Fylte på ${antall.value} ${antall.value == 1 ? "artikkel" : "artikler"} med artikkelnummer ${
          props.artikkelNr
        } på lokasjon ${lokasjon.value}`
      );
      emit("lukk", true);
    };

    return { lokasjon, antall, lagre, lagrer };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.fyll-på-artikler {
  .skjema {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
}

.input-gruppe {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
