import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "bruker-tabell" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("input", {
      type: "text",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterValue) = $event)),
      placeholder: "Søk etter brukernavn, kundenummer eller lager"
    }, null, 512), [
      [_vModelText, _ctx.filterValue]
    ]),
    _createElementVNode("table", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", null, "Brukernavn"),
          _createElementVNode("th", null, "Kunde"),
          _createElementVNode("th", null, "Lager")
        ])
      ], -1)),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filtrerteBrukere, (bruker) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: bruker.id,
            onClick: ($event: any) => (_ctx.velgBruker(bruker)),
            class: "bruker-rad"
          }, [
            _createElementVNode("td", null, _toDisplayString(bruker.brukerNavn), 1),
            _createElementVNode("td", null, _toDisplayString(bruker.kundeNr), 1),
            _createElementVNode("td", null, _toDisplayString(bruker.lager), 1)
          ], 8, _hoisted_2))
        }), 128))
      ])
    ])
  ]))
}