<template>
  <div class="opprett-tokens">
    <div class="forrige-utsendelse" v-if="forrigeUtsendelse">
      <i class="las la-info-circle" />
      Forrige utsending skjedde <strong>{{ formaterDatoTid(forrigeUtsendelse.tidspunkt) }}</strong> av
      <strong>{{ forrigeUtsendelse.bruker }}</strong
      >.
    </div>
    <div class="options" v-if="!senderInn">
      <div class="option">
        <input id="epost" type="checkbox" v-model="sendEpost" />
        <label for="epost">Send e-post til alle kunder</label>
      </div>

      <div class="option">
        <input id="sms" type="checkbox" v-model="sendSms" />
        <label for="sms">Send SMS til alle kunder</label>
      </div>

      <div class="option">
        <input id="csv" type="checkbox" v-model="lastNedCsv" />
        <label for="csv">Last ned CSV</label>
      </div>
    </div>
    <Button @click="sendInn" type="primary" v-if="!senderInn">Opprett tokens</Button>

    <div v-if="senderInn" class="spinner-wrapper">
      <Spinner />
    </div>
  </div>
</template>

<script lang="ts">
import { hentForrigeUtsendelse, opprettTokensForAlleBgpKunder } from "@/clients/brukerClient";
import { getEnvironment } from "@/main";
import { ITokenUtsendelse } from "@/types";
import { eksporterTilCsv, formaterDatoTid, toast } from "@/utils";
import { bekreftFare } from "@/utils/dialogUtils";
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {},

  setup() {
    const sendEpost = ref(false);
    const sendSms = ref(false);
    const lastNedCsv = ref(true);

    const senderInn = ref(false);

    const forrigeUtsendelse = ref<ITokenUtsendelse>();

    hentForrigeUtsendelse().then((result) => (forrigeUtsendelse.value = result));

    const sendInn = async () => {
      const doSendInn = async () => {
        senderInn.value = true;
        const result = await opprettTokensForAlleBgpKunder(sendEpost.value, sendSms.value);

        if (lastNedCsv.value) {
          eksporterTilCsv(result, "reserveløsning-bruker-tokens.csv");
        }
        toast("Tokens opprettet for alle BGP-kunder");
        hentForrigeUtsendelse().then((result) => (forrigeUtsendelse.value = result));
        senderInn.value = false;
      };

      if (sendEpost.value || sendSms.value) {
        let melding =
          `Du er nå i ferd med å sende meldinger til alle aktive kunder av Bilglassgruppen – totalt ` +
          `ca. 1500. Er du sikker på at du vil gjøre dette?`;

        if (["Development", "Test"].includes(getEnvironment())) {
          melding += "\n\n(Du er i testmiljøet, og kan derfor trygt ignorere denne meldingen)";
        }

        bekreftFare(melding, {
          title: "Sender ut meldinger",
          onConfirm: async () => {
            await doSendInn();
          },
        });
      } else {
        await doSendInn();
      }
    };

    return { sendEpost, sendSms, lastNedCsv, senderInn, sendInn, forrigeUtsendelse, formaterDatoTid };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.options {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  margin-bottom: 1rem;
}

.option {
  margin-left: 0.5rem;
  display: flex;
  gap: 0.5rem;
}

.forrige-utsendelse {
  padding: 0.8rem;
  margin-bottom: 1rem;
  background: @blue-lighter;
  border-radius: 0.3rem;

  i {
    font-size: 1rem;
    margin-right: 0.3rem;
  }
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
}
</style>
