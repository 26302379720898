<template>
  <div>
    <input
      type="text"
      v-model="artikkelNr"
      @input="harSøkt = false"
      @keyup.enter="søkOppArtikler()"
      placeholder="Artikkelnummer"
    />
    <Button @click="søkOppArtikler()">Søk</Button>
    <div class="ingen-treff-info" v-if="harSøkt && !søker && artikler.length === 0">Ingen treff</div>
    <CenteredSpinner v-if="søker" class="søk-spinner" />
    <RuteVelger v-if="artikler.length > 0" :ruter="artikler" @velg-rute="velgArtikkel" />
  </div>
</template>

<script lang="ts">
import { søkEtterArtikler } from "@/clients/artikkelClient";
import { IArtikkel } from "@/types";
import { defineComponent, ref } from "vue";
import RuteVelger from "./RuteVelger.vue";

export default defineComponent({
  props: {},

  setup(props, context) {
    const artikkelNr = ref("");
    const artikler = ref<IArtikkel[]>([]);
    const harSøkt = ref(false);
    const søker = ref(false);

    const søkOppArtikler = async () => {
      søker.value = true;
      artikler.value = [];
      artikler.value = await søkEtterArtikler(artikkelNr.value);
      harSøkt.value = true;
      søker.value = false;
    };

    const velgArtikkel = (artikkel: IArtikkel) => {
      context.emit("legg-til-artikkel", artikkel);
    };

    return { artikkelNr, artikler, harSøkt, søkOppArtikler, velgArtikkel, søker };
  },

  components: { RuteVelger },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

input {
  margin-right: 1rem;
}

.ingen-treff-info {
  margin-top: 0.8rem;
  color: @red;
}

.søk-spinner {
  margin-top: 1rem;
}
</style>
