<template>
  <div class="infobox">
    <i class="icon las la-info-circle" />
    <p><slot /></p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {},

  setup() {
    return {};
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.infobox {
  display: flex;
  align-items: flex-start;
  gap: 0.8rem;

  padding: 1.2rem;
  background: @blue-lighter;
  border-radius: 0.5rem;

  .icon {
    font-size: 2rem;
  }

  p {
    margin: 0;
  }
}
</style>
