<template>
  <div class="wrapper">
    <label>Merke</label>
    <select v-model="valgtHovedgruppe" :disabled="hovedgrupper.length === 0" @change="hentUndergrupper()">
      <option v-for="hovedgruppe in hovedgrupper" :value="hovedgruppe" :key="hovedgruppe.hovedgruppeId">
        {{ hovedgruppe.beskrivelse }}
      </option>
    </select>

    <label>Modell</label>
    <select v-model="valgtUndergruppe" :disabled="undergrupper.length === 0" @change="hentÅrsmodeller()">
      <option v-for="undergruppe in undergrupper" :value="undergruppe" :key="undergruppe.undergruppeId">
        {{ undergruppe.beskrivelse }}
      </option>
    </select>

    <label>Årsmodell</label>
    <select v-model="valgtÅrsmodell" :disabled="årsmodeller.length === 0" @change="handleÅrsmodellChange()">
      <option v-for="årsmodell in årsmodeller" :value="årsmodell" :key="årsmodell.fra">
        {{ årsmodell.fra }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { IBilInfo, IHovedgruppe, IUndergruppe, IÅrsmodell } from "@/types";
import { defineComponent, ref } from "vue";
import {
  hentHovedgrupper as getHovedgrupper,
  hentUndergrupper as getUndergrupper,
  hentÅrsmodeller as getÅrsmodeller,
} from "@/clients/bilInfoClient";

export default defineComponent({
  props: {},

  setup(props, context) {
    const hovedgrupper = ref<IHovedgruppe[]>([]);
    const undergrupper = ref<IUndergruppe[]>([]);
    const årsmodeller = ref<IÅrsmodell[]>([]);

    const valgtHovedgruppe = ref<IHovedgruppe | undefined>();
    const valgtUndergruppe = ref<IUndergruppe | undefined>();
    const valgtÅrsmodell = ref<IÅrsmodell | undefined>();

    const handleÅrsmodellChange = () => {
      context.emit("update:årsmodell", valgtÅrsmodell.value);
      context.emit("update:bilInfo", {
        merke: valgtHovedgruppe.value?.beskrivelse,
        modell: valgtUndergruppe.value?.beskrivelse,
        årsmodellFra: valgtÅrsmodell.value?.fra,
      } as IBilInfo);
    };

    const hentHovedgrupper = () => {
      hovedgrupper.value = [];
      context.emit("update:årsmodell", undefined);
      getHovedgrupper().then((result) => (hovedgrupper.value = result));
    };

    const hentUndergrupper = () => {
      undergrupper.value = [];
      årsmodeller.value = [];
      context.emit("update:årsmodell", undefined);

      if (!valgtHovedgruppe.value) return;
      getUndergrupper(valgtHovedgruppe.value.hovedgruppeId).then((result) => (undergrupper.value = result));
    };

    const hentÅrsmodeller = () => {
      årsmodeller.value = [];

      if (!valgtUndergruppe.value) return;
      getÅrsmodeller(valgtUndergruppe.value.hovedgruppeId, valgtUndergruppe.value.undergruppeId).then(
        (result) => (årsmodeller.value = result)
      );
    };

    hentHovedgrupper();

    return {
      hovedgrupper,
      undergrupper,
      årsmodeller,
      valgtHovedgruppe,
      valgtUndergruppe,
      valgtÅrsmodell,
      hentUndergrupper,
      hentÅrsmodeller,
      handleÅrsmodellChange,
    };
  },
});
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
