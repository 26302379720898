<template>
  <div>
    <div class="søk-wrapper">
      <input
        type="text"
        v-model="søkestreng"
        placeholder="Søk etter artikkelnummer eller eurokode"
        @keydown.enter="søk()"
      />
    </div>
    <CenteredSpinner v-if="søker" />
    <table class="resultat" v-else-if="harSøkt && resultat.length > 0">
      <thead>
        <th>Artikkelnummer</th>
        <th>Eurokode</th>
        <th>Navn</th>
        <th>Egenskaper</th>
      </thead>
      <tbody>
        <tr
          v-for="artikkel in resultat"
          :key="artikkel.artikkelNummer"
          @click="velgArtikkel(artikkel)"
          class="artikkel-rad"
        >
          <td>
            {{ artikkel.artikkelNummer }}
          </td>
          <td>
            {{ artikkel.euroKoder.map((x) => x).join(", ")}}
          </td>
          <td>
            {{ artikkel.navn }}
          </td>
          <td>
            {{ artikkel.egenskaper.map((x) => x.beskrivelse).join(", ") }}
          </td>
        </tr>
      </tbody>
    </table>
    <p v-if="harSøkt && resultat.length === 0">Ingen resultater</p>
  </div>
</template>

<script lang="ts">
import { søkEtterArtikler } from "@/clients/artikkelClient";
import { IArtikkel } from "@/types";
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {},

  setup() {
    const søkestreng = ref("");
    const resultat = ref<IArtikkel[]>([]);
    const søker = ref(false);
    const harSøkt = ref(false);

    const søk = async () => {
      søker.value = true;
      resultat.value = await søkEtterArtikler(søkestreng.value);
      søker.value = false;
      harSøkt.value = true;
    };

    const router = useRouter();

    const velgArtikkel = (artikkel: IArtikkel) => {
      router.push(`/artikler/${artikkel.artikkelNummer}`);
    };

    return { søkestreng, resultat, søker, søk, velgArtikkel, harSøkt };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.søk-wrapper {
  display: flex;
  flex-direction: column;
}

.resultat {
  width: 100%;
}

.artikkel-rad {
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.07);
  }
}
</style>
