import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "kundevelger" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_KundeNummerTypeAhead = _resolveComponent("KundeNummerTypeAhead")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (!_ctx.modelValue)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Du har ikke valgt hvilken kunde du bestiller for."))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, "Du gjør bestillinger på vegne av " + _toDisplayString(_ctx.modelValue) + _toDisplayString(_ctx.kundeInfo ? ` - ${_ctx.kundeInfo.navn}` : "") + ".", 1)),
      (_ctx.modelValue && !_ctx.redigerer)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 2,
            class: "button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.redigerer = true))
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Endre")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    (_ctx.redigerer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_KundeNummerTypeAhead, {
            modelValue: _ctx.valgtKunde,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.valgtKunde) = $event)),
            class: "inputfelt"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_Button, {
            class: "button",
            disabled: !_ctx.modelValue,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.redigerer = false))
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Avbryt")
            ])),
            _: 1
          }, 8, ["disabled"])
        ]))
      : _createCommentVNode("", true)
  ]))
}