/* eslint-disable */

import authService from "./auth/authService";

export const get = async (url: string) => {
  const accessToken = await authService.getAccessToken();

  const options: RequestInit = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    method: "GET",
  };

  const response = await fetch(url, options);

  if (response.ok) {
    return decodeResponse(response);
  }

  await handleError(url, options, response);
};

export const post = async (url: string, body: any = undefined) => {
  const accessToken = await authService.getAccessToken();

  const options: RequestInit = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json-patch+json",
    },
    method: "POST",
    body: body && JSON.stringify(body),
  };

  const response = await fetch(url, options);

  if (response.ok) {
    return decodeResponse(response);
  }

  await handleError(url, options, response);
};

export const put = async (url: string, body: any) => {
  const accessToken = await authService.getAccessToken();

  const options: RequestInit = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json-patch+json",
    },
    method: "PUT",
    body: JSON.stringify(body),
  };

  const response = await fetch(url, options);

  if (response.ok) {
    return decodeResponse(response);
  }

  await handleError(url, options, response);
};

export const del = async (url: string) => {
  const accessToken = await authService.getAccessToken();

  const options: RequestInit = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    method: "DELETE",
  };

  const response = await fetch(url, options);

  if (response.ok) {
    return Promise.resolve();
  }

  await handleError(url, options, response);
};

const handleError = async (url: string, options: RequestInit, response: Response) => {
  const content = await response.json();

  const error = content.error ?? `En feil oppstod ved ${options.method} av ${url}`;
  const visTilBruker = !!content.visTilBruker;

  throw new CrudError(error, response.status, visTilBruker);
};

const decodeResponse = async (response: Response) => {
  try {
    return await response.json();
  } catch {
    return null;
  }
};

export class CrudError extends Error {
  visTilBruker: boolean = false;
  status: number = 0;

  constructor(msg: string, status: number, visTilBruker: boolean = false) {
    super(msg);
    this.visTilBruker = visTilBruker;
    this.status = status;
  }
}
