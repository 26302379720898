<template>
  <div class="spinner-container" v-if="!lastet"><Spinner /></div>
  <Plukkliste v-else :bestilling="bestilling" :transportRute="transportRute" />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { Plukkliste } from "@/components";
import { IBestilling, ITransportRute } from "@/types";
import { hentBestilling, hentRuteForBestilling } from "@/clients/bestillingClient";

export default defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const lastet = ref(false);
    const bestilling = ref<IBestilling>();
    const transportRute = ref<ITransportRute | undefined>();

    const init = async () => {
      const [hentetBestilling, hentetRute] = await Promise.all([
        hentBestilling(props.id),
        hentRuteForBestilling(props.id),
      ]);

      hentetBestilling.linjer = hentetBestilling.linjer.sort((a, b) => a.hovedlokasjon.localeCompare(b.hovedlokasjon));
      transportRute.value = hentetRute;
      bestilling.value = hentetBestilling;
      lastet.value = true;
    };

    init();

    return { bestilling, transportRute, lastet };
  },

  components: { Plukkliste },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.spinner-container {
  display: flex;
  justify-content: center;
}
</style>
