<template>
  <div class="rutesøk-view">
    <KundeVelger v-if="isAdmin || isKundesenter" v-model="valgtKunde" class="kundevelger" />
    <Button v-if="!visNyttRutesøk" type="primary" @click="visNyttRutesøk = true">Nytt rutesøk</Button>
    <h2 v-if="visNyttRutesøk">Nytt rutesøk</h2>
    <NyttRutesøk
      v-if="visNyttRutesøk"
      class="nytt-rutesøk"
      :valgtKunde="valgtKunde"
      @gjennomførtSøk="gjennomførtSøk($event)"
      @opprettet="
        visNyttRutesøk = false;
        hentRutesøk();
      "
    />
    <CenteredSpinner v-if="henterRutesøk" />
    <p v-if="gjennomførtSøkRegNr && visGjennomførtSøkMelding" class="gjennomførtSøkTekst">
      Rutesøk ble gjennomført for {{ gjennomførtSøkRegNr }}. Last inn siden på nytt om litt.
    </p>
    <h2>Mine rutesøk</h2>
    <RutesøkListe v-if="!henterRutesøk" :rutesøk="rutesøk" />
    <SideVelger
      v-if="!henterRutesøk"
      v-model="side"
      v-model:antallPerSide="antallPerSide"
      :totaltAntall="totaltAntall"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { KundeVelger, RutesøkListe, NyttRutesøk } from "@/components";
import authService from "@/utils/auth/authService";
import { IRutesøk, IRutesøkFilter } from "@/types";
import { hentRutesøk as getRutesøk } from "@/clients/rutesøkClient";

export default defineComponent({
  props: {},

  setup() {
    const valgtKunde = ref("");
    const henterRutesøk = ref(false);
    const visNyttRutesøk = ref(false);
    const visGjennomførtSøkMelding = ref(false);
    const gjennomførtSøkRegNr = ref("");

    const side = ref(1);
    const antallPerSide = ref(10);
    const totaltAntall = ref(0);
    const rutesøk = ref<IRutesøk[]>([]);

    const isKundesenter = ref(false);
    const isAdmin = ref(false);
    authService.hasRole("Admin").then((result) => (isAdmin.value = result));
    authService.hasRole("Kundesenter").then((result) => (isKundesenter.value = result));

    const gjennomførtSøk = (regNr: string) => {
      visGjennomførtSøkMelding.value = true;
      gjennomførtSøkRegNr.value = regNr;
      setTimeout(() => {
        visGjennomførtSøkMelding.value = false;
      }, 10000);
    };

    const hentRutesøk = async (filter: IRutesøkFilter = {}) => {
      henterRutesøk.value = true;
      filter = { ...filter, kunde: valgtKunde.value, page: side.value - 1, pageSize: antallPerSide.value };
      const rutesøkMedSideInfo = await getRutesøk(filter);
      totaltAntall.value = rutesøkMedSideInfo.totaltAntall;
      rutesøk.value = rutesøkMedSideInfo.rutesøk;
      henterRutesøk.value = false;
    };

    watch([valgtKunde], hentRutesøk);
    watch([side, antallPerSide], hentRutesøk);
    hentRutesøk();

    return {
      valgtKunde,
      rutesøk,
      henterRutesøk,
      isAdmin,
      isKundesenter,
      visNyttRutesøk,
      hentRutesøk,
      side,
      antallPerSide,
      totaltAntall,
      gjennomførtSøk,
      visGjennomførtSøkMelding,
      gjennomførtSøkRegNr,
    };
  },

  components: { KundeVelger, RutesøkListe, NyttRutesøk },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.kundevelger {
  margin-bottom: 1rem;
}

.nytt-rutesøk {
  max-width: 40rem;
  margin-bottom: 3rem;
}

.gjennomførtSøkTekst {
  color: red;
}
</style>
