<template>
  <TypeAhead
    :searchFunction="gjørTypeAheadSøk"
    :value="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
    placeholder="Søk etter kunde"
  />
</template>

<script lang="ts">
import { søkEtterKunder } from "@/clients/kundeClient";
import { defineComponent } from "vue";
import { ITypeAheadResult } from "../_basis/TypeAhead.vue";

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },

  setup() {
    const gjørTypeAheadSøk = async (søkestreng: string): Promise<ITypeAheadResult[]> => {
      const result = await søkEtterKunder(søkestreng);

      return result.map((kunde) => ({
        value: kunde.kundeNummer,
        key: kunde.kundeNummer,
        name: `${kunde.kundeNummer} – ${kunde.navn}`,
      }));
    };

    return { gjørTypeAheadSøk };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
</style>
