<template>
  <div class="opprett-lokasjon">
    <h2>Opprett lokasjon</h2>
    <CenteredSpinner v-if="lagrer" />
    <form class="skjema" v-else @submit.prevent="lagre">
      <div class="input-gruppe">
        <label for="lokasjon"> Lokasjon </label>
        <input type="text" id="lokasjon" v-model="lokasjon" placeholder="Navn på lokasjon" />
      </div>
      <div class="input-gruppe">
        <label for="antall">Antall</label>
        <input type="number" id="antall" v-model="antall" placeholder="Antall" />
      </div>
      <Button type="primary" @click="lagre">Lagre</Button>
    </form>
  </div>
</template>

<script lang="ts">
import { opprettLokasjon } from "@/clients/lokasjonClient";
import { toast } from "@/utils";
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    artikkelNr: {
      type: String,
      required: true,
    },
    lager: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const lokasjon = ref("");
    const antall = ref(0);
    const lagrer = ref(false);

    const lagre = async () => {
      lagrer.value = true;
      await opprettLokasjon(props.artikkelNr, lokasjon.value, antall.value, props.lager);
      lagrer.value = false;
      toast(`Opprettet lokasjon ${lokasjon.value} for artikkel ${props.artikkelNr} med saldo ${antall.value}`);
      emit("lukk", true);
    };

    return { lokasjon, antall, lagre, lagrer };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.opprett-lokasjon {
  .skjema {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
}

.input-gruppe {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
