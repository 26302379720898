import { createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect } from "vue"

const _hoisted_1 = { class: "bestilling-filter" }
const _hoisted_2 = { class: "input-gruppe" }
const _hoisted_3 = { class: "input-gruppe" }
const _hoisted_4 = {
  key: 0,
  class: "input-gruppe"
}
const _hoisted_5 = { class: "input-gruppe" }
const _hoisted_6 = ["value"]
const _hoisted_7 = ["value"]
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "input-gruppe" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[14] || (_cache[14] = _createElementVNode("label", { for: "bestillingId" }, "Bestilling-ID", -1)),
      _withDirectives(_createElementVNode("input", {
        id: "bestillingId",
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter.bestillingId) = $event)),
        placeholder: "Bestilling-ID",
        onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.søk()), ["enter"])),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.søk()))
      }, null, 544), [
        [_vModelText, _ctx.filter.bestillingId]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[15] || (_cache[15] = _createElementVNode("label", { for: "ordreNummer" }, "Ordrenummer", -1)),
      _withDirectives(_createElementVNode("input", {
        id: "ordreNummer",
        type: "text",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filter.ordreNummer) = $event)),
        placeholder: "Ordrenummer",
        onKeydown: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.søk()), ["enter"])),
        onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.søk()))
      }, null, 544), [
        [_vModelText, _ctx.filter.ordreNummer]
      ])
    ]),
    (_ctx.isAdmin || _ctx.isKundesenter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _cache[16] || (_cache[16] = _createElementVNode("label", { for: "lager" }, "Lager", -1)),
          _withDirectives(_createElementVNode("input", {
            id: "lager",
            type: "text",
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.filter.lager) = $event)),
            placeholder: "Lager",
            onKeydown: _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.søk()), ["enter"])),
            onBlur: _cache[8] || (_cache[8] = ($event: any) => (_ctx.søk()))
          }, null, 544), [
            [_vModelText, _ctx.filter.lager]
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _cache[18] || (_cache[18] = _createElementVNode("label", { for: "status" }, "Status", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.bestillingStatus) = $event)),
        id: "status",
        onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.søk()))
      }, [
        _cache[17] || (_cache[17] = _createElementVNode("option", { value: undefined }, null, -1)),
        _createElementVNode("option", {
          value: _ctx.BestillingStatus.åpen
        }, _toDisplayString(_ctx.bestillingStatusTilTekst(_ctx.BestillingStatus.åpen)), 9, _hoisted_6),
        _createElementVNode("option", {
          value: _ctx.BestillingStatus.underPlukking
        }, _toDisplayString(_ctx.bestillingStatusTilTekst(_ctx.BestillingStatus.underPlukking)), 9, _hoisted_7),
        _createElementVNode("option", {
          value: _ctx.BestillingStatus.plukket
        }, _toDisplayString(_ctx.bestillingStatusTilTekst(_ctx.BestillingStatus.plukket)), 9, _hoisted_8)
      ], 544), [
        [_vModelSelect, _ctx.bestillingStatus]
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _cache[19] || (_cache[19] = _createElementVNode("label", { for: "kunde" }, "Kunde", -1)),
      _withDirectives(_createElementVNode("input", {
        id: "kunde",
        type: "text",
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.filter.kunde) = $event)),
        placeholder: "Kundenummer",
        onKeydown: _cache[12] || (_cache[12] = _withKeys(($event: any) => (_ctx.søk()), ["enter"])),
        onBlur: _cache[13] || (_cache[13] = ($event: any) => (_ctx.søk()))
      }, null, 544), [
        [_vModelText, _ctx.filter.kunde]
      ])
    ])
  ]))
}