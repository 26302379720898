import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bestilling-detaljer" }
const _hoisted_2 = { class: "skjema-felt" }
const _hoisted_3 = { class: "skjema-felt" }
const _hoisted_4 = {
  key: 0,
  class: "artikkel-tabell"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("h1", null, "Bestilling", -1)),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("label", null, "Bestilling-ID", -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.bestilling.id), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("label", null, "Dato", -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.formaterDatoTid(_ctx.bestilling.opprettet)), 1)
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Bestilte artikler", -1)),
      (_ctx.bestilling.linjer.length > 0)
        ? (_openBlock(), _createElementBlock("table", _hoisted_4, [
            _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
              _createElementVNode("th", null, "Artikkelnummer"),
              _createElementVNode("th", null, "Navn"),
              _createElementVNode("th", null, "Bruttopris"),
              _createElementVNode("th", null, "Antall"),
              _createElementVNode("th", null, "Sum")
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bestilling.linjer, (linje) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: linje.id
                }, [
                  _createElementVNode("td", null, _toDisplayString(linje.artikkelNr), 1),
                  _createElementVNode("td", null, [
                    _createTextVNode(_toDisplayString(linje.artikkel.navn) + " ", 1),
                    (linje.merknad)
                      ? (_openBlock(), _createElementBlock("em", _hoisted_5, [
                          _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode(" Merknad: " + _toDisplayString(linje.merknad), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("td", null, _toDisplayString(linje.artikkel.bruttoPris) + " kr", 1),
                  _createElementVNode("td", null, _toDisplayString(linje.antall), 1),
                  _createElementVNode("td", null, _toDisplayString(linje.antall * linje.artikkel.bruttoPris) + " kr", 1)
                ]))
              }), 128))
            ])
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_6, "Ingen bestilte artikler"))
    ])
  ]))
}