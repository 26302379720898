<template>
  <div class="kunde-info">
    <div class="rad">
      <div class="felt-gruppe">
        <label>Kundenummer</label>
        <p>{{ kunde.kundeNummer }}</p>
      </div>

      <div class="felt-gruppe">
        <label>Navn</label>
        <p>{{ kunde.navn }}</p>
      </div>

      <div class="felt-gruppe">
        <label>Status</label>
        <p>{{ kunde.status }}</p>
      </div>
    </div>

    <div class="rad">
      <div class="felt-gruppe">
        <label>Adresse</label>
        <p>{{ kunde.adresselinje1 }}</p>
        <p>{{ kunde.postNr }} {{ kunde.postSted }}</p>
      </div>

      <div class="felt-gruppe">
        <label>Telefon</label>
        <p>{{ kunde.telefonNummer1 }}</p>
        <p v-if="kunde.telefonNummer1">{{ kunde.telefonNummer2 }}</p>
      </div>

      <div class="felt-gruppe">
        <label>Deres referanse</label>
        <p>{{ kunde.deresReferanse }}</p>
      </div>
    </div>

    <div class="rad">
      <div class="felt-gruppe">
        <label>Lager</label>
        <p>{{ kunde.kundesLager }}</p>
      </div>

      <div class="felt-gruppe">
        <label>Rute</label>
        <p>{{ kunde.rute?.beskrivelse ?? "" }}</p>
      </div>

      <div class="felt-gruppe">
        <label>Avganger</label>
        <p>{{ kunde.rute?.avganger.map((a) => formaterKlokkeslett(a.avgangTidspunkt)).join(", ") }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { IKunde } from "@/types";
import { formaterKlokkeslett } from "@/utils";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    kunde: Object as PropType<IKunde>,
  },

  setup() {
    return { formaterKlokkeslett };
  },
});
</script>

<style lang="less" scoped>
.kunde-info {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.rad {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
}
</style>
