<template>
  <nav class="nav">
    <router-link v-if="isAdmin || isKundesenter || isKunde" to="/rutesok">Rutesøk</router-link>
    <router-link v-if="isAdmin || isKundesenter || isKunde" to="/bestill">Bestill varer</router-link>
    <router-link v-if="isKunde" to="/mine-bestillinger">Mine bestillinger</router-link>
    <router-link v-if="isAdmin || isKundesenter || isKunde" to="/oppdrag">Oppdrag</router-link>
    <router-link v-if="isLager || isKundesenter || isAdmin" to="/apne-bestillinger">Åpne bestillinger</router-link>
    <router-link v-if="isLager || isKundesenter || isAdmin" to="/bestillinger">Bestillingsarkiv</router-link>
    <router-link v-if="isLager || isKundesenter || isAdmin" to="/artikler">Artikler</router-link>
    <router-link v-if="isLager || isKundesenter || isAdmin" to="/kunder">Kunder</router-link>
    <UnderMeny
      v-if="isAdmin"
      tittel="Admin"
      :aktiv="aktivUndermeny == 'admin'"
      @open="aktivUndermeny = 'admin'"
      @close="aktivUndermeny = ''"
    >
      <router-link v-if="isAdmin" to="/brukere">Brukere</router-link>
      <router-link v-if="isAdmin" to="/driftsetting">Driftsetting</router-link>
      <router-link v-if="isDeveloper" to="/developer">Utviklerverktøy</router-link>
      <router-link v-if="isAdmin" to="/eksport">Eksporter data</router-link>
    </UnderMeny>
    <router-link to="/min-side" class="min-side">{{ userProfile?.name ?? "Min side" }}</router-link>
    <router-link to="/auth/logout" class="logg-ut">Logg ut</router-link>
  </nav>
</template>

<script lang="ts">
import authService from "@/utils/auth/authService";
import { Profile } from "oidc-client";
import { defineComponent, ref } from "vue";
import UnderMeny from "./UnderMeny.vue";

export default defineComponent({
  props: {},

  setup() {
    const aktivUndermeny = ref<"admin" | "">("");

    const isAdmin = ref(false);
    const isDeveloper = ref(false);
    const isKunde = ref(false);
    const isKundesenter = ref(false);
    const isLager = ref(false);
    const userProfile = ref<Profile>();

    authService.hasRole("Admin").then((result) => (isAdmin.value = result));
    authService.hasRole("Developer").then((result) => (isDeveloper.value = result));
    authService.hasRole("Kunde").then((result) => (isKunde.value = result));
    authService.hasRole("Kundesenter").then((result) => (isKundesenter.value = result));
    authService.hasRole("Lager").then((result) => (isLager.value = result));
    authService.getUser().then((result) => (userProfile.value = result));

    return { isAdmin, isDeveloper, isKunde, isKundesenter, isLager, userProfile, aktivUndermeny };
  },

  components: {
    UnderMeny,
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.nav {
  padding: 0 3rem;
  background-color: @blue-dark;

  display: flex;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    padding: 1rem 0.5rem;
  }

  a:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.min-side {
  margin-left: auto;
}
</style>
