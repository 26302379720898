import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "errors"
}
const _hoisted_2 = { class: "input-gruppe" }
const _hoisted_3 = { class: "input-gruppe" }
const _hoisted_4 = { class: "input-gruppe" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("form", {
    class: "endre-passord",
    onSubmit: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.utførEndrePassord()), ["prevent"]))
  }, [
    (_ctx.errors.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
            return (_openBlock(), _createElementBlock("li", { key: error }, _toDisplayString(error), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _cache[7] || (_cache[7] = _createElementVNode("label", { for: "passord" }, "Gammelt passord", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "password",
        id: "gammeltPassord",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.gammeltPassord) = $event)),
        placeholder: "Gammelt passord",
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.lagret = false))
      }, null, 544), [
        [_vModelText, _ctx.gammeltPassord]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[8] || (_cache[8] = _createElementVNode("label", { for: "passord" }, "Nytt passord", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "password",
        id: "passord",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.passord) = $event)),
        placeholder: "Nytt passord",
        onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.lagret = false))
      }, null, 544), [
        [_vModelText, _ctx.passord]
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[9] || (_cache[9] = _createElementVNode("label", { for: "gjentaPassord" }, "Gjenta passord", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "password",
        id: "gjentaPassord",
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.gjentaPassord) = $event)),
        placeholder: "Gjenta passord"
      }, null, 512), [
        [_vModelText, _ctx.gjentaPassord]
      ])
    ]),
    _createVNode(_component_Button, {
      type: "primary",
      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.utførEndrePassord()))
    }, {
      default: _withCtx(() => _cache[10] || (_cache[10] = [
        _createTextVNode("Lagre")
      ])),
      _: 1
    })
  ], 32))
}