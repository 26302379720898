<template>
  <div class="loader"></div>
</template>

<style lang="less" scoped>
.loader {
  border: 0.8rem solid #f3f3f3;
  border-top: 0.8rem solid #a0a0a0;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  animation: spin 2s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
