import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[0] || (_cache[0] = _createElementVNode("label", null, "Brukernavn", -1)),
    _createElementVNode("p", null, _toDisplayString(_ctx.bruker.brukerNavn), 1),
    _cache[1] || (_cache[1] = _createElementVNode("label", null, "E-post", -1)),
    _createElementVNode("p", null, _toDisplayString(_ctx.bruker.epost), 1),
    _cache[2] || (_cache[2] = _createElementVNode("label", null, "Telefonnummer", -1)),
    _createElementVNode("p", null, _toDisplayString(_ctx.bruker.telefonNummer), 1),
    _cache[3] || (_cache[3] = _createElementVNode("label", null, "Lager", -1)),
    _createElementVNode("p", null, _toDisplayString(_ctx.bruker.lager), 1),
    _cache[4] || (_cache[4] = _createElementVNode("label", null, "Kundenummer", -1)),
    _createElementVNode("p", null, _toDisplayString(_ctx.bruker.kundeNr), 1)
  ]))
}