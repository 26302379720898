<template>
  <div class="mine-bestillinger">
    <Modal :aktiv="!!valgtBestilling" @lukk="valgtBestilling = null">
      <BestillingDetaljer v-if="valgtBestilling" :bestilling="valgtBestilling" />
    </Modal>
    <table>
      <thead>
        <th>Bestilling-ID</th>
        <th>Dato</th>
        <th>Antall varer</th>
      </thead>
      <tbody>
        <tr
          v-for="bestilling in bestillinger"
          :key="bestilling.id"
          class="bestilling-rad"
          @click="valgtBestilling = bestilling"
        >
          <td>{{ bestilling.id }}</td>
          <td>{{ formaterDatoTid(bestilling.opprettet) }}</td>
          <td>{{ bestilling.linjer.length }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { IBestilling } from "@/types";
import { formaterDatoTid } from "@/utils";
import { defineComponent, PropType, ref } from "vue";
import BestillingDetaljer from "./BestillingDetaljer.vue";

export default defineComponent({
  props: {
    bestillinger: {
      type: Array as PropType<IBestilling[]>,
      required: true,
    },
  },

  setup() {
    const valgtBestilling = ref<IBestilling | null>(null);

    return { formaterDatoTid, valgtBestilling };
  },
  components: { BestillingDetaljer },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.mine-bestillinger {
  table {
    width: 100%;
  }
}

.bestilling-rad {
  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.07);
  }
}
</style>
