import { IOpprettet } from "./generelt";

export enum KundeStatus {
  foreløpig = 10,
  temporær = 11,
  feilVerdi = 12,
  definitiv = 20,
  utgått = 90,
}

export interface ILager {
  kundeNummer: string;
  lagerNummer: string;
  navn: string;
  adresselinje1: string;
  postNr: string;
  postSted: string;
  telefonNummer1: string;
  telefonNummer2: string;
}

export interface IKunde {
  kundeNummer: string;
  status: KundeStatus;
  navn: string;
  adresselinje1: string;
  postNr: string;
  postSted: string;
  telefonNummer1: string;
  telefonNummer2: string;
  deresReferanse: string;
  kundesLager: string;
  leveringsmåte: string;
  rute?: ITransportRute;
}

export interface IHovedgruppe {
  hovedgruppeId: number;
  beskrivelse: string;
  type?: string;
}

export interface IUndergruppe {
  hovedgruppeId: number;
  undergruppeId: number;
  beskrivelse: string;
  modell?: string;
  dører?: string;
  karosseri?: string;
}

export interface IÅrsmodell {
  hovedgruppeId: number;
  undergruppeId: number;
  fra: string;
  til: string;
}

export enum ArtikkelStatus {
  malArtikkel = 5,
  forhåndsArtikkel = 10,
  artikkelErstattes = 15,
  frigitt = 20,
  utgående = 50,
  utgått = 90,
}

export interface IArtikkel {
  artikkelNummer: string;
  euroKoder: string[];
  navn: string;
  status: ArtikkelStatus;
  bruttoPris: number;
  egenskaper: IEgenskap[];
}

export interface ILagerSaldo {
  artikkelNr: string;
  lager: string;
  status: string;
  lokasjon: string;
  saldo: number;
  allokert: number;
}

export interface IHovedLokasjon {
  artikkelNummer: string;
  lager: string;
  hovedokasjon: string;
  årsbehov: number;
}

export interface IFølgeartikkel {
  artikkel: IArtikkel;
  antall: number;
  sekvens: number;
}

export interface IHandlekurvLinje {
  linjeId: number;
  artikkel: IArtikkel;
  antall: number;
  merknad: string;
  følgeartikler: IFølgeartikkel[];
}

export interface INyBestillingLinje {
  artikkelNr: string;
  antall: number;
  merknad: string;
}

export interface INyBestilling {
  kundeNr: string;
  lager: string;
  linjer: INyBestillingLinje[];
}

export interface IEgenskap {
  id: number;
  type: string;
  kode: string;
  rekkefølge: number;
  beskrivelse: string;
}

export interface IBestillingLinje {
  id: number;
  artikkelNr: string;
  artikkel: IArtikkel;
  antall: number;
  merknad: string;
  hovedlokasjon: string;
  lagerStatus: LagerStatus;
}

export interface IBestilling extends IOpprettet {
  id: number;
  ordreNummer?: string;
  kundeNr: string;
  lager: string;
  leveringsmåte: string;
  status: BestillingStatus;
  linjer: IBestillingLinje[];
  lagerStatus: LagerStatus;
  kunde: IKunde;
  rute: ITransportRute;
  opprettetFraRestOrdre: boolean;
  ønsketLeveringsDato?: Date;
}

export interface IBestillingerMedSideInfo {
  bestillinger: IBestilling[];
  totaltAntall: number;
  side: number;
}

export interface IBestillingMedKunde extends IBestilling {
  kunde: IKunde;
}

export interface IBestillingMedAvgang extends IBestillingMedKunde {
  avganger: IAvgang[];
}

export interface IBestillingFilter {
  lager?: string;
  status?: BestillingStatus[];
  kunde?: string;
  bestillingId?: number;
  ordreNummer?: string;
  restOrdre?: boolean;
  sorterSynkende?: boolean;
  sorterEtter?: BestillingSortering;
  lagerStatus?: LagerStatus[];
}

export interface IAvgang {
  rute: number;
  avgangId: string;
  avgangGyldigTil: string;
  avgangTidspunkt: string;
}

export interface ITransportRute {
  rute: string;
  beskrivelse: string;
  avganger: IAvgang[];
}

export interface IBilInfo {
  regNr: string;
  chassisNr: string;
  merke: string;
  modell: string;
  årsmodellFra: string;
}

export interface IBrukerOpprettelseToken {
  token: string;
  kunde: string;
  gyldigTil: string;
  brukt: boolean;
}

export interface ITokenUtsendelse {
  bruker: string;
  tidspunkt: Date;
}

export interface IImportFil {
  filnavn: string;
  erSuppleringsfil: boolean;
}

export enum BestillingStatus {
  ukjent = 0,
  åpen = 1,
  underPlukking = 4,
  plukket = 2,
  sendt = 3,
}

export const BestillingStatusTekst: Map<number, string> = new Map<number, string>([
  [BestillingStatus.ukjent, "Ukjent"],
  [BestillingStatus.åpen, "Åpen"],
  [BestillingStatus.underPlukking, "Under plukking"],
  [BestillingStatus.plukket, "Plukket"],
  [BestillingStatus.sendt, "Sendt"],
]);

export enum RuteType {
  ukjent = 0,
  frontrute = 1,
  siderute = 2,
  bakrute = 3,
  takrute = 4,
}

export enum LagerStatus {
  ukjent = 0,
  ok = 1,
  overallokert = 2,
  utilgjengeligPåHovedlokasjon = 3,
  tomtPåLager = 4,
}

export enum BestillingSortering {
  default = 0,
  nesteAvgang = 1,
  ønsketLeveringstid = 2,
}

export interface IRutesøk {
  id: number;
  ticketId: number;
  bilInfo: IRutesøkBilInfo;
  svar?: IRutesøkSvar;
}

export interface IRutesøkMedSideInfo {
  rutesøk: IRutesøk[];
  side: number;
  totaltAntall: number;
}

export interface IRutesøkBilInfo {
  regNr: string;
  chassisNummer: string;
  merke: string;
  modell: string;
  årsmodell: string;
  ruteType: RuteType;
}

export interface IRutesøkSvar {
  opprettet: Date;
  resultater: IRutesøkSvarResultat[];
  originaltArtikkelNummer: string[];
  eurokode: string[];
  kalibreringType: KalibreringType;
  kalibreringTid: number;
  kalibreringKommentar?: string;
  ekstraInformasjon?: string;
}

export interface IRutesøkSvarResultat {
  eurokode: string[];
  originaltArtikkelNummer: string[];
  artikkel?: IArtikkel;
}

export enum KalibreringType {
  ikkeValgt = 0,
  statisk = 10,
  dynamisk = 20,
  statiskOgDynamisk = 30,
  usikkert = 40,
  nei = 50,
}

export interface IRutesøkFilter {
  kunde?: string;
  page?: number;
  pageSize?: number;
}


export enum OppdragStatus {
  ikkeBekreftet = 0,
  avtalt = 10,
  mottatt = 20,
  påbegynt = 30,
  innlimt = 40,
  klarTilHenting = 50,
  levert = 60,
}

export interface IOppdrag {
  oppdragNr: number;
  regNr: string;
  verkstedNr: string;
  verkstedDato: Date;
  status: OppdragStatus;
  kontaktperson: IOppdragPerson;
  forsikringstaker: IOppdragPerson;
  bilfører: IOppdragPerson;
  linjer: IOppdragLinje[];
}

export interface IOppdragLinje {
  oppdragNr: number;
  linjeNr: number;
  artikkelNr: string;
  artikkelBetegnelse: string;
  antall: number;
  artikkelGruppe: string;
}
export interface IOppdragPerson {
  navn: string;
  telefon: string;
  epost: string;
  adresse: string;
  postNrOgSted: string;
}

export const oppdragStatusBeskrivelse = new Map<OppdragStatus, string>([
  [OppdragStatus.ikkeBekreftet, "Ikke bekreftet"],
  [OppdragStatus.avtalt, "Avtalt"],
  [OppdragStatus.mottatt, "Mottatt"],
  [OppdragStatus.påbegynt, "Påbegynt"],
  [OppdragStatus.innlimt, "Innlimt"],
  [OppdragStatus.klarTilHenting, "Klar til henting"],
  [OppdragStatus.levert, "Levert"],
]);

export const artikkelGruppeBeskrivelse = new Map<string, string>([
  ["01", "Frontrute"],
  ["04", "Frontrute"],
  ["02", "Siderute"],
  ["05", "Siderute"],
  ["03", "Bakrute"],
  ["06", "Bakrute"],
  ["07", "Frontrute Buss"],
  ["10", "Frontrute Bobil"],
  ["11", "Frontrute Lastebil"],
  ["12", "Siderute Bakrute Buss"],
  ["09", "Glasstak"],
  ["19", "Verktøy"],
  ["31", "Frakt"],
  ["32", "Solfilm"],
  ["50", "Reparasjon"],
  ["27", "Kalkulert lim"],
  ["14", "Limprodukter"],
  ["28", "Montering"],
  ["29", "Viskere"],
  ["15", "Viskere 1pk"],
  ["16", "Viskere 2pk"],
  ["30", "Diverse"],
  ["51", "Egenandel"],
]);