<template>
  <div>
    <h1>Artikkelsøk</h1>
    <ArtikkelSøk />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ArtikkelSøk } from "@/components";

export default defineComponent({
  props: {},

  setup() {
    return {};
  },

  components: { ArtikkelSøk },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
</style>
