import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spinner-container"
}
const _hoisted_2 = {
  key: 1,
  class: "wrapper"
}
const _hoisted_3 = { class: "knapper-container" }
const _hoisted_4 = { class: "roller-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_ButtonRouterLink = _resolveComponent("ButtonRouterLink")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Bruker = _resolveComponent("Bruker")!
  const _component_TilbakestillPassord = _resolveComponent("TilbakestillPassord")!
  const _component_BrukerRoller = _resolveComponent("BrukerRoller")!

  return (!_ctx.lastet)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Spinner)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _cache[4] || (_cache[4] = _createElementVNode("h1", null, "Brukerdetaljer", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ButtonRouterLink, {
            to: `/brukere/${_ctx.brukerId}/rediger`
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Rediger")
            ])),
            _: 1
          }, 8, ["to"]),
          _createVNode(_component_Button, {
            type: "danger",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.slettBruker()))
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Slett")
            ])),
            _: 1
          })
        ]),
        _createVNode(_component_Bruker, { bruker: _ctx.bruker }, null, 8, ["bruker"]),
        _cache[5] || (_cache[5] = _createElementVNode("h2", null, "Tilbakestill passord", -1)),
        _createVNode(_component_TilbakestillPassord, {
          brukerId: _ctx.bruker.id
        }, null, 8, ["brukerId"]),
        _createElementVNode("div", _hoisted_4, [
          _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Roller", -1)),
          _createVNode(_component_BrukerRoller, {
            brukerId: _ctx.bruker.id
          }, null, 8, ["brukerId"])
        ])
      ]))
}