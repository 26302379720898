<template>
  <div>
    <h2>Handlekurv</h2>
    <ul class="vareliste">
      <li class="linje" v-for="linje in linjer" :key="linje.linjeId">
        <div class="hovedlinje">
          <div class="hovedlinje-rad">
            <span>
              <strong>{{ linje.artikkel.artikkelNummer }}:</strong>
              {{ linje.artikkel.navn }}</span
            >
            <input
              class="antall"
              type="number"
              v-model="linje.antall"
              min="0"
              @change="linje.antall = Math.max(0, linje.antall)"
            />
            <span class="pris">{{ (linje.antall * linje.artikkel.bruttoPris).toFixed(2) }} kr</span>
            <button @click="$emit('slett-linje', linje)" class="slett-knapp"><i class="las la-trash" /></button>
          </div>
          <div class="hovedlinje-rad">
            <label>Merknad:</label>
            <input type="text" v-model="linje.merknad" placeholder="F.eks. registreringsnummer" maxlength="250" />
          </div>
        </div>
        <ul v-if="linje.følgeartikler.length > 0" class="følgeartikler">
          <li class="rad" v-for="(følgeartikkel, j) in linje.følgeartikler" :key="følgeartikkel.sekvens">
            <span>
              <strong>{{ følgeartikkel.artikkel.artikkelNummer }}:</strong>
              {{ følgeartikkel.artikkel.navn }}</span
            >
            <input
              class="antall"
              type="number"
              v-model="linje.følgeartikler[j].antall"
              min="0"
              @change="linje.følgeartikler[j].antall = Math.max(0, linje.følgeartikler[j].antall)"
            />
            <span class="pris">{{ (følgeartikkel.antall * følgeartikkel.artikkel.bruttoPris).toFixed(2) }} kr</span>
          </li>
        </ul>
      </li>
    </ul>
    <div class="bestill-wrapper">
      <Button @click="$emit('opprett-bestilling')" :disabled="linjer.length === 0" type="primary" class="bestill-knapp"
        >Bestill varer</Button
      >
    </div>
  </div>
</template>

<script lang="ts">
import { IHandlekurvLinje } from "@/types";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    linjer: {
      type: Array as PropType<IHandlekurvLinje[]>,
      required: true,
    },
    valgtKunde: {
      type: String,
      required: false,
    },
  },

  setup() {
    return {};
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.vareliste {
  list-style: none;
  padding: 0;
}

.hovedlinje {
  padding: 0.8rem 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.hovedlinje-rad {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.rad {
  padding: 0.7rem 0.4rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.linje:not(:first-child) {
  border-top: 1px solid @black80;
}

.følgeartikler {
  padding: 0;
  margin-left: 2rem;
  margin-bottom: 0.5rem;
  border-top: 1px dotted @black80;

  .rad:not(:first-child) {
    border-top: 1px dotted @black80;
  }
}

.antall {
  width: 2rem;
  margin-left: auto;
}

.pris {
  min-width: 4.3rem;
  text-align: right;
}

.slett-knapp {
  background: none;
  border: none;
  font-size: 1.1rem;
  color: @red;
  cursor: pointer;
}

.bestill-wrapper {
  display: flex;
  justify-content: flex-end;
}
</style>
