<template>
  <div class="flytt-artikler">
    <h2>Flytt artikler</h2>
    <CenteredSpinner v-if="lagrer" />
    <form class="skjema" v-else @submit.prevent="lagre">
      <div class="input-gruppe">
        <label for="fra-lokasjon"> Fra </label>
        <select id="fra-lokasjon" v-model="fraLokasjon">
          <option
            v-for="lokasjon in lokasjoner"
            :value="lokasjon.lokasjon"
            :key="lokasjon.lokasjon"
            :disabled="tilLokasjon === lokasjon.lokasjon"
          >
            {{ lokasjon.lokasjon }}: {{ lokasjon.saldo }}
          </option>
        </select>
      </div>
      <div class="input-gruppe">
        <label for="til-lokasjon"> Til </label>
        <select id="til-lokasjon" v-model="tilLokasjon">
          <option
            v-for="lokasjon in lokasjoner"
            :value="lokasjon.lokasjon"
            :key="lokasjon.lokasjon"
            :disabled="fraLokasjon === lokasjon.lokasjon"
          >
            {{ lokasjon.lokasjon }}: {{ lokasjon.saldo }}
          </option>
        </select>
      </div>
      <div class="input-gruppe">
        <label for="antall">Antall</label>
        <input type="number" id="antall" v-model="antall" placeholder="Antall" min="0" :max="fraLokasjon.saldo" />
      </div>
      <Button type="primary" @click="lagre">Lagre</Button>
    </form>
  </div>
</template>

<script lang="ts">
import { ILagerSaldo } from "@/types";
import { defineComponent, PropType, ref } from "vue";
import { flyttArtikler } from "@/clients/lokasjonClient";
import { toast } from "@/utils";

export default defineComponent({
  props: {
    lokasjoner: {
      type: Array as PropType<ILagerSaldo[]>,
      required: true,
    },
    artikkelNr: {
      type: String,
      required: true,
    },
    lager: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const fraLokasjon = ref("");
    const tilLokasjon = ref("");
    const antall = ref(0);
    const lagrer = ref(false);

    const lagre = async () => {
      lagrer.value = true;
      await flyttArtikler(props.artikkelNr, fraLokasjon.value, tilLokasjon.value, antall.value, props.lager);
      lagrer.value = false;
      toast(
        `Flyttet ${antall.value} ${antall.value == 1 ? "artikkel" : "artikler"} med artikkelnummer ${
          props.artikkelNr
        } fra ${fraLokasjon.value} til ${tilLokasjon.value}`
      );
      emit("lukk", true);
    };

    return { fraLokasjon, tilLokasjon, antall, lagre, lagrer };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.flytt-artikler {
  .skjema {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
}

.input-gruppe {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
