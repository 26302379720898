import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Rutes248kLinje = _resolveComponent("RutesøkLinje")!

  return (_openBlock(), _createElementBlock("table", null, [
    _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        _createElementVNode("th", null, "Registreringsnummer"),
        _createElementVNode("th", null, "Bilmodell"),
        _createElementVNode("th", null, "Svar mottatt"),
        _createElementVNode("th", null, "Antall treff")
      ])
    ], -1)),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rutesøk, (søk) => {
        return (_openBlock(), _createBlock(_component_Rutes248kLinje, {
          key: søk.id,
          rutesøk: søk
        }, null, 8, ["rutesøk"]))
      }), 128))
    ])
  ]))
}