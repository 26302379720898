<template>
  <div v-if="aktiv" class="modal-wrapper" @click="$emit('lukk')">
    <div class="modal" @click.stop>
      <button class="lukk-knapp" @click="$emit('lukk')"><i class="las la-times-circle" /></button>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted } from "vue";

export default defineComponent({
  props: {
    aktiv: { type: Boolean, required: true },
  },

  setup(_, { emit }) {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === "Esc" || e.key === "Escape") {
        emit("lukk");
      }
    };

    onMounted(() => addEventListener("keydown", handleKeyPress));
    onUnmounted(() => removeEventListener("keydown", handleKeyPress));
    return {};
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.modal-wrapper {
  z-index: 99;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 2rem;
  overflow: auto;

  display: flex;

  background: rgba(0, 0, 0, 0.4);
}

.modal {
  background: #fff;
  width: 800px;
  padding: 1rem;
  border-radius: 0.2rem;
  margin: auto;
  position: relative;
}

.lukk-knapp {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;

  font-size: 1.1rem;

  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    transform: translateY(0.02rem);
  }

  &:active {
    transform: translateY(0.1rem);
  }
}
</style>
