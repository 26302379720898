import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lager-status-filter" }
const _hoisted_2 = { class: "options" }
const _hoisted_3 = { class: "filter-option" }
const _hoisted_4 = ["checked"]
const _hoisted_5 = { class: "filter-option" }
const _hoisted_6 = ["checked"]
const _hoisted_7 = { class: "filter-option" }
const _hoisted_8 = ["checked"]
const _hoisted_9 = { class: "filter-option" }
const _hoisted_10 = ["checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[10] || (_cache[10] = _createElementVNode("h3", null, "Filtrer etter lagerstatus", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createElementVNode("input", {
          type: "checkbox",
          id: "ok",
          class: "ok",
          checked: _ctx.modelValue?.includes(_ctx.LagerStatus.ok),
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.håndterVerdiEndret(_ctx.LagerStatus.ok, $event)))
        }, null, 40, _hoisted_4),
        _cache[5] || (_cache[5] = _createElementVNode("label", { for: "ok" }, "Lagerstatus ok", -1))
      ]),
      _createElementVNode("span", _hoisted_5, [
        _createElementVNode("input", {
          type: "checkbox",
          id: "overallokert",
          class: "overallokert",
          checked: _ctx.modelValue?.includes(_ctx.LagerStatus.overallokert),
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.håndterVerdiEndret(_ctx.LagerStatus.overallokert, $event)))
        }, null, 40, _hoisted_6),
        _cache[6] || (_cache[6] = _createElementVNode("label", { for: "overallokert" }, "Overallokert", -1))
      ]),
      _createElementVNode("span", _hoisted_7, [
        _createElementVNode("input", {
          type: "checkbox",
          id: "utilgjengelig-på-hovedlokasjon",
          class: "utilgjengelig-på-hovedlokasjon",
          checked: _ctx.modelValue?.includes(_ctx.LagerStatus.utilgjengeligPåHovedlokasjon),
          onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.håndterVerdiEndret(_ctx.LagerStatus.utilgjengeligPåHovedlokasjon, $event)))
        }, null, 40, _hoisted_8),
        _cache[7] || (_cache[7] = _createElementVNode("label", { for: "utilgjengelig-på-hovedlokasjon" }, "Utilgjengelig på hovedlokasjon", -1))
      ]),
      _createElementVNode("span", _hoisted_9, [
        _createElementVNode("input", {
          type: "checkbox",
          id: "tomt-på-lager",
          class: "tomt-på-lager",
          checked: _ctx.modelValue?.includes(_ctx.LagerStatus.tomtPåLager),
          onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.håndterVerdiEndret(_ctx.LagerStatus.tomtPåLager, $event)))
        }, null, 40, _hoisted_10),
        _cache[8] || (_cache[8] = _createElementVNode("label", { for: "tomt-på-lager" }, "Tomt på lager", -1))
      ]),
      _createVNode(_component_Button, {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('hent')))
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [
          _createTextVNode("Oppdater")
        ])),
        _: 1
      })
    ])
  ]))
}