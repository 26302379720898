<template>
  <div class="toasts">
    <Toast v-for="toast in toasts" :key="toast.id" :toast="toast" @close="fjernToast(toast)" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import Toast from "./Toast.vue";
import { toasts, fjernToast } from "@/utils/toastUtils";

export default defineComponent({
  props: {},

  setup() {
    const toastsSortert = computed(() => toasts.sort((a, b) => b.tidspunkt.getTime() - a.tidspunkt.getTime()));

    return { toasts: toastsSortert, fjernToast };
  },

  components: {
    Toast,
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.toasts {
  padding: 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
</style>
