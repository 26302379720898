import { IToast, ToastType } from "@/types";
import { reactive } from "vue";

export const toasts = reactive([] as IToast[]);
let idCounter = 0;

const DEFAULT_TID = 10;

const leggTilToast = (type: ToastType, tekst: string, overskrift?: string, varighetSekunder?: number) => {
  const toast = {
    tekst,
    overskrift,
    varighetSekunder,
    type,
    tidspunkt: new Date(),
    id: idCounter++,
  };

  toasts.push(toast);

  if (varighetSekunder && varighetSekunder > 0) {
    setTimeout(() => fjernToast(toast), varighetSekunder * 1000);
  }
};

export const fjernToast = (toast: IToast): void => {
  const toastIndex = toasts.findIndex((x) => x === toast);

  toasts.splice(toastIndex, 1);
};

export const toast = (tekst: string, varighet = DEFAULT_TID, overskrift?: string): void => {
  leggTilToast("info", tekst, overskrift, varighet);
};

export const toastWarning = (tekst: string, varighet = DEFAULT_TID, overskrift?: string): void => {
  leggTilToast("warning", tekst, overskrift, varighet);
};

export const toastError = (tekst: string, varighet = 0, overskrift?: string): void => {
  leggTilToast("error", tekst, overskrift, varighet);
};
