<template>
  <div></div>
</template>

<script lang="ts">
import authService, { AuthenticationResultStatus } from "@/utils/auth/authService";
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {},

  setup() {
    const message = ref("");

    const logout = async (returnUrl?: string): Promise<void> => {
      const state = { returnUrl };
      const isAuthenticated = await authService.isAuthenticated();

      if (isAuthenticated) {
        const result = await authService.signOut(state);

        switch (result.status) {
          case AuthenticationResultStatus.Redirect:
            break;
          case AuthenticationResultStatus.Success:
            if (!returnUrl) break;
            await authService.navigateToUrl(returnUrl);
            break;
          case AuthenticationResultStatus.Fail:
            if (result.message) {
              message.value = result.message;
            }
            break;
        }
      } else {
        message.value = "Du er nå utlogget";
      }
    };

    logout();

    return {};
  },
});
</script>

<style lang="less" scoped></style>
