<template>
  <div class="toast" :class="toast.type" ref="toastRef">
    <button @click="$emit('close')" class="close-button">X</button>
    <div v-if="toast.overskrift" class="toast-header">
      <h2>{{ toast.overskrift }}</h2>
    </div>
    <div class="toast-content">
      {{ toast.tekst }}
    </div>
  </div>
</template>

<script lang="ts">
import { IToast } from "@/types";
import { defineComponent, onMounted, PropType, ref } from "vue";

export default defineComponent({
  props: {
    toast: {
      type: Object as PropType<IToast>,
      required: true,
    },
  },

  setup() {
    const toastRef = ref<HTMLElement>();

    onMounted(() => {
      if (!toastRef.value) {
        return;
      }

      const toastElement = toastRef.value;

      toastElement.style.bottom = "50vh";
      setTimeout(() => {
        toastElement.style.bottom = "0";
      }, 10);
    });

    return { toastRef };
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.toast {
  border-radius: 0.4rem;
  font-size: 0.9rem;

  position: relative;
  transition: bottom 0.1s ease-in;

  width: 20rem;

  & > * {
    padding: 0.6rem 1rem;
  }

  .toast-header {
    h2 {
      margin: 0;
      font-size: 1rem;
    }

    width: 17.5rem;
  }

  .toast-content {
    width: 17.5rem;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.6rem;
    background: none;
    border: none;
    cursor: pointer;
  }
}

.info {
  background: @blue-lighter;

  .toast-header {
    background: @blue;
  }
}

.warning {
  background: @yellow-light;

  .toast-header {
    background: @yellow-lighter;
  }
}

.error {
  background: @red-lighter;

  .toast-header {
    background: @red-light;
  }
}
</style>
