<template>
  <div>
    <h1>Kundesøk</h1>
    <KundeSøk />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { KundeSøk } from "@/components";

export default defineComponent({
  props: {},

  setup() {
    return {};
  },

  components: {
    KundeSøk,
  },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
</style>
