import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "spinner-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FraktEtikett = _resolveComponent("FraktEtikett")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createBlock(_Suspense, null, {
    default: _withCtx(() => [
      _createVNode(_component_FraktEtikett, { bestillingId: _ctx.id }, null, 8, ["bestillingId"])
    ]),
    fallback: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Spinner)
      ])
    ]),
    _: 1
  }))
}