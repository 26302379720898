import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, withKeys as _withKeys, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "page-options" }
const _hoisted_2 = ["value"]
const _hoisted_3 = { class: "page-selector" }
const _hoisted_4 = ["max"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[11] || (_cache[11] = _createElementVNode("label", null, "Antall per side:", -1)),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pageSize) = $event)),
      onChange: _cache[1] || (_cache[1] = ($event: any) => {
        _ctx.page = 1;
        _ctx.$emit('update:modelValue', _ctx.page);
      })
    }, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList([10, 25, 50, 100], (size) => {
        return _createElementVNode("option", {
          key: size,
          value: size
        }, _toDisplayString(size), 9, _hoisted_2)
      }), 64))
    ], 544), [
      [_vModelSelect, _ctx.pageSize]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => {
          _ctx.page = Math.max(_ctx.page - 1, 1);
          _ctx.$emit('update:modelValue', _ctx.page);
        })
      }, _cache[8] || (_cache[8] = [
        _createElementVNode("i", { class: "las la-angle-double-left" }, null, -1)
      ])),
      _cache[10] || (_cache[10] = _createTextVNode(" Side ")),
      _withDirectives(_createElementVNode("input", {
        type: "number",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.page) = $event)),
        min: "1",
        max: _ctx.antallSider,
        onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.håndterSideEndring())),
        onKeydown: _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.håndterSideEndring()), ["enter"])),
        onMouseup: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('update:modelValue', _ctx.page)))
      }, null, 40, _hoisted_4), [
        [_vModelText, _ctx.page]
      ]),
      _createTextVNode(" av " + _toDisplayString(_ctx.antallSider) + " ", 1),
      _createElementVNode("button", {
        onClick: _cache[7] || (_cache[7] = ($event: any) => {
          _ctx.page = Math.min(_ctx.page + 1, _ctx.antallSider);
          _ctx.$emit('update:modelValue', _ctx.page);
        })
      }, _cache[9] || (_cache[9] = [
        _createElementVNode("i", { class: "las la-angle-double-right" }, null, -1)
      ]))
    ])
  ]))
}