import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spinner-wrapper"
}
const _hoisted_2 = {
  key: 1,
  class: "wrapper"
}
const _hoisted_3 = { class: "input-gruppe" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 2,
  class: "wrapper"
}
const _hoisted_6 = { class: "input-gruppe" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "input-gruppe" }
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_KundeNummerTypeAhead = _resolveComponent("KundeNummerTypeAhead")!
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.lagrer)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Spinner)
      ]))
    : (!_ctx.token)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[1] || (_cache[1] = _createElementVNode("label", { for: "kunde" }, "Kunde", -1)),
            _createVNode(_component_KundeNummerTypeAhead, {
              id: "kunde",
              modelValue: _ctx.kundeNr,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.kundeNr) = $event))
            }, null, 8, ["modelValue"])
          ]),
          (_ctx.kundeNr)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Valgt kunde: " + _toDisplayString(_ctx.kundeNr) + _toDisplayString(_ctx.kunde ? ` - ${_ctx.kunde.navn}` : ""), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_Button, {
            onClick: _ctx.doOpprettToken,
            type: "primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.kundeNr ? "Opprett token tilknyttet valgt kunde" : "Opprett token"), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[2] || (_cache[2] = _createElementVNode("label", { for: "token" }, "Token", -1)),
            _createElementVNode("input", {
              id: "token",
              type: "text",
              disabled: "",
              value: _ctx.token
            }, null, 8, _hoisted_7)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _cache[3] || (_cache[3] = _createElementVNode("label", { for: "registreringslenke" }, "Registreringslenke", -1)),
            _createElementVNode("a", { href: _ctx.registreringslenke }, _toDisplayString(_ctx.registreringslenke), 9, _hoisted_9)
          ])
        ]))
}