import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, withKeys as _withKeys, vModelText as _vModelText, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "lagervelger" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (!_ctx.modelValue)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Du har ikke valgt hvilket lager du gjennomfører handlinger for."))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, "Du gjør handlinger på vegne av lager " + _toDisplayString(_ctx.modelValue) + ".", 1)),
      (_ctx.modelValue && !_ctx.redigerer)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 2,
            class: "button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.redigerer = true))
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Endre")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    (_ctx.redigerer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "inputfelt",
            placeholder: "Lagernummer",
            onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.onVelgLager()), ["enter"])),
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.valgtLager) = $event))
          }, null, 544), [
            [_vModelText, _ctx.valgtLager]
          ]),
          _createVNode(_component_Button, {
            class: "button",
            type: "primary",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onVelgLager()))
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Lagre")
            ])),
            _: 1
          }),
          _createVNode(_component_Button, {
            class: "button",
            disabled: !_ctx.modelValue,
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.redigerer = false))
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("Avbryt")
            ])),
            _: 1
          }, 8, ["disabled"])
        ]))
      : _createCommentVNode("", true)
  ]))
}