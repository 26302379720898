import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "component" }
const _hoisted_2 = {
  key: 0,
  class: "oppdrag"
}
const _hoisted_3 = { class: "kontakt-seksjon" }
const _hoisted_4 = { class: "seksjon" }
const _hoisted_5 = { class: "seksjon" }
const _hoisted_6 = { class: "seksjon" }
const _hoisted_7 = { key: 2 }

import { onMounted, ref } from "vue";
import { hentOppdrag } from "@/clients/oppdragKlient";
import { artikkelGruppeBeskrivelse, IOppdrag, oppdragStatusBeskrivelse } from "@/types";
import Spinner from "@/components/_basis/Spinner.vue";
import { BeskrivelseListe, DataListe } from "@andrlriis/komponentbibliotek";
import { formatterDato } from "@/utils";

interface IProps {
  oppdragId: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'OppdragDetaljerView',
  props: {
    oppdragId: {}
  },
  setup(__props: any) {

const oppdrag = ref<IOppdrag>();
const laster = ref<bool>(false);

onMounted(async () => {
  laster.value = true;
  try {
    oppdrag.value = await hentOppdrag(props.oppdragId);
  } finally {
    laster.value = false;
  }
});

const props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (oppdrag.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("h1", null, _toDisplayString(oppdrag.value.regNr), 1),
            _createVNode(_unref(BeskrivelseListe), { class: "basis-info" }, {
              default: _withCtx(() => [
                _cache[0] || (_cache[0] = _createElementVNode("dt", null, "Dato", -1)),
                _createElementVNode("dd", null, _toDisplayString(_unref(formatterDato)(oppdrag.value.verkstedDato)), 1),
                _cache[1] || (_cache[1] = _createElementVNode("dt", null, "Status", -1)),
                _createElementVNode("dd", null, _toDisplayString(_unref(oppdragStatusBeskrivelse).get(oppdrag.value.status)), 1),
                _cache[2] || (_cache[2] = _createElementVNode("dt", null, "Oppdragnr.", -1)),
                _createElementVNode("dd", null, _toDisplayString(oppdrag.value.oppdragNr), 1),
                _cache[3] || (_cache[3] = _createElementVNode("dt", null, "Verkstednr.", -1)),
                _createElementVNode("dd", null, _toDisplayString(oppdrag.value.verkstedNr), 1)
              ]),
              _: 1
            })
          ]),
          _createVNode(_unref(DataListe), null, {
            headers: _withCtx(() => _cache[4] || (_cache[4] = [
              _createElementVNode("span", null, "Artikkelnummer", -1),
              _createElementVNode("span", null, "Artikkelnavn", -1),
              _createElementVNode("span", null, "Antall", -1),
              _createElementVNode("span", null, "Artikkelgruppe", -1)
            ])),
            rows: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(oppdrag.value.linjer, (linje) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: linje.linjeNr
                }, [
                  _createElementVNode("span", null, _toDisplayString(linje.artikkelNr), 1),
                  _createElementVNode("span", null, _toDisplayString(linje.artikkelBetegnelse), 1),
                  _createElementVNode("span", null, _toDisplayString(linje.antall), 1),
                  _createElementVNode("span", null, _toDisplayString(_unref(artikkelGruppeBeskrivelse).get(linje.artikkelGruppe)), 1)
                ]))
              }), 128))
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[10] || (_cache[10] = _createElementVNode("h1", null, "Kontaktperson", -1)),
              _createVNode(_unref(BeskrivelseListe), null, {
                default: _withCtx(() => [
                  _cache[5] || (_cache[5] = _createElementVNode("dt", null, "Navn", -1)),
                  _createElementVNode("dd", null, _toDisplayString(oppdrag.value.kontaktperson.navn), 1),
                  _cache[6] || (_cache[6] = _createElementVNode("dt", null, "Telefon", -1)),
                  _createElementVNode("dd", null, _toDisplayString(oppdrag.value.kontaktperson.telefon), 1),
                  _cache[7] || (_cache[7] = _createElementVNode("dt", null, "E-post", -1)),
                  _createElementVNode("dd", null, _toDisplayString(oppdrag.value.kontaktperson.epost), 1),
                  _cache[8] || (_cache[8] = _createElementVNode("dt", null, "Adresse", -1)),
                  _createElementVNode("dd", null, _toDisplayString(oppdrag.value.kontaktperson.adresse), 1),
                  _cache[9] || (_cache[9] = _createElementVNode("dt", null, "Poststed", -1)),
                  _createElementVNode("dd", null, _toDisplayString(oppdrag.value.kontaktperson.postNrOgSted), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _cache[16] || (_cache[16] = _createElementVNode("h1", null, "Forsikringstaker", -1)),
              _createVNode(_unref(BeskrivelseListe), null, {
                default: _withCtx(() => [
                  _cache[11] || (_cache[11] = _createElementVNode("dt", null, "Navn", -1)),
                  _createElementVNode("dd", null, _toDisplayString(oppdrag.value.forsikringstaker.navn), 1),
                  _cache[12] || (_cache[12] = _createElementVNode("dt", null, "Telefon", -1)),
                  _createElementVNode("dd", null, _toDisplayString(oppdrag.value.forsikringstaker.telefon), 1),
                  _cache[13] || (_cache[13] = _createElementVNode("dt", null, "E-post", -1)),
                  _createElementVNode("dd", null, _toDisplayString(oppdrag.value.forsikringstaker.epost), 1),
                  _cache[14] || (_cache[14] = _createElementVNode("dt", null, "Adresse", -1)),
                  _createElementVNode("dd", null, _toDisplayString(oppdrag.value.forsikringstaker.adresse), 1),
                  _cache[15] || (_cache[15] = _createElementVNode("dt", null, "Poststed", -1)),
                  _createElementVNode("dd", null, _toDisplayString(oppdrag.value.forsikringstaker.postNrOgSted), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[22] || (_cache[22] = _createElementVNode("h1", null, "Bilfører", -1)),
              _createVNode(_unref(BeskrivelseListe), null, {
                default: _withCtx(() => [
                  _cache[17] || (_cache[17] = _createElementVNode("dt", null, "Navn", -1)),
                  _createElementVNode("dd", null, _toDisplayString(oppdrag.value.bilfører.navn), 1),
                  _cache[18] || (_cache[18] = _createElementVNode("dt", null, "Telefon", -1)),
                  _createElementVNode("dd", null, _toDisplayString(oppdrag.value.bilfører.telefon), 1),
                  _cache[19] || (_cache[19] = _createElementVNode("dt", null, "E-post", -1)),
                  _createElementVNode("dd", null, _toDisplayString(oppdrag.value.bilfører.epost), 1),
                  _cache[20] || (_cache[20] = _createElementVNode("dt", null, "Adresse", -1)),
                  _createElementVNode("dd", null, _toDisplayString(oppdrag.value.bilfører.adresse), 1),
                  _cache[21] || (_cache[21] = _createElementVNode("dt", null, "Poststed", -1)),
                  _createElementVNode("dd", null, _toDisplayString(oppdrag.value.bilfører.postNrOgSted), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]))
      : (laster.value)
        ? (_openBlock(), _createBlock(Spinner, {
            key: 1,
            class: "spinner"
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("p", null, "Fant ikke oppdrag med oppdragsnr. " + _toDisplayString(props.oppdragId), 1)
          ]))
  ]))
}
}

})