const eksporterTilCsv = <T>(data: T[], filnavn: string): void => {
  if (data.length === 0) return;

  const headers = Object.keys(data[0]);
  const rows = data.map((row) => headers.map((header) => row[header as keyof T]));
  const fileRows = [headers, ...rows];

  let csvContent = "data:text/csv;charset=utf-8,";
  fileRows.forEach((row) => {
    csvContent += row.join(",") + "\n";
  });

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", filnavn);
  document.body.appendChild(link);

  link.click();
};

export default eksporterTilCsv;
