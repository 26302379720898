<template>
  <div>
    <h1>Mine bestillinger</h1>
    <CenteredSpinner v-if="laster" />
    <MineBestillinger :bestillinger="bestillinger" v-else />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { MineBestillinger } from "@/components";
import { IBestilling } from "@/types";
import { hentMineBestillinger } from "@/clients/kundeClient";

export default defineComponent({
  props: {},

  setup() {
    const laster = ref(true);
    const bestillinger = ref<IBestilling[]>();

    hentMineBestillinger().then((result) => {
      bestillinger.value = result;
      laster.value = false;
    });

    return { laster, bestillinger };
  },
  components: { MineBestillinger },
});
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
</style>
