import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import { OppdragEksport, OppdragListe } from "@/components";
import { onMounted, ref } from "vue";
import authService from "@/utils/auth/authService";


export default /*@__PURE__*/_defineComponent({
  __name: 'OppdragView',
  setup(__props) {

const visEksportKnapp = ref(false);

onMounted(async () => {
  const user = await authService.getUser();
  visEksportKnapp.value = user?.kundeNr != undefined;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[1] || (_cache[1] = _createElementVNode("h1", null, "Oppdrag", -1)),
    (visEksportKnapp.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
          _createVNode(_unref(OppdragEksport), { class: "eksport-knapp" }),
          _cache[0] || (_cache[0] = _createTextVNode("Her finner du oppdrag som ble eksportert fra Bilglassportalen før behovet for reserveløsningen oppstod. "))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_unref(OppdragListe))
  ]))
}
}

})