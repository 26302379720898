export * from "./crud";
export * from "./errorHandler";
export { toast, toastWarning, toastError } from "./toastUtils";
export * from "./auth/passordValidering";
export { default as eksporterTilCsv } from "./eksporterTilCsv";
export * from "./lastNedEksport";

export const formatterDato = (dato: Date): string => {
  return `${dato.getDate().toString().padStart(2, "0")}.${(dato.getMonth() + 1).toString().padStart(2, "0")}.${dato
    .getFullYear()
    .toString()
    .padStart(4, "0")}`;
};

export const formaterTid = (dato: Date): string => {
  return `${dato.getHours().toString().padStart(2, "0")}:${dato.getMinutes().toString().padStart(2, "0")}`;
};

export const formaterDatoTid = (dato: Date): string => {
  return `${formatterDato(dato)} ${formaterTid(dato)}`;
};

export const formaterKlokkeslett = (tid: string): string => {
  return tid.split(":").slice(0, 2).join(":");
};
