import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = ["selected", "value"]
const _hoisted_2 = { key: 3 }

import { DataListe, Dropdown } from "@andrlriis/komponentbibliotek";
import { IOppdrag, oppdragStatusBeskrivelse } from "@/types";
import { computed, onMounted, ref } from "vue";
import { hentOppdragListe, hentVerkstedNrListe } from "@/clients/oppdragKlient";
import { ROUTE_NAMES } from "@/router";
import { formatterDato } from "../../utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'OppdragListe',
  setup(__props) {

const oppdragListe = ref<IOppdrag[]>([]);
const verkstedNrListe = ref<string[]>([]);
const valgtVerkstedNr = ref<string>("0");
const laster = ref(false);

const filrertOppdragListe = computed(() => {
  if (valgtVerkstedNr.value === "0") {
    return oppdragListe.value;
  }
  return oppdragListe.value.filter((oppdrag) => oppdrag.verkstedNr === valgtVerkstedNr.value);
});

onMounted(async () => {
  try {
    laster.value = true;
    oppdragListe.value = await hentOppdragListe();
    const liste = await hentVerkstedNrListe();
    if (liste.length > 1) {
      verkstedNrListe.value = ["0", ...liste];
    }
  } finally {
    laster.value = false;
  }
});

const endreValgtVerksted = (nyttVerkstedNr: string) => {
  valgtVerkstedNr.value = nyttVerkstedNr;
};

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("section", null, [
    (verkstedNrListe.value.length > 1)
      ? (_openBlock(), _createBlock(_unref(Dropdown), {
          key: 0,
          modelValue: valgtVerkstedNr.value,
          class: "verksted-liste",
          label: "Verksteder",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = (nyttValgtVerkstedNr) => endreValgtVerksted(nyttValgtVerkstedNr))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(verkstedNrListe.value, (verkstedNr) => {
              return (_openBlock(), _createElementBlock("option", {
                key: verkstedNr,
                selected: valgtVerkstedNr.value === verkstedNr,
                value: verkstedNr
              }, _toDisplayString(verkstedNr === "0" ? "Alle" : verkstedNr), 9, _hoisted_1))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (filrertOppdragListe.value.length > 0)
      ? (_openBlock(), _createBlock(_unref(DataListe), { key: 1 }, {
          headers: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("span", null, "Dato", -1),
            _createElementVNode("span", null, "Regnr.", -1),
            _createElementVNode("span", null, "Status", -1)
          ])),
          rows: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filrertOppdragListe.value, (oppdrag) => {
              return (_openBlock(), _createBlock(_component_RouterLink, {
                key: oppdrag.oppdragNr,
                to: { name: _unref(ROUTE_NAMES).oppdragDetaljer, params: { oppdragId: oppdrag.oppdragNr }}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_unref(formatterDato)(oppdrag.verkstedDato)), 1),
                  _createElementVNode("span", null, _toDisplayString(oppdrag.regNr), 1),
                  _createElementVNode("span", null, _toDisplayString(_unref(oppdragStatusBeskrivelse).get(oppdrag.status)), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            }), 128))
          ]),
          _: 1
        }))
      : (laster.value)
        ? (_openBlock(), _createBlock(_component_Spinner, {
            key: 2,
            class: "spinner"
          }))
        : (_openBlock(), _createElementBlock("p", _hoisted_2, "Ingen oppdrag å vise"))
  ]))
}
}

})