import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "verktøyvelger-wrapper" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["value"]
const _hoisted_5 = { key: 2 }

import { ref, watch } from "vue";
import { IHovedgruppe, IUndergruppe, IArtikkel } from "@/types";
import { hentHovedgrupper, hentUndergrupper, hentVerktøy } from "@/clients/verktøyClient";
import RuteVelger from "./RuteVelger.vue";

interface IEmits {
  (e: "legg-til-artikkel", artikkel: IArtikkel): void;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'VerktøyFinner',
  emits: ["legg-til-artikkel"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const valgtHovedgruppe = ref<IHovedgruppe | undefined>();
const valgtUndergruppe = ref<IUndergruppe | undefined>();

const hovedgrupper = ref<IHovedgruppe[]>([]);
const undergrupper = ref<IUndergruppe[]>([]);

const artikler = ref<IArtikkel[]>([]);
const søker = ref(false);

(async () => {
  hovedgrupper.value = await hentHovedgrupper();
})();

watch(valgtHovedgruppe, async (hovedgruppe) => {
  if (hovedgruppe) {
    undergrupper.value = await hentUndergrupper(hovedgruppe.hovedgruppeId);
    valgtUndergruppe.value = undergrupper.value.length === 1 ? undergrupper.value[0] : undefined;
  }
});

watch(valgtUndergruppe, async (undergruppe) => {
  if (undergruppe) {
    søker.value = true;
    artikler.value = await hentVerktøy(undergruppe.hovedgruppeId, undergruppe.undergruppeId);
    søker.value = false;
  } else {
    artikler.value = [];
  }
});

const velgArtikkel = (artikkel: IArtikkel) => {
  emit("legg-til-artikkel", artikkel);
};

return (_ctx: any,_cache: any) => {
  const _component_CenteredSpinner = _resolveComponent("CenteredSpinner")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("label", null, "Kategori", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((valgtHovedgruppe).value = $event))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(hovedgrupper.value, (hovedgruppe) => {
          return (_openBlock(), _createElementBlock("option", {
            value: hovedgruppe,
            key: hovedgruppe.hovedgruppeId
          }, _toDisplayString(hovedgruppe.beskrivelse), 9, _hoisted_2))
        }), 128))
      ], 512), [
        [_vModelSelect, valgtHovedgruppe.value]
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("label", null, "Underkategori", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((valgtUndergruppe).value = $event)),
        disabled: undergrupper.value.length === 0
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(undergrupper.value, (undergruppe) => {
          return (_openBlock(), _createElementBlock("option", {
            value: undergruppe,
            key: undergruppe.undergruppeId
          }, _toDisplayString(undergruppe.beskrivelse), 9, _hoisted_4))
        }), 128))
      ], 8, _hoisted_3), [
        [_vModelSelect, valgtUndergruppe.value]
      ])
    ]),
    (søker.value)
      ? (_openBlock(), _createBlock(_component_CenteredSpinner, { key: 0 }))
      : (artikler.value.length > 0)
        ? (_openBlock(), _createBlock(RuteVelger, {
            key: 1,
            ruter: artikler.value,
            onVelgRute: velgArtikkel
          }, null, 8, ["ruter"]))
        : (valgtHovedgruppe.value && valgtUndergruppe.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, "Fant ingen verktøy"))
          : _createCommentVNode("", true)
  ]))
}
}

})