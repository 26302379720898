import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("p", null, "Du har ikke tilgang til siden du prøvde å navigere deg til.", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Tilbake til forsiden")
        ])),
        _: 1
      })
    ])
  ]))
}