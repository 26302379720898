<template>
  <section class="component">
    <div v-if="oppdrag" class="oppdrag">
      <div>
        <h1>{{ oppdrag.regNr }}</h1>
        <BeskrivelseListe class="basis-info">
          <dt>Dato</dt>
          <dd>{{ formatterDato(oppdrag.verkstedDato) }}</dd>
          <dt>Status</dt>
          <dd>{{ oppdragStatusBeskrivelse.get(oppdrag.status) }}</dd>
          <dt>Oppdragnr.</dt>
          <dd>{{ oppdrag.oppdragNr }}</dd>
          <dt>Verkstednr.</dt>
          <dd>{{ oppdrag.verkstedNr }}</dd>
        </BeskrivelseListe>
      </div>
      <DataListe>
        <template #headers>
          <span>Artikkelnummer</span>
          <span>Artikkelnavn</span>
          <span>Antall</span>
          <span>Artikkelgruppe</span>
        </template>
        <template #rows>
          <div v-for="linje in oppdrag.linjer" :key="linje.linjeNr">
            <span>{{ linje.artikkelNr }}</span>
            <span>{{ linje.artikkelBetegnelse }}</span>
            <span>{{ linje.antall }}</span>
            <span>{{ artikkelGruppeBeskrivelse.get(linje.artikkelGruppe) }}</span>
          </div>
        </template>
      </DataListe>
      <div class="kontakt-seksjon">
        <div class="seksjon">
          <h1>Kontaktperson</h1>
          <BeskrivelseListe>
            <dt>Navn</dt>
            <dd>{{ oppdrag.kontaktperson.navn }}</dd>
            <dt>Telefon</dt>
            <dd>{{ oppdrag.kontaktperson.telefon }}</dd>
            <dt>E-post</dt>
            <dd>{{ oppdrag.kontaktperson.epost }}</dd>
            <dt>Adresse</dt>
            <dd>{{ oppdrag.kontaktperson.adresse }}</dd>
            <dt>Poststed</dt>
            <dd>{{ oppdrag.kontaktperson.postNrOgSted }}</dd>
          </BeskrivelseListe>
        </div>
        <div class="seksjon">
          <h1>Forsikringstaker</h1>
          <BeskrivelseListe>
            <dt>Navn</dt>
            <dd>{{ oppdrag.forsikringstaker.navn }}</dd>
            <dt>Telefon</dt>
            <dd>{{ oppdrag.forsikringstaker.telefon }}</dd>
            <dt>E-post</dt>
            <dd>{{ oppdrag.forsikringstaker.epost }}</dd>
            <dt>Adresse</dt>
            <dd>{{ oppdrag.forsikringstaker.adresse }}</dd>
            <dt>Poststed</dt>
            <dd>{{ oppdrag.forsikringstaker.postNrOgSted }}</dd>
          </BeskrivelseListe>
        </div>
        <div class="seksjon">
          <h1>Bilfører</h1>
          <BeskrivelseListe>
            <dt>Navn</dt>
            <dd>{{ oppdrag.bilfører.navn }}</dd>
            <dt>Telefon</dt>
            <dd>{{ oppdrag.bilfører.telefon }}</dd>
            <dt>E-post</dt>
            <dd>{{ oppdrag.bilfører.epost }}</dd>
            <dt>Adresse</dt>
            <dd>{{ oppdrag.bilfører.adresse }}</dd>
            <dt>Poststed</dt>
            <dd>{{ oppdrag.bilfører.postNrOgSted }}</dd>
          </BeskrivelseListe>
        </div>
      </div>
    </div>
    <Spinner v-else-if="laster" class="spinner"/>
    <div v-else>
      <p>Fant ikke oppdrag med oppdragsnr. {{ props.oppdragId }}</p>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { hentOppdrag } from "@/clients/oppdragKlient";
import { artikkelGruppeBeskrivelse, IOppdrag, oppdragStatusBeskrivelse } from "@/types";
import Spinner from "@/components/_basis/Spinner.vue";
import { BeskrivelseListe, DataListe } from "@andrlriis/komponentbibliotek";
import { formatterDato } from "@/utils";

interface IProps {
  oppdragId: number;
}

const oppdrag = ref<IOppdrag>();
const laster = ref<bool>(false);

onMounted(async () => {
  laster.value = true;
  try {
    oppdrag.value = await hentOppdrag(props.oppdragId);
  } finally {
    laster.value = false;
  }
});

const props = defineProps<IProps>();

</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

h1 {
  margin-top: 0;
}

.component {
  display: grid;
}
.oppdrag {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.spinner {
  margin-top: 100px;
  justify-self: center;
  align-self: center;
}

.kontakt-seksjon {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.basis-info {
  grid-column: span 2;
}

.seksjon {
  background-color: var(--riis-farge-semantisk-lerret-bakgrunn);
  padding: var(--riis-luft-3);
  border-radius: 10px;
}
</style>
