import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "rutesøk-view" }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = {
  key: 5,
  class: "gjennomførtSøkTekst"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KundeVelger = _resolveComponent("KundeVelger")!
  const _component_Button = _resolveComponent("Button")!
  const _component_NyttRutes248k = _resolveComponent("NyttRutesøk")!
  const _component_CenteredSpinner = _resolveComponent("CenteredSpinner")!
  const _component_Rutes248kListe = _resolveComponent("RutesøkListe")!
  const _component_SideVelger = _resolveComponent("SideVelger")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isAdmin || _ctx.isKundesenter)
      ? (_openBlock(), _createBlock(_component_KundeVelger, {
          key: 0,
          modelValue: _ctx.valgtKunde,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valgtKunde) = $event)),
          class: "kundevelger"
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (!_ctx.visNyttRutesøk)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 1,
          type: "primary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.visNyttRutesøk = true))
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("Nytt rutesøk")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.visNyttRutesøk)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "Nytt rutesøk"))
      : _createCommentVNode("", true),
    (_ctx.visNyttRutesøk)
      ? (_openBlock(), _createBlock(_component_NyttRutes248k, {
          key: 3,
          class: "nytt-rutesøk",
          valgtKunde: _ctx.valgtKunde,
          onGjennomførtSøk: _cache[2] || (_cache[2] = ($event: any) => (_ctx.gjennomførtSøk($event))),
          onOpprettet: _cache[3] || (_cache[3] = ($event: any) => {
        _ctx.visNyttRutesøk = false;
        _ctx.hentRutesøk();
      })
        }, null, 8, ["valgtKunde"]))
      : _createCommentVNode("", true),
    (_ctx.henterRutesøk)
      ? (_openBlock(), _createBlock(_component_CenteredSpinner, { key: 4 }))
      : _createCommentVNode("", true),
    (_ctx.gjennomførtSøkRegNr && _ctx.visGjennomførtSøkMelding)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, " Rutesøk ble gjennomført for " + _toDisplayString(_ctx.gjennomførtSøkRegNr) + ". Last inn siden på nytt om litt. ", 1))
      : _createCommentVNode("", true),
    _cache[7] || (_cache[7] = _createElementVNode("h2", null, "Mine rutesøk", -1)),
    (!_ctx.henterRutesøk)
      ? (_openBlock(), _createBlock(_component_Rutes248kListe, {
          key: 6,
          rutesøk: _ctx.rutesøk
        }, null, 8, ["rutesøk"]))
      : _createCommentVNode("", true),
    (!_ctx.henterRutesøk)
      ? (_openBlock(), _createBlock(_component_SideVelger, {
          key: 7,
          modelValue: _ctx.side,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.side) = $event)),
          antallPerSide: _ctx.antallPerSide,
          "onUpdate:antallPerSide": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.antallPerSide) = $event)),
          totaltAntall: _ctx.totaltAntall
        }, null, 8, ["modelValue", "antallPerSide", "totaltAntall"]))
      : _createCommentVNode("", true)
  ]))
}